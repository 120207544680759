import { Renderer2 } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { DeviceContentDay } from 'app/dialog/dialog-delivery-simple/dialog-delivery-simple.component';
import { DialogMessageComponent } from 'app/dialog/dialog-message/dialog-message.component';
import { Helper as HelperLayout } from 'app/layouts/helper';
import { AnnouncementFolder } from 'app/model/entity/announcement/announcement-folder';
import { AnnouncementMedia } from 'app/model/entity/announcement/announcement-media';
import { AnnouncementPlaylist } from 'app/model/entity/announcement/announcement-playlist';
import { AudioOfSequence } from 'app/model/entity/announcement/audio-of-sequence';
import { CommonPlaylistRegistration } from 'app/model/entity/announcement/common-playlist-registratrion';
import { CustomTagElementAnnouncement } from 'app/model/entity/announcement/custom-tag-element-announcement';
import { DeviceCommonSpecific } from 'app/model/entity/announcement/device-common-specific';
import { GroupDeviceAnnouncement } from 'app/model/entity/announcement/group-device-announcement';
import { BusStop } from 'app/model/entity/bus-stop';
import { Common } from 'app/model/entity/common';
import { ConditionFilterDeviceLog } from 'app/model/entity/condition-filter-device-log';
import { ConditionFilterMediaLog } from 'app/model/entity/condition-filter-medialog';
import { ConditionMedia } from 'app/model/entity/condition-media';
import { CustomTag } from 'app/model/entity/custom-tag';
import { DataLog } from 'app/model/entity/dashboard/data-log';
import { DeviceTabLog } from 'app/model/entity/dashboard/device-log';
import { PublishData } from 'app/model/entity/dashboard/publish-data';
import { BusStopRoute } from 'app/model/entity/destination/bus-stop-route';
import { RouteDestination } from 'app/model/entity/destination/route-destination';
import { TemplateLED } from 'app/model/entity/destination/template-led';
import { Device } from 'app/model/entity/device';
import { DeviceCalendar } from 'app/model/entity/device-calendar';
import { DeviceLog } from 'app/model/entity/device-log';
import { DisplayTemplate } from 'app/model/entity/display-template';
import { ExternalContent } from 'app/model/entity/external-content';
import { FolderMedia } from 'app/model/entity/folder-media';
import { IndexWord } from 'app/model/entity/index-word';
import { ItemDetail } from 'app/model/entity/item-detail';
import { AreaLED } from 'app/model/entity/led/area-led';
import { DataResponseArea } from 'app/model/entity/led/data-response-area';
import { PictureAreaLED } from 'app/model/entity/led/picture-area-led';
import { TextAreaLED } from 'app/model/entity/led/text-area-led';
import { NewsContent, ReferencePosition } from 'app/model/entity/news-content';
import { NewsContentDetail } from 'app/model/entity/news-content-detail';
import { OpenWeatherContent } from 'app/model/entity/open-weather-content';
import { OpenWeatherContentDetail } from 'app/model/entity/open-weather-content-detail';
import { Project } from 'app/model/entity/project';
import { RouteBusStop } from 'app/model/entity/route-bustop';
import { ScheduleDisplayIndex } from 'app/model/entity/schedule-display-index';
import { ScheduleOperationFolder } from 'app/model/entity/schedule-operation-manager/notification-registration/Folder';
import { NotificationMedia } from 'app/model/entity/schedule-operation-manager/notification-registration/NotificationMedia';
import { ScheduleRegistration } from 'app/model/entity/schedule-registration';
import { ScheduleRegistrationDetail } from 'app/model/entity/schedule-registration-detail';
import { ContentDaySchedule } from 'app/model/entity/schedule/content-day-schedule';
import { DisplaySettingSchedule } from 'app/model/entity/schedule/display-setting-schedule';
import { TimetableScheduleMerge } from 'app/model/entity/schedule/timetable-schedule';
import { TimetableDaily } from 'app/model/entity/schedule/timetables-daily';
import { UserCalendar } from 'app/model/entity/schedule/user-calendar';
import { Folder } from 'app/model/entity/simple/folder';
import { GroupDevice } from 'app/model/entity/simple/group-device';
import { MediaOfSequence } from 'app/model/entity/simple/media-of-sequence';
import { SimpleMedia } from 'app/model/entity/simple/simple-media';
import { SimplePlaylist } from 'app/model/entity/simple/simple-playlist';
import { Style } from 'app/model/entity/style';
import { StyleDetail } from 'app/model/entity/style-detail';
import { Ticket } from 'app/model/entity/ticket';
import { ContentDayReservation } from 'app/model/entity/ticket-editor/content-day-reservation';
import { ApplicationDTO } from 'app/model/entity/ticket-editor/dto/application-DTO';
import { SaleTicket } from 'app/model/entity/ticket-editor/set-ticket';
import { Spot } from 'app/model/entity/ticket-editor/spot';
import { SpotDetails } from 'app/model/entity/ticket-editor/spot-details';
import { ContentDayReservationManager } from 'app/model/entity/ticket-manager/content-day-reservation-manager';
import { ScheduleReservation } from 'app/model/entity/ticket/schedule-reservation';
import { Timetable } from 'app/model/entity/timetable';
import { TimetableDetail } from 'app/model/entity/timetable-detail';
import { TimetableDetailURLArea } from 'app/model/entity/timetable-detail-url-area';
import { TimetableSchedule } from 'app/model/entity/timetable-schedule';
import { URLArea } from 'app/model/entity/url-area';
import { UserSetting } from 'app/model/entity/user-setting';
import { WeatherContent } from 'app/model/entity/weather-content';
import { WeatherContentDetail } from 'app/model/entity/weather-content-detail';
import { Color } from 'app/module/custom-component/color-picker/entity/color';
import { DeviceOperation } from 'app/module/schedule-operation-manager/schedule-operation-manager.component';
import { SaveMainStateAction } from 'app/ngrx-component-state-management/component-state.action';
import { AnnouncementManagerService } from 'app/service/announcement/announcement-manager.service';
import { DataService } from 'app/service/data.service';
import { DialogService } from 'app/service/dialog.service';
import { IndexWordService } from 'app/service/index-word.service';
import { AppState } from 'app/store/app.state';
import _ from 'lodash';
import moment from 'moment';
import QRCodeStyling from 'qr-code-styling';
import {
  ActiveColumnHeader,
  AlignmentEnum,
  Constant,
  DestinationEnum,
  DeviceDetailStatusEnum,
  DeviceStatusEnum,
  DisplayModelEnum,
  ErrorEnum,
  LinkDataPictureEnum,
  LinkDataTextEnum,
  ObjectFitEnum,
  OrientationEnum,
  PayloadDeviceStatusEnum,
  ScreenNameEnum,
  ScreenTypeEnum,
  TemplateTypeEnum,
  TypeMediaFileEnum,
  TypeMediaInDashBoard
} from '../config/constants';
import { Area } from '../model/entity/area';
import { Channel } from '../model/entity/channel';
import { ContentDay } from '../model/entity/content-day';
import { EventBusStop } from '../model/entity/event-bus-stop';
import { Image } from '../model/entity/image';
import { Layer } from '../model/entity/layer';
import { Media } from '../model/entity/media';
import { PictureArea } from '../model/entity/picture-area';
import { DailySchedule, Program } from '../model/entity/playlist';
import { Route } from '../model/entity/route';
import { Timetable as TimetableMerge } from '../model/entity/schedule/timetable';
import { Sequence } from '../model/entity/sequence';
import { Sound } from '../model/entity/sound';
import { Template } from '../model/entity/template';
import { Text } from '../model/entity/text';
import { TextArea } from '../model/entity/text-area';
import { Video } from '../model/entity/video';
import { TextHighlightingSetting } from './../model/entity/text-highlighting-setting';
export class Helper {
  private static readonly TEXT_AREA_TYPE = 'TEXT';
  private static readonly PICTURE_AREA_TYPE = 'PICTUREDTO';
  private static readonly URL_AREA_TYPE = 'URLDTO';
  private static readonly TIMETABLE_INDEX = 0;
  private static readonly INDEX_WORD_INDEX = 1;
  /**
   * get error mess when validate
   *
   * @param error
   * @param propertyName
   * @param validValue
   * @param translateService
   * @returns errorMessages
   */
  public static getErrorMessage(error: ErrorEnum, propertyName?: string, validValue?: any, translateService?: TranslateService): string {
    let errorMess = '';
    if (translateService) {
      switch (error) {
        case ErrorEnum.EMPTY:
          errorMess = Helper.formatString(translateService.instant('dialog-message.helper.not-empty'), propertyName);
          break;
        case ErrorEnum.MIN_LENGTH:
          errorMess = Helper.formatString(
            translateService.instant('dialog-message.helper.content-message-min-length'),
            propertyName,
            validValue
          );
          break;
        case ErrorEnum.MAX_LENGTH:
          errorMess = Helper.formatString(
            translateService.instant('dialog-message.helper.content-message-max-length'),
            propertyName,
            validValue
          );
          break;
        case ErrorEnum.MIN:
          errorMess = Helper.formatString(translateService.instant('dialog-message.helper.min-value'), propertyName, validValue);
          break;
        case ErrorEnum.MAX:
          errorMess = Helper.formatString(translateService.instant('dialog-message.helper.max-value'), propertyName, validValue);
          break;
        case ErrorEnum.NON_SELECT:
          errorMess = Helper.formatString(translateService.instant('dialog-message.helper.please-select'), propertyName.toLowerCase());
          break;
        case ErrorEnum.PROPERTY_IS_EDITING:
          errorMess = Helper.formatString(translateService.instant('dialog-message.helper.is-editing'), propertyName);
          break;
        case ErrorEnum.PROPERTY_HAS_NO_TEMPLATE:
          errorMess = Helper.formatString(translateService.instant('dialog-message.helper.not-template'), propertyName);
          break;
        case ErrorEnum.PREVIEW_IS_PLAYING:
          errorMess = `${translateService.instant('dialog-message.helper.is-preview')}`;
          break;
        default:
          break;
      }
    } else {
      switch (error) {
        case ErrorEnum.EMPTY:
          errorMess = `${propertyName} cannot be empty.`;
          break;
        case ErrorEnum.MIN_LENGTH:
          errorMess = `${propertyName} must contain at least ${validValue} characters.`;
          break;
        case ErrorEnum.MAX_LENGTH:
          errorMess = `${propertyName} must contain no more than ${validValue} characters.`;
          break;
        case ErrorEnum.MIN:
          errorMess = `${propertyName} must contain at least ${validValue}.`;
          break;
        case ErrorEnum.MAX:
          errorMess = `${propertyName} must not be greater than ${validValue}.`;
          break;
        case ErrorEnum.NON_SELECT:
          errorMess = `Please select a ${propertyName.toLowerCase()}`;
          break;
        case ErrorEnum.PROPERTY_IS_EDITING:
          errorMess = `${propertyName} is in editing mode.`;
          break;
        case ErrorEnum.PROPERTY_HAS_NO_TEMPLATE:
          errorMess = `Selected ${propertyName} has no template.`;
          break;
        case ErrorEnum.PREVIEW_IS_PLAYING:
          errorMess = `Preview is in playing mode.`;
          break;
        default:
          break;
      }
    }
    return errorMess;
  }

  /**
   * convert full data news content from client
   *
   * @param newsContent
   * @returns any
   */
  public static convertFullDataNewsContentFromClient(newsContent: NewsContent): any {
    return {
      id: newsContent.id,
      contentOutputFileName: newsContent.contentOutputFileName,
      template: this.convertDataTemplate(newsContent.template),
      pageCounts: newsContent.pageCounts,
      duration: newsContent.duration,
      newsContentDetails: newsContent.newsContentDetails,
      contentOutputFileNameEncode: newsContent.contentOutputFileNameEncode,
      referencePositions: this.getReferencePositions(this.getAllAreaTemplate(newsContent.template).filter(area => area.checkTypeTextArea()))
    };
  }

  /**
   * get reference positions
   * @param areas
   * @returns
   */
  public static getReferencePositions(areas: Area[]): ReferencePosition[] {
    if (!areas || !areas.length) {
      return;
    }
    return areas
      .map(area => {
        let areaText = area as TextArea;
        let ctx = areaText.canvas.getContext('2d');
        let widthMeasureText = ctx.measureText(areaText.text ?? '').width;
        switch (areaText.orientation) {
          case OrientationEnum.HORIZONTAL:
            let positionH = this.getReferencePositionOrientationHorizontal(ctx, areaText);
            let referenceXH = 0;
            if (areaText.horizontalTextAlignment == AlignmentEnum.CENTER) {
              referenceXH = positionH.referenceX - widthMeasureText / 2;
            } else if (areaText.horizontalTextAlignment == AlignmentEnum.RIGHT) {
              referenceXH = positionH.referenceX - widthMeasureText;
            }
            return new ReferencePosition(referenceXH, positionH.referenceY, areaText.id);
          case OrientationEnum.SIDEWAYS:
            let positionS = this.getReferencePositionOrientationSideways(ctx, areaText, widthMeasureText);
            return new ReferencePosition(positionS.referenceX, positionS.referenceY, areaText.id);
          default:
            return null;
        }
      })
      .filter(referencePosition => referencePosition != null);
  }

  /**
   * convert data news content from client
   *
   * @param newsContent
   * @returns any
   */
  public static convertDataNewsContentFromClient(newsContent: NewsContent): any {
    return {
      id: newsContent.id,
      contentOutputFileName: newsContent.contentOutputFileName,
      template: newsContent.template,
      pageCounts: newsContent.pageCounts,
      duration: newsContent.duration,
      contentOutputFileNameEncode: newsContent.contentOutputFileNameEncode
    };
  }

  /**
   * convert full data weather content from client
   *
   * @param weatherContent
   * @returns any
   */
  public static convertFullDataWeatherContentFromClient(weatherContent: WeatherContent): any {
    return {
      id: weatherContent.id,
      contentOutputFileName: weatherContent.contentOutputFileName,
      template: this.convertDataTemplate(weatherContent.template),
      municipalCode: weatherContent.municipalCode,
      municipalName: weatherContent.municipalName,
      weatherContentDetails: weatherContent.weatherContentDetails,
      contentOutputFileNameEncode: weatherContent.contentOutputFileNameEncode,
      referencePositions: this.getReferencePositions(
        this.getAllAreaTemplate(weatherContent.template).filter(area => area.checkTypeTextArea())
      )
    };
  }

  /**
   * convert full data weather content from client
   *
   * @param weatherContent
   * @returns any
   */
  public static convertFullDataOpenWeatherContentFromClient(weatherContent: OpenWeatherContent): any {
    return {
      id: weatherContent.id,
      contentOutputFileName: weatherContent.contentOutputFileName,
      template: this.convertDataTemplate(weatherContent.template),
      cities: weatherContent.cities,
      weatherContentDetails: weatherContent.weatherContentDetails,
      contentOutputFileNameEncode: weatherContent.contentOutputFileNameEncode,
      referencePositions: this.getReferencePositions(
        this.getAllAreaTemplate(weatherContent.template).filter(area => area.checkTypeTextArea())
      )
    };
  }

  /**
   * convert data weather content from client
   *
   * @param weatherContent
   */
  public static convertDataWeatherContentFromClient(weatherContent: WeatherContent): any {
    return {
      id: weatherContent.id,
      contentOutputFileName: weatherContent.contentOutputFileName,
      template: weatherContent.template,
      municipalCode: weatherContent.municipalCode,
      municipalName: weatherContent.municipalName,
      contentOutputFileNameEncode: weatherContent.contentOutputFileNameEncode
    };
  }

  /**
   * convert data weather content from client
   *
   * @param weatherContent
   */
  public static convertDataOpenWeatherContentFromClient(openWeatherContent: OpenWeatherContent): any {
    return {
      id: openWeatherContent.id,
      contentOutputFileName: openWeatherContent.contentOutputFileName,
      template: openWeatherContent.template,
      cities: openWeatherContent.cities,
      contentOutputFileNameEncode: openWeatherContent.contentOutputFileNameEncode
    };
  }

  /**
   * convert data weather content detail from server
   *
   * @param weatherContentDetailData
   */
  public static convertDataWeatherContentDetailFromServer(weatherContentDetailData: any): WeatherContentDetail {
    let weatherContentDetail = new WeatherContentDetail();
    weatherContentDetail.id = weatherContentDetailData['id'];
    const area = weatherContentDetailData['area'];
    weatherContentDetail.area = area
      ? area.type == this.TEXT_AREA_TYPE
        ? this.convertDataTextArea(area)
        : this.convertDataPictureArea(area)
      : null;
    weatherContentDetail.source = weatherContentDetailData['source'];
    weatherContentDetail.forecastParam = weatherContentDetailData['forecastParam'];
    weatherContentDetail.indexWordGroup = weatherContentDetailData['indexWordGroup'];
    weatherContentDetail.externalContentId = weatherContentDetailData['externalContentId'];
    return weatherContentDetail;
  }

  /**
   * convert data weather content detail from server
   *
   * @param weatherContentDetailData
   */
  public static convertDataOpenWeatherContentDetailFromServer(weatherContentDetailData: any): OpenWeatherContentDetail {
    let weatherContentDetail = new OpenWeatherContentDetail();
    weatherContentDetail.id = weatherContentDetailData['id'];
    const area = weatherContentDetailData['area'];
    weatherContentDetail.area = area
      ? area.type == this.TEXT_AREA_TYPE
        ? this.convertDataTextArea(area)
        : this.convertDataPictureArea(area)
      : null;
    weatherContentDetail.source = weatherContentDetailData['source'];
    weatherContentDetail.city = weatherContentDetailData['city'];
    weatherContentDetail.forecastParam = weatherContentDetailData['forecastParam'];
    weatherContentDetail.indexWordGroup = weatherContentDetailData['indexWordGroup'];
    weatherContentDetail.externalContentId = weatherContentDetailData['externalContentId'];
    return weatherContentDetail;
  }

  /**
   * convert data weather content from server
   *
   * @param weatherContentDataResponse
   */
  public static convertDataWeatherContentFromServer(weatherContentDataResponse: any): WeatherContent {
    let weatherContent = new WeatherContent();
    weatherContent.id = weatherContentDataResponse['id'];
    weatherContent.contentOutputFileName = this.convertDataToDisplayGUI(
      weatherContentDataResponse['contentOutputFileName'],
      weatherContentDataResponse['contentOutputFileNameEncode']
    );
    weatherContent.template = this.convertDataTemplateBackward(weatherContentDataResponse['template']);
    weatherContent.municipalCode = weatherContentDataResponse['municipalCode'];
    weatherContent.municipalName = weatherContentDataResponse['municipalName'];
    weatherContent.weatherContentDetails = weatherContentDataResponse['weatherContentDetails'];
    weatherContent.contentOutputFileNameEncode = weatherContentDataResponse['contentOutputFileNameEncode'];
    return weatherContent;
  }

  /**
   * convert data weather content from server
   *
   * @param weatherContentDataResponse
   */
  public static convertDataOpenWeatherContentFromServer(weatherContentDataResponse: any): OpenWeatherContent {
    let openWeatherContent = new OpenWeatherContent();
    openWeatherContent.id = weatherContentDataResponse['id'];
    openWeatherContent.contentOutputFileName = this.convertDataToDisplayGUI(
      weatherContentDataResponse['contentOutputFileName'],
      weatherContentDataResponse['contentOutputFileNameEncode']
    );
    openWeatherContent.template = this.convertDataTemplateBackward(weatherContentDataResponse['template']);
    openWeatherContent.isAutoUpdate = weatherContentDataResponse['autoUpdate'] ? weatherContentDataResponse['autoUpdate'] : false;
    openWeatherContent.cities = !weatherContentDataResponse['cities']?.length ? [] : weatherContentDataResponse['cities'];
    openWeatherContent.weatherContentDetails = weatherContentDataResponse['weatherContentDetails'];
    openWeatherContent.contentOutputFileNameEncode = weatherContentDataResponse['contentOutputFileNameEncode'];
    return openWeatherContent;
  }

  /**
   * convert data news content detail from server
   *
   * @param newsContentDetailData
   */
  public static convertDataNewsContentDetailFromServer(newsContentDetailData: any): NewsContentDetail {
    let newsContentDetail = new NewsContentDetail();
    newsContentDetail.id = newsContentDetailData['id'];
    const area = newsContentDetailData['area'];
    newsContentDetail.area = area
      ? area.type == this.TEXT_AREA_TYPE
        ? this.convertDataTextArea(area)
        : this.convertDataPictureArea(area)
      : null;
    newsContentDetail.source = newsContentDetailData['source'];
    newsContentDetail.externalContentId = newsContentDetailData['externalContentId'];
    newsContentDetail.texts = newsContentDetailData['texts'];
    return newsContentDetail;
  }

  /**
   * convert data news content from server
   *
   * @param newsContentDataResponse
   */
  public static convertDataNewsContentFromServer(newsContentDataResponse: any): NewsContent {
    let newsContent = new NewsContent();
    newsContent.id = newsContentDataResponse['id'];
    newsContent.contentOutputFileName = this.convertDataToDisplayGUI(
      newsContentDataResponse['contentOutputFileName'],
      newsContentDataResponse['contentOutputFileNameEncode']
    );
    newsContent.template = this.convertDataTemplateBackward(newsContentDataResponse['template']);
    newsContent.pageCounts = newsContentDataResponse['pageCounts'];
    newsContent.duration = newsContentDataResponse['duration'];
    newsContent.newsContentDetails = newsContentDataResponse['newsContentDetails'];
    newsContent.contentOutputFileNameEncode = newsContentDataResponse['contentOutputFileNameEncode'];
    return newsContent;
  }

  public static getFileNameFromResponse(response: any): string {
    let fileName = '';
    const contentDisposition = response.headers.get('Content-Disposition');
    if (contentDisposition) {
      const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = fileNameRegex.exec(contentDisposition);
      if (matches != null && matches[1]) {
        fileName = matches[1].replace(/['"]/g, '');
      }
    }
    return fileName;
  }

  /**
   * convert image data
   * @param imageData
   */
  public static convertImageData(imageData: any): Image {
    let image = new Image();
    image.id = imageData['id'];
    image.name = this.convertDataToDisplayGUI(imageData['name'], imageData['mediaNameEncode']);
    image.url = imageData['url'];
    image.type = imageData['type'];
    image.width = imageData['width'];
    image.height = imageData['height'];
    image.folderId = imageData['folderId'];
    image.mediaOwner = imageData['mediaOwner'];
    image.validFrom = imageData['validFrom'] ? imageData['validFrom'] : '';
    image.validTo = imageData['validTo'] ? imageData['validTo'] : '';
    image.playTimeFrom = imageData['playTimeFrom'] ? imageData['playTimeFrom'] : '';
    image.playTimeTo = imageData['playTimeTo'] ? imageData['playTimeTo'] : '';
    image.randomNumber = Math.random();
    image.description = imageData['description'];
    image.startTime = imageData['startTime'];
    image.duration = imageData['duration'];
    image.mediaNameEncode = imageData['mediaNameEncode'];
    return image;
  }

  /**
   * convert sound data
   * @param soundData
   */
  public static convertSoundData(soundData: any): Sound {
    let sound = new Sound();
    sound.id = soundData['id'];
    sound.name = this.convertDataToDisplayGUI(soundData['name'], soundData['mediaNameEncode']);
    sound.url = soundData['url'];
    sound.type = soundData['type'];
    sound.folderId = soundData['folderId'];
    sound.duration = soundData['duration'];
    sound.bitRate = soundData['bitRate'];
    sound.mediaOwner = soundData['mediaOwner'];
    sound.validFrom = soundData['validFrom'] ? soundData['validFrom'] : '';
    sound.validTo = soundData['validTo'] ? soundData['validTo'] : '';
    sound.playTimeFrom = soundData['playTimeFrom'] ? soundData['playTimeFrom'] : '';
    sound.playTimeTo = soundData['playTimeTo'] ? soundData['playTimeTo'] : '';
    sound.randomNumber = Math.random();
    sound.description = soundData['description'];
    sound.startTime = soundData['startTime'];
    sound.durationDisplay = soundData['duration'];
    sound.mediaNameEncode = soundData['mediaNameEncode'];
    return sound;
  }

  /**
   * convert video data
   * @param videoData
   */
  public static covertVideoData(videoData: any): Video {
    let video = new Video();
    video.id = videoData['id'];
    video.name = this.convertDataToDisplayGUI(videoData['name'], videoData['mediaNameEncode']);
    video.url = videoData['url'];
    video.type = videoData['type'];
    video.width = videoData['width'];
    video.height = videoData['height'];
    video.folderId = videoData['folderId'];
    video.duration = videoData['duration'];
    video.bitRate = videoData['bitRate'];
    video.frameRate = videoData['frameRate'];
    video.mediaOwner = videoData['mediaOwner'];
    video.validFrom = videoData['validFrom'] ? videoData['validFrom'] : '';
    video.validTo = videoData['validTo'] ? videoData['validTo'] : '';
    video.playTimeFrom = videoData['playTimeFrom'] ? videoData['playTimeFrom'] : '';
    video.playTimeTo = videoData['playTimeTo'] ? videoData['playTimeTo'] : '';
    video.randomNumber = Math.random();
    video.description = videoData['description'];
    video.startTime = videoData['startTime'];
    video.durationDisplay = videoData['duration'];
    video.in = videoData['in'];
    video.out = videoData['out'];
    video.mediaNameEncode = videoData['mediaNameEncode'];
    return video;
  }

  /**
   * convert media to objects (sound, image, video)
   * @param mediaData media data
   */
  public static convertMediaData(mediaData: any): any {
    if (!mediaData) {
      return null;
    }
    let media;
    switch (mediaData['type']?.toLowerCase()) {
      case TypeMediaFileEnum.JPG:
      case TypeMediaFileEnum.GIF:
      case TypeMediaFileEnum.PNG:
      case TypeMediaFileEnum.BMP:
        media = this.convertImageData(mediaData);
        break;
      case TypeMediaFileEnum.MP3:
      case TypeMediaFileEnum.WAV:
        media = this.convertSoundData(mediaData);
        break;
      case TypeMediaFileEnum.MP4:
        media = this.covertVideoData(mediaData);
        break;
      case TypeMediaFileEnum.TXT:
        media = this.convertTextData(mediaData);
        break;
      case TypeMediaFileEnum.SEQ:
        media = this.convertSequenceData(mediaData);
        break;
      default:
        break;
    }
    return media;
  }

  /**
   * convert data medias data
   * @param data
   */
  public static convertDataMediasData(data: any[]): Array<Media> {
    if (!data) {
      return [];
    }
    return data.map(mediaData => this.convertMediaData(mediaData));
  }

  /**
   * convert text data
   * @param textData
   */
  public static convertTextData(textData: any): Text {
    let text = new Text();
    text.id = textData['id'];
    text.type = textData['type'];
    text.name = this.convertDataToDisplayGUI(textData['name'], textData['mediaNameEncode']);
    text.url = textData['url'];
    text.folderId = textData['folderId'];
    text.content = textData['content'];
    text.mediaOwner = textData['mediaOwner'];
    text.validFrom = textData['validFrom'] ? textData['validFrom'] : '';
    text.validTo = textData['validTo'] ? textData['validTo'] : '';
    text.playTimeFrom = textData['playTimeFrom'] ? textData['playTimeFrom'] : '';
    text.playTimeTo = textData['playTimeTo'] ? textData['playTimeTo'] : '';
    text.description = textData['description'];
    text.mediaNameEncode = textData['mediaNameEncode'];
    return text;
  }

  /**
   * convert sequence data
   * @param sequenceData
   */
  public static convertSequenceData(sequenceData: any): Sequence {
    let sequence = new Sequence();
    sequence.medias = new Array<Media>();
    sequence.id = sequenceData['id'];
    sequence.type = sequenceData['type'];
    sequence.name = this.convertDataToDisplayGUI(sequenceData['name'], sequenceData['mediaNameEncode']);
    sequence.url = sequenceData['url'];
    sequence.width = sequenceData['width'];
    sequence.height = sequenceData['height'];
    sequence.frameRate = sequenceData['frameRate'];
    sequence.startTime = sequenceData['startTime'];
    sequence.mediaOwner = sequenceData['mediaOwner'];
    let medias: Array<Media> = sequenceData['medias'];
    sequence.medias = medias?.map(mediaData => this.convertMediaData(mediaData));
    sequence.totalDuration = sequenceData['totalDuration'];
    sequence.validFrom = sequenceData['validFrom'] ? sequenceData['validFrom'] : '';
    sequence.validTo = sequenceData['validTo'] ? sequenceData['validTo'] : '';
    sequence.playTimeFrom = sequenceData['playTimeFrom'] ? sequenceData['playTimeFrom'] : '';
    sequence.playTimeTo = sequenceData['playTimeTo'] ? sequenceData['playTimeTo'] : '';
    sequence.description = sequenceData['description'];
    sequence.folderId = sequenceData['folderId'];
    sequence.mediaNameEncode = sequenceData['mediaNameEncode'];
    return sequence;
  }

  /**
   * convert data event bus stop
   * @param eventBusStopData
   * @param route
   */
  public static convertDataEventBusStop(eventBusStopData: any, route: Route): EventBusStop {
    let eventBusStop = new EventBusStop();
    eventBusStop.id = eventBusStopData['id'];
    eventBusStop.outputOption = eventBusStopData['outputOption'];
    eventBusStop.duration = eventBusStopData['duration'];
    eventBusStop.text = eventBusStopData['text'];
    eventBusStop.routeId = eventBusStopData['routeId'];
    eventBusStop.busStopId = eventBusStopData['busStopId'];
    eventBusStop.randomNumber = Math.random();
    if (route.display1) {
      eventBusStop.templateId = route.display1.id;
    }
    if (route.display2) {
      eventBusStop.templateId2 = route.display2.id;
    }
    let medias: Array<Media> = eventBusStopData['medias'];
    eventBusStop.medias = medias?.map(mediaData => this.convertMediaData(mediaData));
    let areaDisplay1 = eventBusStopData['areaDisplay1'];
    if (areaDisplay1) {
      if (areaDisplay1.type == this.TEXT_AREA_TYPE) {
        let areaText: TextArea = Helper.convertDataTextArea(areaDisplay1, undefined, 'Display1');
        eventBusStop.areaDisplay1 = areaText;
      } else {
        let areaPicture: PictureArea = Helper.convertDataPictureArea(areaDisplay1, undefined, 'Display1');
        eventBusStop.areaDisplay1 = areaPicture;
      }
    }
    let areaDisplay2 = eventBusStopData['areaDisplay2'];
    if (areaDisplay2) {
      if (areaDisplay2.type == this.TEXT_AREA_TYPE) {
        let areaText: TextArea = Helper.convertDataTextArea(areaDisplay2, undefined, 'Display2');
        eventBusStop.areaDisplay2 = areaText;
      } else {
        let areaPicture: PictureArea = Helper.convertDataPictureArea(areaDisplay2, undefined, 'Display2');
        eventBusStop.areaDisplay2 = areaPicture;
      }
    }
    return eventBusStop;
  }

  /**
   * convert data event bus stop forward
   * @param eventBusStop
   */
  public static convertDataEventBusStopForward(eventBusStop: EventBusStop): any {
    return {
      id: eventBusStop.id,
      areaDisplayId1: eventBusStop.areaDisplay1?.id,
      areaDisplayId2: eventBusStop.areaDisplay2?.id,
      busStopId: eventBusStop.busStopId,
      routeId: eventBusStop.routeId,
      outputOption: eventBusStop.outputOption,
      text: eventBusStop.text,
      duration: eventBusStop.duration,
      mediaId1: eventBusStop.medias ? eventBusStop.medias[0]?.id : undefined,
      mediaId2: eventBusStop.medias ? eventBusStop.medias[1]?.id : undefined
    };
  }

  /**
   * convert data templates backward
   * @param data
   */
  public static convertDataTemplatesBackward(data: any[], displaysNum?: string): Array<Template> {
    return data.map(templateData => this.convertDataTemplateBackward(templateData, displaysNum));
  }

  /**
   * convert data template backward
   * @param data
   */
  public static convertDataTemplateBackward(data: any, displaysNum?: string): Template {
    if (!data) {
      return null;
    }
    let templateOutput = new Template();
    if (data['id']) {
      templateOutput.id = data['id'];
    }
    templateOutput.name = data['name'];
    templateOutput.width = data['width'];
    templateOutput.height = data['height'];
    templateOutput.templateGroupName = data['templateGroupName'];
    templateOutput.templateGroupId = data['templateGroupId'];
    templateOutput.templateType = data['templateType'];
    templateOutput.isAutoTransition = data['isAutoTransition'];
    templateOutput.transitionTime = data['transitionTime'];
    templateOutput.destination = data['destination'];
    templateOutput.isMultiTimetable = data['isMultiTimetable'];
    templateOutput.isPageSwitching = data['isPageSwitching'];
    templateOutput.pageRowNumber = data['pageRowNumber'];
    let layers = data['layersDTO'];
    templateOutput.layers = layers
      ?.map(layerData => this.convertDataLayer(layerData, templateOutput.templateType, displaysNum))
      .sort(function(layer1, layer2) {
        return layer1.index - layer2.index;
      });
    return templateOutput;
  }

  /**
   * convert data layer
   * @param layerData
   */
  public static convertDataLayer(layerData: any, templateType: TemplateTypeEnum, displaysNum?: string): Layer {
    let layer = new Layer();
    layer.id = layerData['id'];
    layer.name = layerData['name'];
    layer.index = layerData['index'];
    layer.templateId = layerData['templateId'];
    layer.isSwitchingArea = layerData['isSwitchingArea'] ? layerData['isSwitchingArea'] : false;
    layer.areas = new Array<Area>();
    let textAreas = layerData['textAreas'];
    if (textAreas) {
      textAreas.forEach(textAreaData => {
        let textAreaOutput: TextArea = this.convertDataTextArea(textAreaData, templateType, displaysNum);
        layer.areas.push(textAreaOutput);
      });
    }
    let pictureAreasDTO = layerData['pictureAreasDTO'];
    if (pictureAreasDTO) {
      pictureAreasDTO.forEach(pictureAreaDTOData => {
        let pictureAreaOutput: PictureArea = this.convertDataPictureArea(pictureAreaDTOData, templateType, displaysNum);
        layer.areas.push(pictureAreaOutput);
      });
    }
    let urlAreasDTO = layerData['urlAreasDTO'];
    if (urlAreasDTO) {
      urlAreasDTO.forEach(urlAreaDTOData => {
        let urlAreaOutput: URLArea = this.convertDataURLArea(urlAreaDTOData, templateType, displaysNum);
        layer.areas.push(urlAreaOutput);
      });
    }
    layer.areas = layer.areas.sort(function(area1, area2) {
      return area1.index - area2.index;
    });
    return layer;
  }

  /**
   * convert data text area
   * @param textAreaData
   */
  public static convertDataTextArea(textAreaData: any, templateType?: TemplateTypeEnum, displaysNum?: string): TextArea {
    let textArea = new TextArea();
    textArea.id = textAreaData['id'];
    textArea['type'] = textAreaData['type'];
    textArea.name = textAreaData['name'];
    textArea.width = textAreaData['width'];
    textArea.height = textAreaData['height'];
    textArea.posX = textAreaData['posX'];
    textArea.posY = textAreaData['posY'];
    textArea.referencePoint = textAreaData['referencePoint'];
    textArea.index = textAreaData['index'];
    textArea.isFix = textAreaData['isFix'];
    textArea.isURL = textAreaData['isURL'];
    textArea.colorTextName = textArea.isURL ? '#FFEB8D' : '#00B050';
    textArea.text = textAreaData['text'];
    textArea.fontName = textAreaData['fontName'];
    textArea.fontSize = textAreaData['fontSize'];
    textArea.isBold = textAreaData['isBold'];
    textArea.isItalic = textAreaData['isItalic'];
    textArea.horizontalTextAlignment = textAreaData['horizontalTextAlignment'];
    textArea.verticalTextAlignment = textAreaData['verticalTextAlignment'];
    textArea.orientation = textAreaData['orientation'];
    textArea.scrollStatus = textAreaData['scrollStatus'];
    textArea.scrollSpeed = textAreaData['scrollSpeed'];
    textArea.scrollDirection = textAreaData['scrollDirection'];
    textArea.linkReferenceData = textAreaData['linkReferenceData'];
    textArea.indexWordGroupId = textAreaData['indexWordGroupId'];
    textArea.referencePositionRow = textAreaData['referencePositionRow'];
    textArea.referencePositionColumn = textAreaData['referencePositionColumn'];
    textArea.timingOn = textAreaData['timingOn'];
    textArea.timingOff = textAreaData['timingOff'];
    textArea.backgroundColor = textAreaData['backgroundColor'];
    textArea.fontColor = textAreaData['fontColor'];
    textArea.durationDisplayTiming = textAreaData['durationDisplayTiming'];
    textArea.timesFileEnd = textAreaData['timesFileEnd'];
    textArea.isOnClickEvent = textAreaData['isOnClickEvent'];
    textArea.onClickEventType = textAreaData['onClickEventType'];
    textArea.onClickEventDestination = textAreaData['onClickEventDestination'];
    textArea.arrivalTimeFormat = textAreaData['arrivalTimeFormat'];
    textArea.templateType = templateType ?? undefined;
    textArea.isTimingOn = textAreaData['isTimingOn'];
    textArea.timingType = textAreaData['timingType'];
    textArea.stopDuration = textAreaData['stopDuration'];
    textArea.timetableId = textAreaData['timetableId'];
    textArea.referenceColumn = textAreaData['referenceColumn'];
    textArea.idWithDisplay = displaysNum ? textAreaData['id'] + displaysNum : textAreaData['id'];
    textArea.typePageCount = textAreaData['typePageCount'];
    return textArea;
  }

  /**
   * convert data picture area
   * @param pictureAreaData
   */
  public static convertDataPictureArea(pictureAreaData: any, templateType?: TemplateTypeEnum, displaysNum?: string): PictureArea {
    let pictureArea = new PictureArea();
    pictureArea.id = pictureAreaData['id'];
    pictureArea['type'] = pictureAreaData['type'];
    pictureArea.name = pictureAreaData['name'];
    pictureArea.width = pictureAreaData['width'];
    pictureArea.height = pictureAreaData['height'];
    pictureArea.posX = pictureAreaData['posX'];
    pictureArea.posY = pictureAreaData['posY'];
    pictureArea.referencePoint = pictureAreaData['referencePoint'];
    pictureArea.index = pictureAreaData['index'];
    pictureArea.isFix = pictureAreaData['isFix'];
    pictureArea.isURL = pictureAreaData['isURL'];
    if (pictureAreaData['media']) {
      pictureArea.media = this.convertMediaData(pictureAreaData['media']);
    }
    pictureArea.objectFit = pictureAreaData['objectFit'];
    pictureArea.attribute = pictureAreaData['attribute'];
    pictureArea.indexWordGroupId = pictureAreaData['indexWordGroupId'];
    pictureArea.referencePositionRow = pictureAreaData['referencePositionRow'];
    pictureArea.referencePositionColumn = pictureAreaData['referencePositionColumn'];
    pictureArea.timingOn = pictureAreaData['timingOn'];
    pictureArea.timingOff = pictureAreaData['timingOff'];
    if (pictureArea.isFix) {
      if (pictureAreaData['soundA']) {
        pictureArea.soundA = this.convertMediaData(pictureAreaData['soundA']);
      }
      if (pictureAreaData['soundB']) {
        pictureArea.soundB = this.convertMediaData(pictureAreaData['soundB']);
      }
    }
    pictureArea.colorTextName = pictureArea.isURL ? '#FFEB8D' : '#FF66FF';
    pictureArea.durationDisplayTiming = pictureAreaData['durationDisplayTiming'];
    pictureArea.isOnClickEvent = pictureAreaData['isOnClickEvent'];
    pictureArea.onClickEventType = pictureAreaData['onClickEventType'];
    pictureArea.onClickEventDestination = pictureAreaData['onClickEventDestination'];
    pictureArea.templateType = templateType ?? undefined;
    pictureArea.isTimingOn = pictureAreaData['isTimingOn'];
    pictureArea.timingType = pictureAreaData['timingType'];
    pictureArea.beforeTimetableDisplay = pictureAreaData['beforeTimetableDisplay'];
    pictureArea.referenceColumn = pictureAreaData['referenceColumn'];
    pictureArea.timetableId = pictureAreaData['timetableId'];
    pictureArea.idWithDisplay = displaysNum ? pictureAreaData['id'] + displaysNum : pictureAreaData['id'];
    pictureArea.typePageCount = pictureAreaData['typePageCount'];
    return pictureArea;
  }
  /**
   * convert Data URL Area
   * @param urlAreaData
   * @param templateType
   * @param displaysNum
   * @returns
   */
  public static convertDataURLArea(urlAreaData: any, templateType?: TemplateTypeEnum, displaysNum?: string): URLArea {
    let urlArea = new URLArea();
    urlArea.id = urlAreaData['id'];
    urlArea['type'] = urlAreaData['type'];
    urlArea.name = urlAreaData['name'];
    urlArea.width = urlAreaData['width'];
    urlArea.height = urlAreaData['height'];
    urlArea.posX = urlAreaData['posX'];
    urlArea.posY = urlAreaData['posY'];
    urlArea.referencePoint = urlAreaData['referencePoint'];
    urlArea.index = urlAreaData['index'];
    urlArea.isFix = urlAreaData['isFix'];
    urlArea.isURL = urlAreaData['isURL'];
    urlArea.textURL = urlAreaData['textURL'];
    if (urlAreaData['media']) {
      urlArea.media = this.convertMediaData(urlAreaData['media']);
    }
    urlArea.colorTextName = '#FFEB8D';
    urlArea.durationDisplayTiming = urlAreaData['durationDisplayTiming'];
    urlArea.isOnClickEvent = urlAreaData['isOnClickEvent'];
    urlArea.onClickEventType = urlAreaData['onClickEventType'];
    urlArea.onClickEventDestination = urlAreaData['onClickEventDestination'];
    urlArea.templateType = templateType ?? undefined;
    urlArea.isTimingOn = urlAreaData['isTimingOn'];
    urlArea.timingType = urlAreaData['timingType'];
    urlArea.referenceColumn = urlAreaData['referenceColumn'];
    urlArea.timetableId = urlAreaData['timetableId'];
    urlArea.idWithDisplay = displaysNum ? urlAreaData['id'] + displaysNum : urlAreaData['id'];
    return urlArea;
  }

  /**
   * convert data template
   * @param template
   */
  public static convertDataTemplate(template: Template): any {
    let templateDTO: any = {
      id: template.id,
      name: template.name,
      width: template.width,
      height: template.height,
      templateGroupId: template.templateGroupId,
      templateGroupName: template.templateGroupName,
      templateType: template.templateType,
      isAutoTransition: template.isAutoTransition,
      transitionTime: template.transitionTime,
      destination: template.destination,
      isMultiTimetable: template.isMultiTimetable,
      isPageSwitching: template.isPageSwitching,
      pageRowNumber: template.pageRowNumber,
      modeActionTemplate: template.modeActionTemplate
    };
    templateDTO.layersDTO = new Array<any>();
    if (template.layers) {
      template.layers.forEach(layer => {
        let layerDTO: any = {
          id: layer.id,
          name: layer.name,
          index: layer.index,
          templateId: layer.templateId,
          isSwitchingArea: layer.isSwitchingArea
        };
        layerDTO.textAreas = new Array<any>();
        layerDTO.pictureAreasDTO = new Array<any>();
        layerDTO.urlAreasDTO = new Array<any>();
        layer.areas.forEach(area => {
          if (area instanceof TextArea) {
            layerDTO.textAreas.push(this.getTextAreaDto(area));
          } else if (area instanceof PictureArea) {
            layerDTO.pictureAreasDTO.push(this.getPictureAreaDto(area));
          } else {
            layerDTO.urlAreasDTO.push(this.getURLAreaDto(area));
          }
        });
        templateDTO.layersDTO.push(layerDTO);
      });
    }
    return templateDTO;
  }

  /**
   * get picture area Dto
   * @param area
   */
  private static getPictureAreaDto(area: Area): any {
    return {
      type: this.PICTURE_AREA_TYPE,
      id: area.id,
      name: area.name,
      width: area.width,
      height: area.height,
      posX: area.posX,
      posY: area.posY,
      referencePoint: area.referencePoint,
      index: area.index,
      isFix: area.isFix,
      isURL: area.isURL,
      media: area.getArea().media ?? null,
      objectFit: area.getArea().objectFit,
      attribute: area.getArea().attribute,
      indexWordGroupId: area.indexWordGroupId,
      referencePositionRow: area.referencePositionRow,
      referencePositionColumn: area.referencePositionColumn,
      timingOn: area.timingOn,
      timingOff: area.timingOff,
      soundA: area.getArea().soundA ?? null,
      soundB: area.getArea().soundB ?? null,
      durationDisplayTiming: area.durationDisplayTiming,
      isOnClickEvent: area.isOnClickEvent,
      onClickEventType: area.onClickEventType,
      onClickEventDestination: area.onClickEventDestination,
      isTimingOn: area.isTimingOn,
      timingType: area.timingType,
      beforeTimetableDisplay: area.getArea().beforeTimetableDisplay,
      timetableId: area.timetableId,
      referenceColumn: area.referenceColumn,
      idWithDisplay: area.idWithDisplay,
      typePageCount: +area.typePageCount
    };
  }

  /**
   * get URL Area Dto
   * @param area
   * @returns UrlAreaDTO backend
   */
  private static getURLAreaDto(area: Area): any {
    return {
      type: this.URL_AREA_TYPE,
      id: area.id,
      name: area.name,
      width: area.width,
      height: area.height,
      posX: area.posX,
      posY: area.posY,
      referencePoint: area.referencePoint,
      index: area.index,
      isFix: area.isFix,
      isURL: area.isURL,
      textURL: area.getArea().textURL ?? null,
      media: area.getArea().media ?? null,
      isOnClickEvent: area.isOnClickEvent,
      onClickEventType: area.onClickEventType,
      onClickEventDestination: area.onClickEventDestination,
      isTimingOn: area.isTimingOn,
      timingType: area.timingType,
      beforeTimetableDisplay: area.getArea().beforeTimetableDisplay,
      timetableId: area.timetableId,
      referenceColumn: area.referenceColumn,
      idWithDisplay: area.idWithDisplay
    };
  }

  /**
   * get text area Dto
   * @param area
   */
  private static getTextAreaDto(area: Area): any {
    return {
      type: this.TEXT_AREA_TYPE,
      id: area.id,
      name: area.name,
      width: area.width,
      height: area.height,
      posX: area.posX,
      posY: area.posY,
      referencePoint: area.referencePoint,
      index: area.index,
      isFix: area.isFix,
      isURL: area.isURL,
      text: area.getArea().text,
      fontName: area.getArea().fontName,
      fontSize: area.getArea().fontSize,
      isBold: area.getArea().isBold,
      isItalic: area.getArea().isItalic,
      horizontalTextAlignment: area.getArea().horizontalTextAlignment,
      verticalTextAlignment: area.getArea().verticalTextAlignment,
      orientation: area.getArea().orientation,
      scrollStatus: area.getArea().scrollStatus,
      scrollSpeed: area.getArea().scrollSpeed,
      scrollDirection: area.getArea().scrollDirection,
      linkReferenceData: area.getArea().linkReferenceData,
      indexWordGroupId: area.indexWordGroupId,
      referencePositionRow: area.referencePositionRow,
      referencePositionColumn: area.referencePositionColumn,
      timingOn: area.timingOn,
      timingOff: area.timingOff,
      backgroundColor: area.getArea().backgroundColor,
      fontColor: area.getArea().fontColor,
      durationDisplayTiming: area.durationDisplayTiming,
      timesFileEnd: area.getArea().timesFileEnd,
      isOnClickEvent: area.isOnClickEvent,
      onClickEventType: area.onClickEventType,
      onClickEventDestination: area.onClickEventDestination,
      arrivalTimeFormat: area.getArea().arrivalTimeFormat,
      isTimingOn: area.isTimingOn,
      timingType: area.timingType,
      stopDuration: area.getArea().stopDuration,
      timetableId: area.timetableId,
      referenceColumn: area.referenceColumn,
      idWithDisplay: area.idWithDisplay,
      typePageCount: +area.typePageCount
    };
  }

  /**
   * get all area template
   * @param template
   */
  public static getAllAreaTemplate(template: Template): Array<Area> {
    var areas: Array<Area> = new Array<Area>();
    if (template?.layers) {
      template.layers.forEach(layer => {
        layer.areas.forEach(area => {
          areas.push(area);
        });
      });
    }
    return areas;
  }

  /**
   * get all area template for display
   * @param templateDisplays
   * @returns list area
   */
  public static getAllAreaTemplateForDisplay(templateDisplays: Template[]): Array<Area> {
    if (!templateDisplays) {
      return;
    }
    var areas: Array<Area> = new Array<Area>();
    for (let i = 0; i < templateDisplays.length; i++) {
      if (templateDisplays[i]?.layers) {
        templateDisplays[i].layers.forEach(layer => {
          layer.areas.forEach(area => {
            areas.push(area);
          });
        });
      }
    }
    return areas;
  }

  /**
   * add/substract days from current value
   * @param date current date
   * @param increment increment (nagative for substract)
   */
  public static addDay(date: Date, increment: number): Date {
    let newDay = date.getDate() + increment;
    let newDate = new Date(date);
    newDate.setDate(newDay);
    return newDate;
  }

  /**
   * add/substract years from current value
   * @param date current date
   * @param increment increment (nagative for substract)
   */
  public static addYear(date: Date, increment: number): Date {
    let newYear = date.getFullYear() + increment;
    let newDate = new Date(date);
    newDate.setFullYear(newYear);
    return newDate;
  }

  /**
   * add/substract months from current value
   * @param date current date
   * @param increment increment (nagative for substract)
   */
  public static addMonth(date: Date, increment: number): Date {
    let newMonth = date.getMonth() + increment;
    let newDate = new Date(date);
    newDate.setMonth(newMonth);
    return newDate;
  }

  /**
   * return number of days in current month
   * @param date current date
   */
  public static daysInMonth(date: Date): number {
    let nextFirst = new Date(date.getFullYear(), date.getMonth() + 1, 1, 12);
    return this.addDay(nextFirst, -1).getDate();
  }

  /**
   * get calendars
   * @param channel
   */
  public static getCalendars(channel: any): Array<ContentDay> {
    let calendarDays = new Array<ContentDay>();
    let currentDate = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    currentDate.setMonth(currentDate.getMonth() - 1);
    // insert days in previous month
    let datePreviousMonth = currentDate;
    let daysPreviousMonth = this.daysInMonth(currentDate);
    let tempDay = new Date(datePreviousMonth);
    for (let day = 1; day <= daysPreviousMonth; day++) {
      tempDay = this.addDay(tempDay, 1);
      calendarDays.push(new ContentDay(tempDay.getDay(), new Date(tempDay)));
    }

    // insert days in current month to 12 current year
    for (let month = channel.startDate.getMonth() + 1; month <= 12; month++) {
      let dateCurrentMonth = this.getDateByMonth(channel.startDate.getFullYear(), month);
      let daysCurrentMonth = this.daysInMonth(dateCurrentMonth);
      for (let day = 1; day <= daysCurrentMonth; day++) {
        tempDay = this.addDay(tempDay, 1);
        calendarDays.push(new ContentDay(tempDay.getDay(), new Date(tempDay)));
      }
    }

    // insert days in month 1 to 12 next year
    for (let i = 1; i < Constant.MAX_YEAR; i++) {
      for (let month = 1; month <= 12; month++) {
        let dateCurrentMonthNextYear = this.getDateByMonth(channel.startDate.getFullYear() + i, month);
        let daysCurrentMonthNextYear = this.daysInMonth(dateCurrentMonthNextYear);
        for (let day = 1; day <= daysCurrentMonthNextYear; day++) {
          tempDay = this.addDay(tempDay, 1);
          calendarDays.push(new ContentDay(tempDay.getDay(), new Date(tempDay)));
        }
      }
    }

    // insert days in next month to previous finish month next year
    let dateEnd = _.cloneDeep(channel.startDate);
    dateEnd.setFullYear(dateEnd.getFullYear() + Constant.MAX_YEAR);
    dateEnd = this.getDateByDay(dateEnd.getFullYear(), dateEnd.getMonth() + 1, dateEnd.getDate());
    for (let month = 1; month <= dateEnd.getMonth() + 1; month++) {
      let dateNextMonth = this.getDateByMonth(dateEnd.getFullYear(), month);
      let daysNextMonth = this.daysInMonth(dateNextMonth);
      for (let day = 1; day <= daysNextMonth; day++) {
        tempDay = this.addDay(tempDay, 1);
        calendarDays.push(new ContentDay(tempDay.getDay(), new Date(tempDay)));
      }
    }

    // insert days in finish month next year
    let dateFinish = this.getDateByMonth(dateEnd.getFullYear(), dateEnd.getMonth() + 1);
    let daysInMonthEnd = this.daysInMonth(dateFinish);
    for (let day = 1; day <= daysInMonthEnd; day++) {
      tempDay = this.addDay(tempDay, 1);
      calendarDays.push(new ContentDay(tempDay.getDay(), new Date(tempDay)));
    }

    return calendarDays;
  }

  /**
   * get calendars by month year
   * @param month month
   * @param year year
   */
  public static getCalendarsByMonthYear(channel: any, month: number, year: number, commonObject): Array<ContentDay> {
    let calendarDays: Array<ContentDay> = new Array<ContentDay>();
    let currentDate = new Date(new Date(year, month).getFullYear(), new Date(year, month).getMonth());
    currentDate.setDate(0);
    let lastDatePreviousMonth = currentDate.getDate();
    let firstDateCurrentMonth = new Date(year, month, 1);
    let dayOfMonth = lastDatePreviousMonth - firstDateCurrentMonth.getDay();
    // get date previous month
    for (let day = lastDatePreviousMonth; day > dayOfMonth; day--) {
      let contentDays: Array<ContentDay>;
      if (month == 0) {
        contentDays = channel.calendarDays.filter(
          contentDay =>
            contentDay.fullDate.getDate() == day && contentDay.fullDate.getMonth() == 11 && contentDay.fullDate.getFullYear() == year - 1
        );
      } else {
        contentDays = channel.calendarDays.filter(
          contentDay =>
            contentDay.fullDate.getDate() == day && contentDay.fullDate.getMonth() == month - 1 && contentDay.fullDate.getFullYear() == year
        );
      }
      contentDays.forEach(contentDay => {
        contentDay.isOtherMonth = true;
        contentDay.inactive = true;
        calendarDays.unshift(contentDay);
      });
    }
    // get date current month
    let contentDays = channel.calendarDays.filter(
      contentDay => contentDay.fullDate.getMonth() == month && contentDay.fullDate.getFullYear() == year
    );
    contentDays.forEach(contentDay => {
      let dateEnd = _.cloneDeep(channel.startDate);
      dateEnd.setFullYear(dateEnd.getFullYear() + Constant.MAX_YEAR);
      dateEnd.setDate(dateEnd.getDate() - 1);
      let now = this.getCurrentByTimezoneSetting(commonObject, false);
      if (
        contentDay.fullDate < this.getDateByDay(new Date().getFullYear(), new Date().getMonth() + 1, channel.startDate.getDate()) ||
        contentDay.fullDate > this.getDateByDay(dateEnd.getFullYear(), dateEnd.getMonth() + 1, dateEnd.getDate())
      ) {
        contentDay.isOtherMonth = false;
        contentDay.inactive = true;
      } else {
        contentDay.isOtherMonth = false;
        contentDay.inactive = false;
      }
      calendarDays.push(contentDay);
    });
    // get date next month
    let contentDayEnds: Array<ContentDay>;
    if (month == 11) {
      contentDayEnds = channel.calendarDays.filter(
        contentDay => contentDay.fullDate.getMonth() == 0 && contentDay.fullDate.getFullYear() == year + 1
      );
    } else {
      contentDayEnds = channel.calendarDays.filter(
        contentDay => contentDay.fullDate.getMonth() == month + 1 && contentDay.fullDate.getFullYear() == year
      );
    }

    contentDayEnds.forEach(contentDay => {
      if (calendarDays.length >= 42) {
        return;
      }
      contentDay.isOtherMonth = true;
      contentDay.inactive = true;
      calendarDays.push(contentDay);
    });

    return calendarDays;
  }

  /**
   * get date contain content day current month
   * @param calendars
   * @param contentDay
   */
  public static getDateContainContentDayCurrentMonth(
    calendars: Array<ContentDay>,
    contentDay: ContentDay,
    typeDataSettingContentDay: string
  ): Array<Date> {
    let listIndex = new Array<Date>();
    let year = contentDay.startDate.getFullYear();
    let month = contentDay.startDate.getMonth() + 1;

    let dateData = this.getDateByMonth(year, month);
    let dayInsMonth = Helper.daysInMonth(dateData);
    for (let day = contentDay.startDate.getDate(); day <= dayInsMonth; day++) {
      let date = this.getDateByDay(year, month, day);
      let contentDayByFullDate = calendars.find(contentDay => contentDay.fullDate.getTime() === date.getTime());
      if (contentDayByFullDate && contentDayByFullDate[typeDataSettingContentDay]) {
        listIndex.push(date);
      }
    }
    return listIndex;
  }

  /**
   * get date contain content day other month
   * @param calendars
   * @param month
   * @param year
   */
  public static getDateContainContentDayOtherMonth(
    calendars: Array<ContentDay>,
    month: number,
    year: number,
    typeDataSettingContentDay: string
  ): Array<Date> {
    let listIndex = new Array<Date>();
    let dateData = this.getDateByMonth(year, month);
    let dayInsMonth = Helper.daysInMonth(dateData);
    for (let day = 1; day <= dayInsMonth; day++) {
      let date = this.getDateByDay(year, month, day);
      let contentDayByFullDate = calendars.find(contentDay => contentDay.fullDate.getTime() === date.getTime());
      if (contentDayByFullDate && contentDayByFullDate[typeDataSettingContentDay]) {
        listIndex.push(date);
      }
    }
    return listIndex;
  }

  /**
   * re-create day list on calendar for current month
   * @param channel
   * @param startActiveDay
   */
  public static updateDates(channel: Channel, startActiveDay: Date): void {
    channel.calendarDays = new Array<ContentDay>();
    channel.calendarDays.push(new ContentDay(startActiveDay.getDay(), startActiveDay));
    let tempDay = new Date(startActiveDay);
    if (startActiveDay.getDate() > 1) {
      // insert previous days of start month
      let startActiveDate = startActiveDay.getDate();
      for (let day = startActiveDate; day > 1; day--) {
        tempDay = Helper.addDay(tempDay, -1);
        channel.calendarDays.unshift(new ContentDay(tempDay.getDay(), new Date(tempDay), true));
      }
    }
    let startWeekDay = tempDay.getDay();
    if (startWeekDay > 0) {
      // insert previous days of start week
      for (let day = startWeekDay - 1; day >= 0; day--) {
        tempDay = Helper.addDay(tempDay, -1);
        channel.calendarDays.unshift(new ContentDay(tempDay.getDay(), new Date(tempDay), true, true));
      }
    }
    let daysInMonth = Helper.daysInMonth(startActiveDay);
    tempDay = new Date(startActiveDay);
    if (startActiveDay.getDate() < daysInMonth) {
      // insert days in current month
      for (let day = startActiveDay.getDate() + 1; day <= daysInMonth; day++) {
        tempDay = Helper.addDay(tempDay, 1);
        channel.calendarDays.push(new ContentDay(tempDay.getDay(), new Date(tempDay)));
      }
    }
    let lastWeekDay = tempDay.getDay();
    if (lastWeekDay < 6) {
      // insert days of next month
      for (let weekday = lastWeekDay + 1; weekday <= 6; weekday++) {
        tempDay = Helper.addDay(tempDay, 1);
        channel.calendarDays.push(new ContentDay(tempDay.getDay(), new Date(tempDay), false, true));
      }
    }
    while (channel.calendarDays.length < 42) {
      tempDay = Helper.addDay(tempDay, 1);
      channel.calendarDays.push(new ContentDay(tempDay.getDay(), new Date(tempDay), false, true));
    }
  }
  /**
   * convert channel
   * @param channelData channel
   */
  public static convertDataChannel(channelData: any): Channel {
    let channel = new Channel();
    channel.id = channelData['id'];
    channel.name = channelData['name'];
    channel.projectId = channelData['projectId'];
    let startDate = channelData['startDate'];
    let stringDate = startDate.split('-');
    let day = stringDate[2].split('T');
    channel.startDate = this.getDateByDay(+stringDate[0], +stringDate[1], +day[0]);
    return channel;
  }

  /**
   * get GMT time zone
   */
  public static getUserTimeZone(setting: UserSetting): any {
    var GMTTimeZone = '+00:00';
    if (setting) {
      var timeZone = setting.timezone;
      if (timeZone.offsetHour < -9) {
        GMTTimeZone = timeZone.offsetHour + ':';
      } else if (timeZone.offsetHour >= -9 && timeZone.offsetHour < 0) {
        GMTTimeZone = '-' + '0' + -timeZone.offsetHour + ':';
      } else {
        GMTTimeZone = '+' + (timeZone.offsetHour + '').padStart(2, '0') + ':';
      }
      if (timeZone.offsetMinute < 0) {
        GMTTimeZone += -timeZone.offsetMinute;
      } else {
        GMTTimeZone += (timeZone.offsetMinute + '').padStart(2, '0');
      }
    }
    return GMTTimeZone;
  }

  /**
   * convert data channel forward
   * @param channel
   */
  public static convertDataChannelForward(channel: Channel, setting: UserSetting): any {
    let contentDays = channel.contentDays
      .filter(contentDayData => contentDayData.playlist?.id)
      .map(contentDay => {
        return this.convertDataContentDayForward(contentDay, setting);
      });
    let GMTTimeZone = this.getUserTimeZone(setting);
    return {
      id: channel.id,
      name: channel.name,
      startDate: moment(channel.startDate).format('YYYY-MM-DD') + 'T00:00:00' + GMTTimeZone,
      projectId: channel.projectId,
      contentDays: contentDays
    };
  }

  /**
   * convert data contentDay
   * @param contentDayData
   */
  public static convertDataContentDay(contentDayData: any): ContentDay {
    let contentDay: ContentDay = new ContentDay();
    contentDay.id = contentDayData['id'];
    if (contentDayData['playlist']) {
      contentDay.playlist = this.convertDataPlaylist(contentDayData['playlist']);
    }
    if (contentDayData['simplePlaylist']) {
      contentDay.playlistSimple = contentDayData['simplePlaylist'];
    }
    if (contentDayData['playlistId']) {
      contentDay.playlistSimpleId = contentDayData['playlistId'];
      contentDay.unlimitedInfo = this.getUnlimitedDataInformation(contentDayData['unlimitedInfo']);
    }
    contentDay.fullDate = this.getContentDayFullDate(contentDayData['fullDate']);
    if (contentDayData['color']) {
      contentDay.color = contentDayData['color'];
    }
    contentDay.isDelivered = contentDayData['isDelivered'];
    return contentDay;
  }

  /**
   * Get content day full date
   *
   * @param fullDate
   * @returns
   */
  private static getContentDayFullDate(fullDate: any): Date {
    let stringDate = fullDate.split('-');
    let day = stringDate[2].split('T');
    return this.getDateByDay(+stringDate[0], +stringDate[1], +day[0]);
  }

  /**
   * Convert data content day for timetable
   *
   * @param contentDayData
   * @param timetables
   * @returns
   */
  public static convertDataContentDayForTimetable(contentDayData: any, timetables?: Timetable[]): ContentDay {
    let contentDay: ContentDay = new ContentDay();
    contentDay.id = contentDayData['id'];
    if (contentDayData['timetableId']) {
      contentDay.timetableId = contentDayData['timetableId'];
      contentDay.unlimitedInfo = this.getUnlimitedDataInformation(contentDayData['unlimitedInfo']);
    }
    if (contentDayData['timetable']) {
      contentDay.timetable = this.convertDataTimetable(contentDayData['timetable']);
      if (timetables) {
        let index = timetables.findIndex(timetable => timetable.id == contentDay.timetableId);
        if (index != -1) {
          contentDay.timetable.timetableSchedule = timetables[index].timetableSchedule;
        }
      }
    }
    contentDay.fullDate = this.getContentDayFullDate(contentDayData['fullDate']);
    if (contentDayData['color']) {
      contentDay.color = contentDayData['color'];
    }
    contentDay.isDelivered = contentDayData['isDelivered'];
    return contentDay;
  }

  /**
   * Get unlimited info
   *
   * @param unlimitedInfo
   * @returns
   */
  private static getUnlimitedDataInformation(unlimitedInfo: string): any {
    if (!unlimitedInfo) {
      return null;
    }
    return JSON.parse(unlimitedInfo);
  }

  /**
   * convert data contentDay forward
   * @param contentDay
   */
  public static convertDataContentDayForward(contentDay: ContentDay, setting: UserSetting): any {
    let GMTTimeZone = this.getUserTimeZone(setting);
    return {
      id: contentDay?.id,
      playlistId: contentDay.playlist?.id,
      fullDate: moment(contentDay.fullDate).format('YYYY-MM-DD') + 'T00:00:00' + GMTTimeZone
    };
  }

  /**
   * convert program data
   * @param programData programData
   */
  public static convertDataProgram(programData: any): Program {
    let program = new Program();
    program.id = programData['id'];
    program.startTime = programData['startTime'];
    program.duration = programData['duration'];
    program.media = programData['media'] ? Helper.convertMediaData(programData['media']) : undefined;
    return program;
  }

  /**
   * convert playlist
   * @param playlistData playlist
   */
  public static convertDataPlaylist(playlistData: any): DailySchedule {
    let playlist = new DailySchedule();
    playlist.id = playlistData['id'];
    playlist.name = playlistData['name'];
    playlist.color = playlistData['color'];
    playlist.projectId = playlistData['projectId'];
    return playlist;
  }

  /**
   * convert data playlist forward
   * @param playlist
   */
  public static convertDataPlaylistForward(playlist: DailySchedule): any {
    return {
      id: playlist.id,
      name: playlist.name,
      color: playlist.color,
      projectId: playlist.projectId
    };
  }

  /**
   * convert list channel
   * @param channelDatas list channel data
   */
  public static convertChannelsData(channelDatas: any[]): Array<Channel> {
    return channelDatas.map(channelData => this.convertDataChannel(channelData));
  }

  /**
   * convert data styles
   * @param styleDatas list styles data
   */
  public static convertDataStyles(styleDatas: any[]): Array<Style> {
    return styleDatas?.map(style => this.convertDataStyle(style));
  }

  /**
   * convert data style
   * @param styleData style data
   */
  public static convertDataStyle(styleData: any): Style {
    let style = new Style();
    style.id = styleData['id'];
    style.name = styleData['name'];
    style.styleNo = styleData['styleNo'];
    style.suffix = styleData['suffix'];
    style.projectId = styleData['projectId'];
    if (styleData['templateDisplay1s']) {
      style.display1Templates = styleData['templateDisplay1s'].map((display1Data: any) => {
        return this.convertDataTemplateBackward(display1Data);
      });
    }
    if (styleData['templateDisplay2s']) {
      style.display2Templates = styleData['templateDisplay2s'].map((display2Data: any) => {
        return this.convertDataTemplateBackward(display2Data);
      });
    }
    if (styleData['display1Templates']) {
      style.displayTemplate1 = this.convertDataDisplayTemplate(styleData['display1Templates']);
    }
    if (styleData['display2Templates']) {
      style.displayTemplate2 = this.convertDataDisplayTemplate(styleData['display2Templates']);
    }
    return style;
  }

  /**
   * convert data details information for style
   * @param styleDetailDatas list details style data
   */
  public static convertDataDetailsStyle(styleDetailDatas: any[]): Array<StyleDetail> {
    return styleDetailDatas?.map(styleDetail => this.convertDataDetailStyle(styleDetail));
  }

  /**
   * convert Timetable Details
   * @param timetableDetail
   * @returns
   */
  public static convertTimetableDetails(timetableDetail: any[]): Array<TimetableDetail> {
    return timetableDetail?.map(timetable => this.convertDataDetailTimetable(timetable));
  }

  /**
   * convert Timetable Details
   * @param timetableDetail
   * @returns
   */
  public static convertTicket(data: any[]): Array<Ticket> {
    return data?.map(ticket => this.convertDataTicket(ticket));
  }

  /**
   * convert Timetable Url Details
   * @param timetableDetail
   * @returns
   */
  public static convertTimetableUrlDetails(timetableDetail: any[]): Array<TimetableDetailURLArea> {
    return timetableDetail?.map(timetable => this.convertDataDetailURLTimetable(timetable));
  }

  /**
   * convert data style backward
   * @param styleDetail
   */
  public static convertDataStyleBackward(style: Style): any {
    return {
      id: style.id,
      name: style.name,
      styleNo: style.styleNo,
      suffix: style.suffix,
      projectId: style.projectId,
      display1Templates: JSON.stringify(style.displayTemplate1),
      display2Templates: JSON.stringify(style.displayTemplate2),
      styleDetails: style.styleDetails?.map(styleDetail => this.convertDataDetailStyleBackward(styleDetail))
    };
  }

  /**
   * convert data detail style backward
   * @param styleDetail
   */
  public static convertDataDetailStyleBackward(styleDetail: StyleDetail): any {
    return {
      id: styleDetail.id,
      areaDisplayId1: styleDetail?.areaDisplay1?.id,
      areaDisplayId2: styleDetail?.areaDisplay2?.id,
      mediaSoundId: styleDetail.mediaSound?.id,
      mediaMainId: styleDetail.mediaMain?.id,
      text: styleDetail.text != '' ? styleDetail.text : null,
      styleId: styleDetail.styleId
    };
  }

  /**
   * Convert data detail timetable backward
   *
   * @param timetableDetail
   * @returns
   */
  public static convertDataDetailTimetableBackward(timetableDetail: TimetableDetail): any {
    return {
      id: timetableDetail.id,
      areaDisplay1: timetableDetail.areaDisplay1?.id,
      areaDisplay2: timetableDetail.areaDisplay2?.id,
      mediaId: timetableDetail.mediaId,
      text: timetableDetail.text != '' ? timetableDetail.text : null,
      timetableId: timetableDetail.timetableId
    };
  }

  /**
   * Convert data url detail timetable backward
   * @param timetableDetail
   * @returns
   */
  public static convertDataUrlDetailTimetableBackward(timetableDetail: TimetableDetailURLArea): any {
    return {
      id: timetableDetail.id,
      areaDisplay1: timetableDetail.areaDisplay1?.id,
      areaDisplay2: timetableDetail.areaDisplay2?.id,
      textUrl: timetableDetail.textUrl != '' ? timetableDetail.textUrl : null,
      timetableId: timetableDetail.timetableId
    };
  }

  /**
   * convert data style
   * @param styleDetailData style detail data
   */
  public static convertDataDetailStyle(styleDetailData: any): StyleDetail {
    let styleDetail = new StyleDetail();
    styleDetail.id = styleDetailData['id'];
    styleDetail.areaDisplayId1 = styleDetailData['areaDisplayId1'];
    styleDetail.areaDisplayId2 = styleDetailData['areaDisplayId2'];
    styleDetail.mediaMainId = styleDetailData['mediaMainId'];
    styleDetail.mediaSoundId = styleDetailData['mediaSoundId'];
    styleDetail.styleId = styleDetailData['styleId'];
    let mediasOutput: Array<Media> = styleDetailData['medias'];
    const medias = mediasOutput?.map(mediaData => this.convertMediaData(mediaData));
    styleDetail.mediaMain = _.get(medias, `[${Constant.MEDIA_TYPE_IMG}]`, undefined);
    styleDetail.mediaSound = _.get(medias, `[${Constant.MEDIA_TYPE_MP3}]`, undefined);
    let areaDisplay1 = styleDetailData['areaDisplay1'];
    if (areaDisplay1) {
      styleDetail.areaDisplay1 =
        areaDisplay1.type == this.TEXT_AREA_TYPE
          ? Helper.convertDataTextArea(areaDisplay1, undefined, 'Display1')
          : Helper.convertDataPictureArea(areaDisplay1, undefined, 'Display1');
    }
    let areaDisplay2 = styleDetailData['areaDisplay2'];
    if (areaDisplay2) {
      styleDetail.areaDisplay2 =
        areaDisplay2.type == this.TEXT_AREA_TYPE
          ? Helper.convertDataTextArea(areaDisplay2, undefined, 'Display2')
          : Helper.convertDataPictureArea(areaDisplay2, undefined, 'Display2');
    }
    styleDetail.text = styleDetailData['text'];
    return styleDetail;
  }

  /**
   * Convert data detail timetable
   * @param timetableDetailData
   * @returns timetableDetail
   */
  public static convertDataDetailTimetable(timetableDetailData: any): TimetableDetail {
    let timetableDetail = new TimetableDetail();
    timetableDetail.id = timetableDetailData['id'];
    timetableDetail.areaDisplay1Id = timetableDetailData['areaDisplay1'];
    timetableDetail.areaDisplay2Id = timetableDetailData['areaDisplay2'];
    timetableDetail.mediaId = timetableDetailData['mediaId'];
    timetableDetail.text = timetableDetailData['text'];
    timetableDetail.timetableId = timetableDetailData['timetableId'];
    timetableDetail.media = this.convertMediaData(timetableDetailData['media']);
    let areaDisplay1 = timetableDetailData['areaDisplayTemplate1'];
    if (areaDisplay1) {
      timetableDetail.areaDisplay1 =
        areaDisplay1.type == this.TEXT_AREA_TYPE
          ? Helper.convertDataTextArea(areaDisplay1, undefined, 'Display1')
          : Helper.convertDataPictureArea(areaDisplay1, undefined, 'Display1');
    }
    let areaDisplay2 = timetableDetailData['areaDisplayTemplate2'];
    if (areaDisplay2) {
      timetableDetail.areaDisplay2 =
        areaDisplay2.type == this.TEXT_AREA_TYPE
          ? Helper.convertDataTextArea(areaDisplay2, undefined, 'Display2')
          : Helper.convertDataPictureArea(areaDisplay2, undefined, 'Display2');
    }
    return timetableDetail;
  }

  /**
   * Convert data detail timetable
   * @param timetableDetailData
   * @returns timetableDetail
   */
  public static convertDataTicket(data: any): Ticket {
    let ticket = new Ticket();
    ticket.ticketId = data['ticketId'];
    ticket.ticketType = data['ticketType'];
    ticket.ticketName = data['ticketName'];
    ticket.companyName = data['companyName'];
    ticket.companyId = data['companyId'];
    ticket.operatorId = data['operatorId'];
    ticket.operatorName = data['operatorName'];
    ticket.appId = data['appId'];
    ticket.appName = data['appName'];
    ticket.priceCustom1 = data['priceCustom1'];
    ticket.usingPeriod = data['usingPeriod'];
    ticket.usingPeriodType = data['usingPeriodType'];
    ticket.updateScheduleAt = data['updateScheduleAt'];
    ticket.inUse = data['inUse'];
    ticket.isReserve = data['isReserve'];
    return ticket;
  }

  /**
   * convert Timetable Details
   * @param timetableDetail
   * @returns
   */
  public static convertSalesTicket(data: any[]): Array<SaleTicket> {
    return data?.map(salesTicket => this.convertDataSalesTicket(salesTicket));
  }

  /**
   * Convert data detail timetable
   * @param timetableDetailData
   * @returns timetableDetail
   */
  public static convertDataSalesTicket(data: any): SaleTicket {
    let saleTicket = new SaleTicket();
    saleTicket.saleTicketId = data['saleTicketId'];
    saleTicket.ticketName = data['ticketName'];
    saleTicket.appId = data['appId'];
    saleTicket.appName = data['appName'];
    saleTicket.companyId = data['companyId'];
    saleTicket.companyName = data['companyName'];
    saleTicket.priceAdult = data['priceAdult'];
    saleTicket.priceCustom1 = data['priceCustom1'];
    saleTicket.priceCustom2 = data['priceCustom2'];
    saleTicket.priceCustom3 = data['priceCustom3'];
    saleTicket.status = data['status'];
    return saleTicket;
  }

  /**
   * Convert data url detail timetable
   * @param timetableDetailData
   * @returns timetableUrlDetail
   */
  public static convertDataDetailURLTimetable(timetableDetailData: any): TimetableDetailURLArea {
    let timetableDetail = new TimetableDetailURLArea();
    timetableDetail.id = timetableDetailData['id'];
    timetableDetail.areaDisplay1Id = timetableDetailData['areaDisplay1'];
    timetableDetail.areaDisplay2Id = timetableDetailData['areaDisplay2'];
    timetableDetail.textUrl = timetableDetailData['textUrl'];
    timetableDetail.timetableId = timetableDetailData['timetableId'];
    timetableDetail.media = this.convertMediaData(timetableDetailData['media']);
    let areaDisplay1 = timetableDetailData['areaDisplayTemplate1'];
    if (areaDisplay1) {
      timetableDetail.areaDisplay1 = Helper.convertDataURLArea(areaDisplay1, undefined, 'Display1');
    }
    let areaDisplay2 = timetableDetailData['areaDisplayTemplate2'];
    if (areaDisplay2) {
      timetableDetail.areaDisplay2 = Helper.convertDataURLArea(areaDisplay2, undefined, 'Display1');
    }
    return timetableDetail;
  }

  /**
   * get date by day
   * @param year year
   * @param month month
   * @param day day
   */
  public static getDateByDay(year: number, month: number, day: number): Date {
    return new Date(year, month - 1, day);
  }

  /**
   * get date by month
   * @param year year
   * @param month month
   */
  public static getDateByMonth(year: number, month: number): Date {
    return new Date(year, month - 1);
  }

  /**
   * convert data index word
   * @param indexWordData
   */
  public static convertDataIndexWord(indexWordData: any): IndexWord {
    let indexWord = new IndexWord();
    indexWord.id = indexWordData['id'];
    indexWord.name = indexWordData['name'];
    indexWord.groupId = indexWordData['groupId'];
    indexWord.orderNo = indexWordData['orderNo'];
    if (indexWordData['media']) {
      indexWord.media = this.convertMediaData(indexWordData['media']);
    }
    if (indexWordData['text']) {
      indexWord.text = indexWordData['text'];
    }
    return indexWord;
  }

  /**
   * convert data indexWord forward
   * @param indexWord
   */
  public static convertDataIndexWordForward(indexWord: IndexWord): any {
    return {
      id: indexWord.id,
      name: indexWord.name,
      mediaId: indexWord.media?.id,
      media: indexWord.media,
      text: indexWord.text?.trim() == Constant.EMPTY ? indexWord.text?.trim() : indexWord.text,
      groupId: indexWord.groupId
    };
  }

  /**
   * convert data route
   * @param routeData
   */
  public static convertDataRoute(routeData: any): Route {
    let route: Route = new Route();
    route.id = routeData['id'];
    route.name = routeData['name'];
    route.routeNo = routeData['routeNo'];
    route.suffix = routeData['suffix'];
    route.projectId = routeData['projectId'];
    route.displayId1 = routeData['displayId1'];
    route.displayId2 = routeData['displayId2'];
    if (routeData['label']) {
      route.label = routeData['label'];
    }
    if (routeData['display1']) {
      route.display1 = this.convertDataTemplateBackward(routeData['display1']);
    }
    if (routeData['display2']) {
      route.display2 = this.convertDataTemplateBackward(routeData['display2']);
    }
    route.busStops = new Array<BusStop>();
    return route;
  }

  /**
   * convert data route forward
   * @param route
   */
  public static convertDataRouteForward(route: Route): any {
    return {
      id: route.id,
      name: route.name,
      routeNo: route.routeNo,
      suffix: route.suffix,
      labelId: route.label?.id,
      label: route.label,
      displayId1: route.display1?.id,
      display1: route.display1,
      displayId2: route.display2?.id,
      display2: route.display2,
      projectId: route.projectId,
      busStops: route?.busStops
    };
  }

  /**
   * convert data route to save in route list editor
   * @param route
   */
  public static convertDataRouteOriginal(route: Route): any {
    return {
      id: route.id,
      name: route.name,
      routeNo: route.routeNo,
      suffix: route.suffix,
      labelId: route.label?.id,
      displayId1: route.displayId1,
      displayId2: route.displayId2,
      projectId: route.projectId,
      busStops: route?.busStops
    };
  }

  /**
   * convert data bus stop
   * @param busStopData
   * @param route
   */
  public static convertDataBusStop(busStopData: any, route?: Route): BusStop {
    let busStop = new BusStop();
    busStop.id = busStopData['id'];
    busStop.name = busStopData['name'];
    busStop.no = busStopData['no'];
    busStop.suffix = busStopData['suffix'];
    busStop.orderNo = busStopData['orderNo'];
    busStop.media = busStopData['media'];
    busStop.mediaId = busStopData['mediaId'];
    busStop.routeBustopId = busStopData['routeBustopId'];
    busStop.busStopID = busStop.suffix ? `${busStop.no}-${busStop.suffix}` : `${busStop.no}-`;
    if (busStopData['label']) {
      busStop.label = busStopData['label'];
    }
    if (busStopData['indexWords']) {
      busStop.indexWords = busStopData['indexWords'].map((indexWordData: any) => {
        return this.convertDataIndexWord(indexWordData);
      });
    }
    if (busStopData['events'] && route) {
      busStop.events = busStopData['events'].map((eventBusStop: any) => {
        return this.convertDataEventBusStop(eventBusStop, route);
      });
    }
    if (busStopData['templateDisplay1s']) {
      busStop.display1Templates = busStopData['templateDisplay1s'].map((display1Data: any) => {
        return this.convertDataTemplateBackward(display1Data);
      });
    }
    if (busStopData['templateDisplay2s']) {
      busStop.display2Templates = busStopData['templateDisplay2s'].map((display2Data: any) => {
        return this.convertDataTemplateBackward(display2Data);
      });
    }
    if (busStopData['display1Templates']) {
      busStop.displayTemplate1 = this.convertDataDisplayTemplate(busStopData['display1Templates']);
    }
    if (busStopData['display2Templates']) {
      busStop.displayTemplate2 = this.convertDataDisplayTemplate(busStopData['display2Templates']);
    }

    return busStop;
  }

  /**
   * convert data bus stop forward
   * @param busStop
   */
  public static convertDataBusStopForward(busStop: BusStop): any {
    return {
      id: busStop.id,
      name: busStop.name,
      no: busStop.no,
      suffix: busStop.suffix,
      orderNo: busStop.orderNo,
      labelId: busStop.label?.id,
      label: busStop.label,
      indexWords: busStop.indexWords,
      display1Templates: JSON.stringify(busStop.displayTemplate1),
      display2Templates: JSON.stringify(busStop.displayTemplate2)
    };
  }

  /**
   * convert data busStops BID
   * @param busStopsData
   * @param route
   */
  public static convertDataBusStopsBID(busStopsData: any[], route: Route): Array<BusStop> {
    return busStopsData.map(busStopData => this.convertDataBusStop(busStopData, route));
  }

  /**
   * convert data project
   * @param projectData
   */
  public static convertDataProject(projectData: any): Project {
    let project = new Project();
    project.id = projectData['id'];
    project.name = projectData['name'];
    project.description = projectData['description'];
    project.creationTime = projectData['creationTime'];
    project.hasBid = projectData['hasBid'];
    project.hasDsc = projectData['hasDsc'];
    project.hasEds = projectData['hasEds'];
    project.hasBsd = projectData['hasBsd'];
    project.hasSd = projectData['hasSd'];
    project.isPublic = projectData['isPublic'];
    if (projectData['projectMembers']) {
    }
    return project;
  }

  /**
   * convert date to string
   * @param date Date
   */
  public static convertDateToString(date: Date): string {
    return moment(date).format('YYYY-MM-DD');
  }

  /**
   * convert date to format string
   * @param date date
   */
  public static convertDateToFormatString(date: Date): string {
    return moment(date).format('YYYY/MM/DD hh:mm A');
  }

  /**
   * get max height
   */
  public static getMaxHeight(): number {
    let value = 0;
    if (Constant.PLAY_SEGMENT == 120) {
      value = Constant.CELL_HEIGHT * 12;
    } else if (Constant.PLAY_SEGMENT == 30) {
      value = Constant.CELL_HEIGHT * 48;
    } else if (Constant.PLAY_SEGMENT == 15) {
      value = Constant.CELL_HEIGHT * 96;
    } else if (Constant.PLAY_SEGMENT == 5) {
      value = Constant.CELL_HEIGHT * 288;
    } else if (Constant.PLAY_SEGMENT == 2) {
      value = Constant.CELL_HEIGHT * 720;
    } else if (Constant.PLAY_SEGMENT == 1) {
      value = Constant.CELL_HEIGHT * 1440;
    }
    return value;
  }

  /**
   * check if the dragged program is overlapped with another program on top
   * @param dragged dragged program
   * @param nearby another program
   */
  public static isOverlapTop(dragged: Program, nearby: Program): boolean {
    let value = this.getMaxHeight();
    return (
      (dragged.symbol != nearby.symbol &&
        dragged.startPixel < nearby.startPixel + nearby.durationPixel &&
        dragged.startPixel > nearby.startPixel) ||
      dragged.startPixel < 0 ||
      dragged.startPixel + dragged.durationPixel > value
    );
  }

  /**
   * check if the dragged program is overlapped with another program at bottom
   * @param dragged dragged program
   * @param nearby dragged program
   */
  public static isOverlapBottom(dragged: Program, nearby: Program): boolean {
    let value = this.getMaxHeight();
    return (
      (dragged.symbol != nearby.symbol &&
        nearby.startPixel < dragged.startPixel + dragged.durationPixel &&
        nearby.startPixel >= dragged.startPixel) ||
      dragged.durationPixel <= 0 ||
      dragged.startPixel + dragged.durationPixel > value
    );
  }

  /**
   * create canvas template
   * @param template template
   * @param canvasContainerDisplay ElementRef
   * @param renderer
   */
  public static createCanvasTemplate(template: Template, canvasContainerDisplay: any, renderer: Renderer2): void {
    const canvas = renderer.createElement('canvas');
    canvas.style.position = 'absolute';
    canvas.style.background = '#000';
    canvas.style.width = template.width + 'px';
    canvas.style.height = template.height + 'px';
    canvas.width = template.width;
    canvas.height = template.height;
    renderer.appendChild(canvasContainerDisplay.nativeElement, canvas);
  }

  /**
   * create canvas template
   * @param template template
   * @param canvasContainerDisplay ElementRef
   * @param renderer
   */
  public static createCanvasTemplateExternalContentManager(template: Template, canvasContainerDisplay: any, renderer: Renderer2): void {
    const canvas = renderer.createElement('canvas');
    canvas.id = 'previewCanvas';
    canvas.style.position = 'absolute';
    canvas.style.background = '#000';
    canvas.style.width = template.width + 'px';
    canvas.style.height = template.height + 'px';
    canvas.width = template.width;
    canvas.height = template.height;
    renderer.appendChild(canvasContainerDisplay.nativeElement, canvas);
  }

  /**
   * create all canvas area template
   * @param template Template
   * @param canvasContainerDisplay ElementRef
   * @param renderer
   */
  public static createAllCanvasAreaTemplate(
    template: Template,
    canvasContainerDisplay: any,
    renderer: Renderer2,
    isPreviewEmergency: boolean
  ): void {
    template?.layers &&
      template.layers.forEach(layer => {
        layer.areas.forEach(area => {
          this.createCanvasArea(area, canvasContainerDisplay, renderer, isPreviewEmergency);
        });
      });
  }

  /**
   * create canvas area
   * @param area Area
   * @param canvasContainerDisplay ElementRef
   * @param renderer
   */
  public static createCanvasArea(area: Area, canvasContainerDisplay: any, renderer: Renderer2, isPreviewEmergency: boolean): void {
    if (
      !isPreviewEmergency &&
      (area.getArea().attribute == LinkDataPictureEnum.EMERGENCY_MESSAGE ||
        area.getArea().linkReferenceData == LinkDataTextEnum.EMERGENCY_MESSAGE)
    ) {
      return;
    }
    const canvas = renderer.createElement('canvas');
    canvas.style.position = 'absolute';
    canvas.style.zIndex = area.index;
    canvas.style.left = area.posX + 'px';
    canvas.style.top = area.posY + 'px';
    canvas.style.width = area.width + 'px';
    canvas.style.height = area.height + 'px';
    canvas.width = area.width;
    canvas.height = area.height;
    canvas.style.visibility = area.isHidden ? 'hidden' : 'visible';
    renderer.appendChild(canvasContainerDisplay.nativeElement, canvas);
    area.canvas = canvas;
  }

  /**
   * convert data bus stops
   * @param data
   */
  public static convertDataBusStopsData(data: any[]): Array<BusStop> {
    return data.map(busStop => this.convertDataBusStop(busStop));
  }

  /**
   * convert data routes
   * @param data
   */
  public static convertDataRoutesForStation(data: any[]): Array<Route> {
    return data.map(route => this.convertDataRouteForStation(route));
  }

  /**
   * convert data route for station
   * @param routeData
   */
  public static convertDataRouteForStation(routeData: any): Route {
    let route: Route = new Route();
    route.id = routeData['id'];
    route.name = routeData['name'];
    route.routeNo = routeData['routeNo'];
    route.suffix = routeData['suffix'];
    route.projectId = routeData['projectId'];
    route.routeID = route.suffix ? `${route.routeNo}-${route.suffix}` : `${route.routeNo}-`;
    if (routeData['label']) {
      route.label = routeData['label'];
    }
    if (routeData['busStops']) {
      route.busStops = routeData['busStops'].map((busStopData: any) => this.convertDataBusStop(busStopData));
    }
    return route;
  }

  /**
   * convert data route bus stop forward for station
   * @param routeBusStop
   */
  public static convertDataRouteBusStopForwardForStation(routeBusStop: RouteBusStop): any {
    return {
      id: routeBusStop.id,
      routeId: routeBusStop.routeId,
      busStopId: routeBusStop.busStopId,
      orderNo: routeBusStop.orderNo,
      mediaId: routeBusStop.media ? routeBusStop.media.id : undefined
    };
  }

  /**
   * convert data display template
   * @param displayTemplateData
   */
  public static convertDataDisplayTemplate(displayTemplateData: string): DisplayTemplate {
    let displayTemplate: DisplayTemplate = new DisplayTemplate();

    let displayTemplateOutput = JSON.parse(displayTemplateData);
    displayTemplate.idMainPage = displayTemplateOutput.idMainPage ?? null;
    displayTemplate.idSubPage1 = displayTemplateOutput.idSubPage1 ?? null;
    displayTemplate.idSubPage2 = displayTemplateOutput.idSubPage2 ?? null;
    displayTemplate.idSubPage3 = displayTemplateOutput.idSubPage3 ?? null;
    displayTemplate.idSubPage4 = displayTemplateOutput.idSubPage4 ?? null;
    displayTemplate.idSubPage5 = displayTemplateOutput.idSubPage5 ?? null;
    displayTemplate.idSubPage6 = displayTemplateOutput.idSubPage6 ?? null;
    displayTemplate.idSubPage7 = displayTemplateOutput.idSubPage7 ?? null;
    displayTemplate.idSubPage8 = displayTemplateOutput.idSubPage8 ?? null;
    displayTemplate.idSubPage9 = displayTemplateOutput.idSubPage9 ?? null;
    displayTemplate.idEmergencyPage = displayTemplateOutput.idEmergencyPage ?? null;
    displayTemplate.externalContentMainPage = displayTemplateOutput.externalContentMainPage ?? null;
    displayTemplate.externalContentPage1 = displayTemplateOutput.externalContentPage1 ?? null;
    displayTemplate.externalContentPage2 = displayTemplateOutput.externalContentPage2 ?? null;
    displayTemplate.externalContentPage3 = displayTemplateOutput.externalContentPage3 ?? null;
    displayTemplate.externalContentPage4 = displayTemplateOutput.externalContentPage4 ?? null;
    displayTemplate.externalContentPage5 = displayTemplateOutput.externalContentPage5 ?? null;
    displayTemplate.externalContentPage6 = displayTemplateOutput.externalContentPage6 ?? null;
    displayTemplate.externalContentPage7 = displayTemplateOutput.externalContentPage7 ?? null;
    displayTemplate.externalContentPage8 = displayTemplateOutput.externalContentPage8 ?? null;
    displayTemplate.externalContentPage9 = displayTemplateOutput.externalContentPage9 ?? null;
    displayTemplate.externalContentEmergencyPage = displayTemplateOutput.externalContentEmergencyPage ?? null;

    return displayTemplate;
  }
  /**
   * Clear node child
   * @param myNode
   * @returns
   */
  public static clearNodeChild(myNode: any): void {
    if (!myNode) {
      return;
    }
    while (myNode.firstChild) {
      myNode.removeChild(myNode.firstChild);
    }
  }

  /**
   * convert data folder s3 media
   * @param folderData
   * @returns FolderMedia object
   */
  public static convertDataFolderS3Media(folderData: any): FolderMedia {
    let folder = new FolderMedia();
    folder.id = folderData['id'];
    folder.name = folderData['projectName'];
    folder.folderS3Name = folderData['folderS3Name'];
    folder.parentId = folderData['parentId'];
    return folder;
  }

  /**
   * convert data folder s3 medias
   * @param data
   * @returns FolderMedia object
   */
  public static convertDataFolderS3Medias(data: any[]): Array<FolderMedia> {
    return data.map(folderS3 => this.convertDataFolderS3Media(folderS3));
  }

  /**
   * read text file based on url
   * @param urlFile url file text
   */
  public static readTextFile(urlFile: string): string {
    if (!urlFile || _.isEmpty(urlFile)) {
      return null;
    }
    var rawFile = new XMLHttpRequest();
    var result = null;
    rawFile.open('GET', urlFile, false);
    rawFile.onreadystatechange = () => {
      if (rawFile.readyState === 4) {
        if (rawFile.status === 200) {
          result = rawFile.responseText;
        }
      }
    };
    rawFile.send(null);
    return result;
  }

  /**
   * true if media is object Image
   * @param media
   */
  public static isImage(media: Media): boolean {
    return media instanceof Image;
  }

  /**
   * true if media is object Sound
   * @param media
   */
  public static isSound(media: Media): boolean {
    return media instanceof Sound;
  }

  /**
   * true if media is object Text
   * @param media
   */
  public static isText(media: Media): boolean {
    return media instanceof Text;
  }

  /**
   * true if media is object Sequence
   * @param media
   */
  public static isSequence(media: Media): boolean {
    return media instanceof Sequence;
  }

  /**
   * true if media is object Video
   * @param media
   */
  public static isVideo(media: Media): boolean {
    return media instanceof Video;
  }
  /**
   * convert string to ObjectFitEnum
   * @param objectFitValue
   */
  public static convertStringToObjectFitEnum(objectFitValue: string): ObjectFitEnum {
    let objectFitOutput: ObjectFitEnum;
    if (objectFitValue == 'FILL') {
      objectFitOutput = ObjectFitEnum.FILL;
    } else if (objectFitValue == 'COVER') {
      objectFitOutput = ObjectFitEnum.COVER;
    } else {
      objectFitOutput = ObjectFitEnum.CONTAIN;
    }
    return objectFitOutput;
  }

  /**
   * get horizontal text alignment orientation sideways
   *
   * @param align
   * @returns
   */
  public static getHorizontalTextAlignmentOrientationSideways(align: AlignmentEnum) {
    switch (align) {
      case AlignmentEnum.TOP:
        return 'right';
      case AlignmentEnum.MIDDLE:
        return 'center';
      case AlignmentEnum.BOTTOM:
        return 'left';
      default:
        return 'center';
    }
  }

  /**
   * get vertical text alignment orientation sideways
   *
   * @param align
   * @returns
   */
  public static getVerticalTextAlignmentOrientationSideways(align: AlignmentEnum) {
    switch (align) {
      case AlignmentEnum.RIGHT:
        return 'bottom';
      case AlignmentEnum.CENTER:
        return 'middle';
      case AlignmentEnum.LEFT:
        return 'top';
      default:
        return 'middle';
    }
  }

  /**
   * read file text
   * @param file any
   * @param media Media
   */
  public static readContentTextFile(file: any, media: Media) {
    var rawFile = new XMLHttpRequest();
    rawFile.open('GET', file, false);
    rawFile.onreadystatechange = () => {
      if (rawFile.readyState === 4) {
        if (rawFile.status === 200) {
          var contentResponse = rawFile.responseText;
          media.getMedia().content = contentResponse;
        }
      }
    };
    rawFile.send(null);
  }

  /**
   * check type media by media name
   * @param mediaName media data
   */
  public static checkTypeMediaByMediaName(mediaName: any): string {
    if (!mediaName) {
      return null;
    }
    return mediaName.substring(mediaName.lastIndexOf('.') + 1).toLocaleUpperCase();
  }

  /**
   * convert data device log
   * @param deviceLogData
   * @returns
   */
  public static convertDataDeviceLog(deviceLogData: any, device: Device | DeviceLog, languageKey: any): DeviceLog {
    let deviceLog = new DeviceLog();
    deviceLog.registrationId = device['registrationId'];
    deviceLog.deviceId = device['deviceId'];
    if (deviceLogData) {
      deviceLog.systemTime = this.updateSystemTime(deviceLogData['system_time'], languageKey);
      deviceLog.firmwareVersion = deviceLogData['firmware_version'];
      let shutdown_time = deviceLogData['shutdown_time'];
      if (shutdown_time) deviceLog.shutdownTime = shutdown_time.substring(0, 2) + ':' + shutdown_time.substring(2, 4);
      else deviceLog.shutdownTime = null;
    }
    return deviceLog;
  }

  /**
   * convert data filter media log
   *
   * @param conditionFilterMediaLog
   * @returns
   */
  public static convertDataFilterMediaLog(conditionFilterMediaLog: ConditionFilterMediaLog): any {
    return {
      id: conditionFilterMediaLog?.id,
      userId: conditionFilterMediaLog?.userId,
      condition: conditionFilterMediaLog?.condition,
      period: conditionFilterMediaLog?.period,
      dateFrom: conditionFilterMediaLog?.dateFrom,
      dateTo: conditionFilterMediaLog?.dateTo
    };
  }

  /**
   * convert data filter device log to save
   * @param conditionFilterDeviceLog
   * @returns filter device log to save
   */
  public static convertDataFilterDeviceLogToSave(conditionFilterDeviceLog: ConditionFilterDeviceLog): any {
    let conditions = {};
    conditions['customTagElement1Ids'] = this.getElementCustomTagIds(
      conditionFilterDeviceLog.customTag1,
      conditionFilterDeviceLog,
      'customTagElement1Ids'
    );
    conditions['customTagElement2Ids'] = this.getElementCustomTagIds(
      conditionFilterDeviceLog.customTag2,
      conditionFilterDeviceLog,
      'customTagElement2Ids'
    );
    conditions['customTagElement3Ids'] = this.getElementCustomTagIds(
      conditionFilterDeviceLog.customTag3,
      conditionFilterDeviceLog,
      'customTagElement3Ids'
    );
    conditions['deviceId'] = conditionFilterDeviceLog?.deviceId.trim().length == 0 ? undefined : conditionFilterDeviceLog?.deviceId;
    return {
      id: conditionFilterDeviceLog?.id,
      userId: conditionFilterDeviceLog?.userId,
      conditions: JSON.stringify(conditions),
      customTag01Id: conditionFilterDeviceLog?.customTag1?.id,
      customTag02Id: conditionFilterDeviceLog?.customTag2?.id,
      customTag03Id: conditionFilterDeviceLog?.customTag3?.id
    };
  }

  /**
   * get list id of custom tag element
   * @param customTag
   * @param conditionFilterDeviceLog
   * @param customTagElementIdsKey
   * @returns list id of custom tag element
   */
  public static getElementCustomTagIds(
    customTag: CustomTag,
    conditionFilterDeviceLog: ConditionFilterDeviceLog,
    customTagElementIdsKey: string
  ): number[] {
    if (customTag && !conditionFilterDeviceLog[customTagElementIdsKey]?.length) {
      return customTag.elements?.map(element => element.id);
    } else {
      return conditionFilterDeviceLog[customTagElementIdsKey];
    }
  }

  /**
   * create list object from array string
   * @param items array string
   * @returns list object
   */
  public static createListObject(items: any): any {
    return items
      ? items.map(item => {
          return { name: item };
        })
      : [];
  }

  /**
   * convert data receive status
   *
   * @param dataLog
   * @returns
   */
  public static convertDataReceiveStatus(dataLog: any): Array<DataLog> {
    let dataLogs = new Array<DataLog>();
    dataLogs.push(this.convertDataLog(dataLog[ScreenTypeEnum.BID], ScreenTypeEnum.BID));
    dataLogs.push(this.convertDataLog(dataLog[ScreenTypeEnum.SD], ScreenTypeEnum.SD));
    dataLogs.push(this.convertDataLog(dataLog[ScreenTypeEnum.DSC], ScreenTypeEnum.DSC));
    dataLogs.push(this.convertDataLog(dataLog[ScreenTypeEnum.GID], ScreenTypeEnum.GID));
    dataLogs.push(this.convertDataLog(dataLog[ScreenTypeEnum.EDS], ScreenTypeEnum.EDS));
    return dataLogs.filter(dataLog => dataLog);
  }

  /**
   * convert list data general log
   *
   * @param generalLogs
   * @returns
   */
  public static convertDataGeneralLogs(generalLogs: Array<any>): Array<DeviceTabLog> {
    let deviceLogs = new Array<DeviceTabLog>();
    generalLogs.forEach(log => {
      deviceLogs = deviceLogs.concat(this.convertDataTabLog(log));
    });
    return deviceLogs;
  }

  /**
   * convert data tab log
   *
   * @param dataLog
   * @returns
   */
  public static convertDataTabLog(dataLog: any): Array<DeviceTabLog> {
    let deviceLogs = new Array<DeviceTabLog>();
    if (!dataLog) {
      return deviceLogs;
    }
    dataLog['deviceLog'].forEach(log => {
      let deviceLog = new DeviceTabLog();
      deviceLog.timestamp = log['timestamp'];
      deviceLog.emitter = log['emitter'];
      deviceLog.logLevel = log['logLevel'];
      deviceLog.eventType = log['eventType'];
      deviceLog.message = log['message'];
      deviceLogs.push(deviceLog);
    });
    return deviceLogs;
  }

  /**
   * convert data log
   *
   * @param data
   * @param type
   * @returns
   */
  private static convertDataLog(data: any, type: string): DataLog {
    if (!data) {
      return null;
    }
    let dataLog = new DataLog();
    dataLog.name = this.convertTypeToDisplayScreenName(type);
    dataLog.current = this.convertPublishDataLog(data['current']);
    dataLog.next = this.convertPublishDataLog(data['next']);
    return dataLog;
  }

  private static convertPublishDataLog(publishDataLog: any): PublishData {
    if (!publishDataLog) {
      return null;
    }
    let publishData = new PublishData();
    publishData.name = publishDataLog['name'];
    publishData.validFrom = moment(new Date(publishDataLog['validFrom'])).format('MMM. DD, YYYY');
    return publishData;
  }

  /**
   * convert data condition filter media log
   *
   * @param mediaLogData
   * @returns
   */
  public static convertDataFilterMediaLogToFrontEnd(mediaLogData: any): ConditionFilterMediaLog {
    if (!mediaLogData) {
      return null;
    }
    let result = new ConditionFilterMediaLog();
    result.id = mediaLogData['id'];
    result.registrationIds = mediaLogData['registrationIds'];
    result.playlistNames = mediaLogData['playlistNames'];
    result.condition = this.convertConditionToFrontEnd(mediaLogData['condition']);
    result.dateFrom = mediaLogData['dateFrom'];
    result.dateTo = mediaLogData['dateTo'];
    result.userId = mediaLogData['userId'];
    result.period = mediaLogData['period'];
    return result;
  }

  /**
   * convert condition to frontEnd
   * @param condition
   * @returns
   */
  public static convertConditionToFrontEnd(condition: ConditionMedia): ConditionMedia {
    let conditionMedia = new ConditionMedia();
    if (condition['customTagId']) {
      let customTag = new CustomTag(null, null, null);
      customTag.id = condition['customTagId'];
      conditionMedia.customTag = customTag;
    }
    (conditionMedia.customTagElementId = condition['customTagElementId']),
      (conditionMedia.deviceIds = condition['deviceIds']),
      (conditionMedia.fileName = condition['fileName']),
      (conditionMedia.mediaTypes = condition['mediaTypes']),
      (conditionMedia.playlistIds = condition['playlistIds']);
    return conditionMedia;
  }

  /**
   * convert data media log to backend
   *
   * @param mediaLogData
   * @returns
   */
  public static convertDataMediaLogToBackend(mediaLogData: ConditionFilterMediaLog): any {
    if (!mediaLogData) {
      return null;
    }
    return {
      id: mediaLogData.id,
      registrationIds: mediaLogData.registrationIds,
      playlistNames: mediaLogData.playlistNames,
      condition: this.convertConditionToBackend(mediaLogData.condition),
      dateFrom: mediaLogData.dateFrom,
      dateTo: mediaLogData.dateTo,
      userId: mediaLogData.userId,
      period: mediaLogData.period
    };
  }

  /**
   * convert condition to backend
   * @param condition
   */
  public static convertConditionToBackend(condition: ConditionMedia): any {
    if (!condition) {
      return null;
    }
    return {
      deviceIds: condition.deviceIds,
      mediaTypes: condition.mediaTypes,
      fileName: condition.fileName,
      playlistIds: condition.playlistIds,
      customTagId: !condition.customTag ? undefined : condition.customTag.id,
      customTagElementId: condition.customTagElementId
    };
  }

  /**
   * convert type to display screen name
   *
   * @param type
   * @returns
   */
  private static convertTypeToDisplayScreenName(type: string): string {
    switch (type) {
      case ScreenTypeEnum.BID:
        return ScreenNameEnum.ON_BUS_DISPLAY;
      case ScreenTypeEnum.SD:
        return ScreenNameEnum.STATION_DISPLAY;
      case ScreenTypeEnum.GID:
        return ScreenNameEnum.SIGNAGE_DISPLAY;
      case ScreenTypeEnum.DSC:
        return ScreenNameEnum.DIGITAL_SIGNAGE_CHANNEL_DISPLAY;
      case ScreenTypeEnum.EDS:
        return ScreenNameEnum.DESTINATION_SIGN_DISPLAY;
      default:
        break;
    }
  }

  /**
   * checkDecimal
   * @param inputNumber input Number
   * @return outputNumber
   */
  public static handlingDecimal(inputNumber: number): number {
    let stringInput = inputNumber + '';
    if (Math.ceil(inputNumber) != inputNumber) {
      stringInput = stringInput.replace(/(\.)/g, '');
    }
    return +stringInput;
  }

  /**
   * convert data timetable
   * @param timetableData
   */
  public static convertDataTimetable(timetableData: any): Timetable {
    let timetable = new Timetable();
    timetable.id = timetableData['id'];
    timetable.name = timetableData['name'];
    timetable.no = timetableData['no'];
    timetable.suffix = timetableData['suffix'];
    timetable.timetableSchedule = timetableData['timetableSchedule'];
    if (timetableData['label']) {
      timetable.label = timetableData['label'];
      timetable['nameLabel'] = timetable.label?.name;
    }
    if (timetableData['templateDisplay1s']) {
      timetable.templateDisplay1s = timetableData['templateDisplay1s'].map((display1Data: any) => {
        return this.convertDataTemplateBackward(display1Data, 'Display1');
      });
      timetable['nameTemplateMain1'] = timetable.templateDisplay1s[0]?.name;
    }
    if (timetableData['templateDisplay2s']) {
      timetable.templateDisplay2s = timetableData['templateDisplay2s'].map((display2Data: any) => {
        return this.convertDataTemplateBackward(display2Data, 'Display2');
      });
      timetable['nameTemplateMain2'] = timetable.templateDisplay2s[0]?.name;
    }
    if (timetableData['display1TemplatesJson']) {
      timetable.displayTemplate1 = this.convertDataDisplayTemplate(timetableData['display1TemplatesJson']);
    }
    if (timetableData['display2TemplatesJson']) {
      timetable.displayTemplate2 = this.convertDataDisplayTemplate(timetableData['display2TemplatesJson']);
    }
    return timetable;
  }

  /**
   * convert data timetable import
   * @param timetableData
   * @returns
   */
  public static convertDataTimetableImport(timetableData: any): Timetable {
    let timetable = new Timetable();
    timetable.name = timetableData['name'];
    timetable.no = timetableData['no'];
    timetable.suffix = timetableData['suffix'];

    if (timetableData['display1TemplatesJson'] == '') {
      timetable.displayTemplate1 = undefined;
    } else if (timetableData['display1TemplatesJson']) {
      timetable.displayTemplate1 = this.convertDataDisplayTemplateImport(timetableData['display1TemplatesJson']);
      timetable.timetableSchedule = timetableData['timetableSchedule'];
    } else {
      // template not exit and no data schedule
      timetable.timetableSchedule = new TimetableSchedule();
    }

    return timetable;
  }

  /**
   * convert data display template import
   * @param displayTemplateData
   * @returns
   */
  public static convertDataDisplayTemplateImport(displayTemplateData: string): DisplayTemplate {
    let displayTemplate: DisplayTemplate = new DisplayTemplate();
    const displayTemplateOutput = JSON.parse(displayTemplateData);
    displayTemplate.idMainPage = displayTemplateOutput?.idMainPage ?? null;
    return displayTemplate;
  }

  /**
   * convert data timetables
   * @param timetableDatas list timetables data
   */
  public static convertDataTimetables(timetableDatas: any[]): Array<Timetable> {
    return timetableDatas?.map(timetable => this.convertDataTimetable(timetable));
  }

  /**
   * convert data timetable backward
   * @param timetable
   */
  public static convertDataTimetableBackward(timetable: Timetable): any {
    return {
      id: timetable.id,
      name: timetable.name,
      no: timetable.no,
      suffix: timetable.suffix,
      labelId: timetable.label?.id,
      label: timetable.label,
      display1TemplatesJson: JSON.stringify(timetable.displayTemplate1),
      display2TemplatesJson: JSON.stringify(timetable.displayTemplate2),
      timetableSchedule: timetable.timetableSchedule
    };
  }

  /**
   * convert data timetable backward
   * @param timetable
   */
  public static convertDataOpenBackward(openWeather: OpenWeatherContent): any {
    return {
      id: openWeather.id,
      cities: openWeather.cities,
      contentOutputFileName: openWeather.contentOutputFileName,
      contentOutputFileNameEncode: openWeather.contentOutputFileNameEncode,
      autoUpdate: openWeather.isAutoUpdate,
      template: openWeather.template,
      weatherContentDetails: openWeather.weatherContentDetails
    };
  }

  /**
   * convert data timetable backward
   * @param timetable
   */
  public static convertTimetableBackward(timetable: Timetable): any {
    return {
      id: timetable.id,
      name: timetable.name,
      no: timetable.no,
      suffix: timetable.suffix,
      labelId: timetable.label?.id,
      label: timetable.label,
      display1TemplatesJson: timetable.displayTemplate1?.idMainPage ? JSON.stringify(timetable.displayTemplate1) : null,
      display2TemplatesJson: timetable.displayTemplate2?.idMainPage ? JSON.stringify(timetable.displayTemplate2) : null,
      timetableSchedule: timetable.timetableSchedule
    };
  }

  /**
   * get template's id by template type
   * @param stylesSelected
   * @param displayTemplateType
   * @param templateType
   */
  public static getIdTemplateByType(stylesSelected: Array<any>, displayTemplateType: string, templateType: DestinationEnum): any {
    const FIRST_STYLE_INDEX = 0;
    const displayType = displayTemplateType == Constant.DISPLAY_1 ? 'displayTemplate1' : 'displayTemplate2';
    let idTemplate;
    switch (templateType) {
      case DestinationEnum.MAIN:
        idTemplate = 'idMainPage';
        break;
      case DestinationEnum.SUB_PAGE_1:
        idTemplate = 'idSubPage1';
        break;
      case DestinationEnum.SUB_PAGE_2:
        idTemplate = 'idSubPage2';
        break;
      case DestinationEnum.SUB_PAGE_3:
        idTemplate = 'idSubPage3';
        break;
      case DestinationEnum.SUB_PAGE_4:
        idTemplate = 'idSubPage4';
        break;
      case DestinationEnum.SUB_PAGE_5:
        idTemplate = 'idSubPage5';
        break;
      case DestinationEnum.SUB_PAGE_6:
        idTemplate = 'idSubPage6';
        break;
      case DestinationEnum.SUB_PAGE_7:
        idTemplate = 'idSubPage7';
        break;
      case DestinationEnum.SUB_PAGE_8:
        idTemplate = 'idSubPage8';
        break;
      case DestinationEnum.SUB_PAGE_9:
        idTemplate = 'idSubPage9';
        break;
      case DestinationEnum.EMERGENCY:
        idTemplate = 'idEmergencyPage';
        break;
      default:
        break;
    }
    const displayTemplate = _.get(stylesSelected[FIRST_STYLE_INDEX], `[${displayType}]`, undefined);
    if (!displayTemplate) {
      return undefined;
    }
    let idSame = _.get(displayTemplate, `[${idTemplate}]`);
    if (!idSame) {
      return undefined;
    }
    return stylesSelected.every(styleData => _.get(styleData[displayType], `[${idTemplate}]`) == idSame) ? idSame : undefined;
  }

  /**
   * convert data timetable schedule
   * @param timetableDetailData
   * @param isNoConvertTime
   * @param headersLength
   * @param timeDateLine
   */
  public static convertTimetableSchedule(
    timetableScheduleData: any,
    isNoConvertTime?: boolean,
    timeDateLine?: string,
    headersLength?: number
  ): TimetableSchedule {
    const START_CODE = '<$$>';
    let timetableSchedule = new TimetableSchedule();
    timetableSchedule.id = timetableScheduleData['id'];
    timetableSchedule.scheduleJson = timetableScheduleData['scheduleJson'];
    timetableSchedule.timetableId = timetableScheduleData['timetableId'];
    timetableSchedule.items = timetableScheduleData['items'];
    timetableSchedule.isChangeScheduleHeader = timetableScheduleData['isChangeScheduleHeader'];
    if (timetableScheduleData['itemNames']) {
      timetableSchedule.headers = JSON.parse(timetableScheduleData['itemNames']);
    }
    if (timetableSchedule?.items) {
      timetableSchedule.itemDetails = new Array<ItemDetail>();
      timetableSchedule.items.forEach(item => {
        let itemsSchedule: Array<string> = JSON.parse(item);
        if (headersLength) {
          const numbers = headersLength - itemsSchedule.length;
          if (itemsSchedule.length < headersLength) {
            for (let i = 0; i < numbers; i++) {
              itemsSchedule.push('');
            }
          } else if (itemsSchedule.length > headersLength) {
            itemsSchedule.splice(headersLength, -numbers);
          }
        }
        timetableSchedule?.itemDetails.push(new ItemDetail(START_CODE, itemsSchedule));
      });
    }

    // no column time
    if (timetableSchedule.headers[0] == null) {
      return timetableSchedule;
    }

    const formatRegex = this.formatTimeRegex(Constant.FORMAT_TIME_COMMON_REGEX, timeDateLine);
    // check time valid
    timetableSchedule.itemDetails?.forEach(detail => {
      const time = detail.items[0];
      let match = new RegExp(formatRegex).exec(time);
      if (match == null) {
        detail.isInValidFormat = true;
      }
    });

    // no convert time to using validate tab calendar
    if (isNoConvertTime) {
      return timetableSchedule;
    }

    // convert time over 24h
    timetableSchedule.itemDetails?.forEach(detail => {
      if (detail.isInValidFormat) {
        return;
      }
      if (detail.items[0].split(':').length == Constant.NUMBER_ELEMENTS_OF_MINUTE_FORMAT) {
        detail.items[0] = moment
          .utc(moment.duration(detail.items[0], Constant.SECONDS_STRING).asMilliseconds())
          .format(Constant.FORMAT_TIME_TO_MINUTES);
      } else {
        detail.items[0] = moment
          .utc(moment.duration(detail.items[0], Constant.SECONDS_STRING).asMilliseconds())
          .format(Constant.FORMAT_TIME_TO_SECONDS);
      }
    });

    return timetableSchedule;
  }

  /**
   * convert time date line
   * @param timeDateLine
   * @returns
   */
  private static convertTimeDateLine(timeDateLine: string): string {
    let seconds = this.convertTimeDateLineToSeconds(timeDateLine);
    if (seconds == 0) {
      return '23:59';
    }
    seconds -= 1;
    let timeFormat = moment.utc(moment.duration(seconds, Constant.SECONDS_STRING).asMilliseconds()).format(Constant.FORMAT_TIME_TO_MINUTES);
    const time = timeFormat.split(':');
    return `${+time[0] + 24}:${time[1]}`;
  }

  /**
   * format time regex
   * @param formatTimeRegex
   * @param timeDateLine
   * @returns
   */
  public static formatTimeRegex(formatTimeRegex: string, timeDateLine: string): string {
    const time = this.convertTimeDateLine(timeDateLine);
    const hour1 = +time[0];
    const hour2 = +time[1];
    const minute1 = +time[3];
    const minute2 = +time[4];

    return Helper.formatString(
      formatTimeRegex,
      `${hour1 - 1}`,
      `${hour1}`,
      `${hour2 == 0 ? hour2 : hour2 - 1}`,
      `${hour1}${hour2}`,
      `${minute1 == 0 ? minute1 : minute1 - 1}`,
      `${minute1}`,
      `${minute2}`
    );
  }

  /**
   * convert seconds to format time
   * @param seconds
   */
  public static convertSecondsToString(seconds: number): string {
    return moment.utc(moment.duration(seconds, Constant.SECONDS_STRING).asMilliseconds()).format(Constant.FORMAT_TIME_TO_MINUTES);
  }

  /**
   * convert data device to device calendar
   * @param deviceDatas
   */
  public static convertDevicesToDeviceCalendars(deviceDatas: any[], commonObject?: Common): Array<DeviceCalendar> {
    return deviceDatas?.map(deviceData => this.convertDeviceToDeviceCalendar(deviceData, commonObject));
  }

  /**
   * convert device to device calendar
   * @param deviceData
   * @returns
   */
  private static convertDeviceToDeviceCalendar(deviceData: any, commonObject: Common): DeviceCalendar {
    let deviceCalendar = new DeviceCalendar();
    deviceCalendar.id = deviceData['id'];
    deviceCalendar.name = deviceData['deviceId'];
    deviceCalendar.registrationId = deviceData['registrationId'];
    deviceCalendar.startDate = this.getCurrentByTimezoneSetting(commonObject, false);
    deviceCalendar.timeDateLine = deviceData['timeDateLine'];
    return deviceCalendar;
  }

  /**
   * convert list data timetable content day backward
   *
   * @param timetableContentDayDatas
   * @param setting
   * @returns
   */
  public static convertDataTimetableContentDayBackward(timetableContentDayDatas: any, setting: UserSetting): any {
    return timetableContentDayDatas
      ?.filter(data => data?.timetable || data?.timetableId)
      ?.map(contentDay => {
        return this.convertTimetableContentDayBackward(contentDay, setting);
      });
  }

  /**
   * convert a timetable content day
   *
   * @param contentDay
   * @returns
   */
  private static convertTimetableContentDayBackward(contentDay: ContentDay, setting: UserSetting): any {
    let GMTTimeZone = this.getUserTimeZone(setting);
    return {
      id: contentDay?.id,
      timetableId: contentDay.timetable?.id ?? -1,
      fullDate: moment(contentDay.fullDate).format('YYYY-MM-DD') + 'T00:00:00' + GMTTimeZone,
      color: contentDay.color ?? '',
      isDelivered: contentDay.isDelivered ?? false,
      unlimitedInfo: contentDay.unlimitedInfo ? JSON.stringify(contentDay.unlimitedInfo) : null
    };
  }

  /**
   * Convert timetable data backward when export timetable
   * @param timetable
   * @returns
   */
  public static convertTimetableDataBackWardWhenExport(timetable: Timetable): any {
    // delete layers and templateDisplay2s
    timetable?.templateDisplay1s?.forEach(template => {
      delete template?.layers;
    });
    return {
      id: timetable.id,
      name: timetable.name,
      no: timetable.no,
      suffix: timetable.suffix,
      templateDisplay1s: timetable.templateDisplay1s
        ? timetable.templateDisplay1s[TemplateTypeEnum.MAIN]
          ? timetable.templateDisplay1s
          : undefined
        : undefined,
      display1TemplatesJson: JSON.stringify(timetable.displayTemplate1)
    };
  }

  /**
   * convert list data timetable content day backward
   * @param timetableContentDayDatas
   * @returns
   */
  public static convertDataSimpleContentDayBackward(simpleContentDayDatas: any, setting: UserSetting): any {
    return simpleContentDayDatas?.map(contentDay => {
      return this.convertSimpleContentDayBackward(contentDay, setting);
    });
  }

  /**
   * convert a simple content day
   *
   * @param contentDay
   * @param setting
   * @returns
   */
  private static convertSimpleContentDayBackward(contentDay: ContentDay, setting: UserSetting): any {
    let GMTTimeZone = this.getUserTimeZone(setting);
    return {
      id: contentDay?.id,
      playlistId: contentDay.playlistSimple?.id ?? -1,
      fullDate: moment(contentDay.fullDate).format('YYYY-MM-DD') + 'T00:00:00' + GMTTimeZone,
      color: contentDay.color ?? '',
      isDelivered: contentDay.isDelivered ?? false,
      unlimitedInfo: contentDay.unlimitedInfo ? JSON.stringify(contentDay.unlimitedInfo) : null
    };
  }

  /**
   * convert data list timetable for calendar
   *
   * @param timetableDatas
   * @param timeDateLine
   * @returns
   */
  public static convertDataTimetablesForCalendar(timetableDatas: any[], timeDateLine: string): Array<Timetable> {
    return timetableDatas?.map(data => this.convertDataTimetableForCalendar(data, timeDateLine));
  }

  /**
   * convert data a timetable for calendar
   *
   * @param timetableData
   * @param timeDateLine
   * @returns
   */
  private static convertDataTimetableForCalendar(timetableData: any, timeDateLine: string): Timetable {
    let timetable = new Timetable();
    timetable.id = timetableData['id'];
    timetable.name = timetableData['name'];
    timetable.no = timetableData['no'];
    timetable.suffix = timetableData['suffix'];
    if (timetableData['display1TemplatesJson']) {
      timetable.displayTemplate1 = this.convertDataDisplayTemplate(timetableData['display1TemplatesJson']);
    }
    if (timetableData['display2TemplatesJson']) {
      timetable.displayTemplate2 = this.convertDataDisplayTemplate(timetableData['display2TemplatesJson']);
    }
    if (timetableData['templateDisplay1s']) {
      timetable.templateDisplay1s = timetableData['templateDisplay1s'].map((display1Data: any) => {
        return this.convertDataTemplateBackward(display1Data);
      });
      timetable['nameTemplateMain1'] = timetable.templateDisplay1s[0]?.name;
    }
    if (timetableData['timetableSchedule']) {
      timetable.timetableSchedule = this.convertTimetableSchedule(timetableData['timetableSchedule'], true, timeDateLine);
    }
    return timetable;
  }

  /**
   * convert time timetable schedule
   *
   * @param time
   * @returns convertedTime
   */
  public static convertTimeTimetableSchedule(time: string, isDrawPreview?: boolean): string {
    const num = time.split(':');
    if (num.length <= 1) {
      return time.trim();
    }
    let hours = +num[Constant.HOURS] % 24;
    let minutes = num[Constant.MINUTES];
    if (!isDrawPreview) {
      if (num.length === Constant.NUMBER_ELEMENTS_OF_SECOND_FORMAT) {
        return `${(hours + '').padStart(2, '0')}:${(minutes + '').padStart(2, '0')}:${(num[Constant.SECONDS] + '').padStart(2, '0')}`;
      }
      return `${(hours + '').padStart(2, '0')}:${(minutes + '').padStart(2, '0')}`;
    } else {
      return `${hours + ''}:${(minutes + '').padStart(2, '0')}`;
    }
  }

  /**
   * check option need to unique is unique in template
   *
   * @param linkAreasText
   * @param optionsUniqueLinkText
   * @param linkAreasPicture
   * @param optionsUniqueLinkPicture
   * @returns
   */
  public static validateUniqueOptionOfTemplate(
    linkAreasText: Area[],
    optionsUniqueLinkText: any[],
    linkAreasPicture: Area[],
    optionsUniqueLinkPicture: any[]
  ): boolean {
    if (!linkAreasText.length && !linkAreasPicture.length) {
      return true;
    }
    // check unique text option
    for (const option of optionsUniqueLinkText) {
      if (linkAreasText.filter(area => area.getArea().linkReferenceData == option).length > 1) {
        return false;
      }
    }
    // check unique picture option
    for (const option of optionsUniqueLinkPicture) {
      if (linkAreasPicture.filter(area => area.getArea().attribute == option).length > 1) {
        return false;
      }
    }
    return true;
  }

  /**
   * convert times to seconds
   *
   * @param times
   * @returns
   */
  public static convertTimesToSeconds(times: string[]): number[] {
    return times?.map(data => {
      const time = data.split(':');
      let seconds = +time[0] * 3600 + +time[1] * 60;
      return !time[2] ? seconds : seconds + +time[2];
    });
  }

  /**
   * convert time date line to seconds
   * @param timeDateLine
   * @returns
   */
  public static convertTimeDateLineToSeconds(timeDateLine: string): number {
    const arrTimes = timeDateLine.split(':');
    return +arrTimes[0] * 3600 + +arrTimes[1] * 60;
  }

  /**
   * convert list data simple playlist
   *
   * @param playlistDatas
   * @returns
   */
  public static convertDataSimplePlaylists(playlistDatas: any[]): Array<SimplePlaylist> {
    return playlistDatas?.map(data => this.convertDataSimplePlaylist(data));
  }

  /**
   * convert data simple playlist
   * @param playlistData
   * @returns
   */
  public static convertDataSimplePlaylist(playlistData: any): SimplePlaylist {
    let simplePlaylist = new SimplePlaylist();
    simplePlaylist.id = playlistData['id'];
    simplePlaylist.name = playlistData['name'];
    simplePlaylist.sequence = playlistData['sequence'];
    return simplePlaylist;
  }

  /**
   * convert list data simple playlist
   *
   * @param playlistDatas
   * @returns
   *
   */
  public static convertDataSimplePlaylistsCalendar(playlistDatas: any[]): Array<SimplePlaylist> {
    return playlistDatas?.map(data => this.convertDataSimplePlaylistCalendar(data)).filter(simplePlaylist => simplePlaylist.id);
  }

  /**
   * convert data simple playlist
   * @param playlistData
   * @returns
   */
  public static convertDataSimplePlaylistCalendar(playlistData: any): SimplePlaylist {
    let simplePlaylist = new SimplePlaylist();
    if (playlistData['sequence']) {
      simplePlaylist.id = playlistData['id'];
      simplePlaylist.name = playlistData['name'];
      simplePlaylist.sequence = playlistData['sequence'];
    }
    return simplePlaylist;
  }

  /**
   * convert data list media of sequence
   * @param mediaOfSequenceData
   * @returns
   */
  public static convertDataListMediaOfSequence(mediaOfSequenceDatas: any): Array<MediaOfSequence> {
    return mediaOfSequenceDatas?.map(data => this.convertDataMediaOfSequence(data));
  }

  /**
   * convert data media of sequence
   * @param mediaOfSequenceData
   * @returns
   */
  private static convertDataMediaOfSequence(mediaOfSequenceData: any): MediaOfSequence {
    let mediaOfSequence = new MediaOfSequence();
    mediaOfSequence.idMedia = mediaOfSequenceData['idMedia'];
    mediaOfSequence.startTime = mediaOfSequenceData['startTime'];
    mediaOfSequence.url = mediaOfSequenceData['url'];
    mediaOfSequence.name = this.convertDataToDisplayGUI(mediaOfSequenceData['name'], mediaOfSequenceData['mediaNameEncode']);
    mediaOfSequence.width = mediaOfSequenceData['width'];
    mediaOfSequence.height = mediaOfSequenceData['height'];
    mediaOfSequence.duration = mediaOfSequenceData['duration'];
    mediaOfSequence.validFrom = mediaOfSequenceData['validFrom'];
    mediaOfSequence.validTo = mediaOfSequenceData['validTo'];
    mediaOfSequence.type = mediaOfSequenceData['type'];
    mediaOfSequence.size = mediaOfSequenceData['size'];
    mediaOfSequence.randomNumber = Math.random();
    mediaOfSequence.folderS3Name = mediaOfSequenceData['folderS3Name'];
    mediaOfSequence.mediaNameEncode = mediaOfSequenceData['mediaNameEncode'];
    mediaOfSequence.playTimeFrom = mediaOfSequenceData['playTimeFrom'] == '24:00' ? '00:00' : mediaOfSequenceData['playTimeFrom'];
    mediaOfSequence.playTimeTo = mediaOfSequenceData['playTimeTo'] == '24:00' ? '00:00' : mediaOfSequenceData['playTimeTo'];
    this.convertDurationMediaOfSequence(mediaOfSequence);
    return mediaOfSequence;
  }

  /**
   * convert duration of media
   * @param mediaOfSequence
   */
  private static convertDurationMediaOfSequence(mediaOfSequence: MediaOfSequence) {
    if (mediaOfSequence.type == TypeMediaFileEnum.MP4) {
      return;
    }
    mediaOfSequence.hour = `${Math.floor(mediaOfSequence.duration / 3600)}`.padStart(2, '0');
    mediaOfSequence.minute = `${Math.floor((mediaOfSequence.duration % 3600) / 60)}`.padStart(2, '0');
    mediaOfSequence.second = `${(mediaOfSequence.duration % 3600) % 60}`.padStart(2, '0');
  }

  /**
   * convert data list simple media
   * @param mediaDatas
   * @returns
   */
  public static convertDataSimpleMedias(mediaDatas: any, isDisplayInGUI: boolean): Array<SimpleMedia> {
    if (!mediaDatas) {
      return [];
    }
    return mediaDatas.map(data => this.convertDataSimpleMedia(data, isDisplayInGUI));
  }

  /**
   * convert data simple media
   * @param simpleMediaData
   * @returns
   */
  public static convertDataSimpleMedia(simpleMediaData: any, isDisplayInGUI: boolean): SimpleMedia {
    let simpleMedia = new SimpleMedia();
    simpleMedia.id = simpleMediaData['id'];
    simpleMedia.name = isDisplayInGUI
      ? this.convertDataToDisplayGUI(simpleMediaData['name'], simpleMediaData['mediaNameEncode'])
      : simpleMediaData['name'];
    simpleMedia.url = simpleMediaData['url'];
    simpleMedia.type = simpleMediaData['type'];
    simpleMedia.duration = simpleMediaData['duration'];
    simpleMedia.width = simpleMediaData['width'];
    simpleMedia.height = simpleMediaData['height'];
    simpleMedia.folderId = simpleMediaData['folderId'];
    simpleMedia.size = simpleMediaData['size'];
    simpleMedia.randomNumber = Math.random();
    simpleMedia.mediaNameEncode = simpleMediaData['mediaNameEncode'];
    simpleMedia.frameRate = simpleMediaData['mediaFrameRate'];
    simpleMedia.bitRate = simpleMediaData['mediaBitRate'];
    return simpleMedia;
  }

  /**
   * convert data list simple folder media
   * @param folderDatas
   * @returns
   */
  public static convertDataSimpleFolderMedias(folderDatas: any): Array<Folder> {
    return folderDatas?.map(data => this.convertDataSimpleFolderMedia(data));
  }

  /**
   * convert data simple folder media
   * @param folderData
   * @returns
   */
  private static convertDataSimpleFolderMedia(folderData: any): Folder {
    let simpleFolder = new Folder();
    simpleFolder.id = folderData['id'];
    simpleFolder.name = folderData['name'];
    simpleFolder.folderS3Name = folderData['folderS3Name'];
    return simpleFolder;
  }

  /**
   * convert simple media to media of sequence
   *
   * @param simpleMedia
   */
  public static convertSimpleMediaToMediaOfSequence(simpleMedia: SimpleMedia, mediaOriginalVideo?: any): MediaOfSequence {
    let mediaOfSequence = new MediaOfSequence();
    mediaOfSequence.idMedia = simpleMedia.id;
    mediaOfSequence.name = simpleMedia.name;
    if (simpleMedia.id != null) {
      mediaOfSequence.duration = simpleMedia.type == TypeMediaFileEnum.MP4 ? this.convertDuration(simpleMedia.duration) : 15;
    } else {
      if (mediaOriginalVideo?.includes(simpleMedia.name)) {
        mediaOfSequence.duration = 1;
      } else {
        mediaOfSequence.duration = 15;
      }
    }
    mediaOfSequence.type = simpleMedia.type;
    mediaOfSequence.url = simpleMedia.url;
    mediaOfSequence.width = simpleMedia.width;
    mediaOfSequence.height = simpleMedia.height;
    mediaOfSequence.size = simpleMedia.size;
    mediaOfSequence.randomNumber = Math.random();
    mediaOfSequence.mediaNameEncode = simpleMedia.mediaNameEncode;
    this.convertDurationMediaOfSequence(mediaOfSequence);
    return mediaOfSequence;
  }

  /**
   * convert media manager to simple media
   *
   * @param media
   */
  public static convertMediaToSimpleMedia(media: any): SimpleMedia {
    const FOLDER_MEDIA = 'media/';
    let simpleMedia = new SimpleMedia();
    simpleMedia.name = media.name;
    simpleMedia.url = media.url;
    simpleMedia.type = media.type;
    if (this.isVideo(media)) {
      simpleMedia.duration = media.duration;
    }
    simpleMedia.width = media.width;
    simpleMedia.height = media.height;
    simpleMedia.url = media.url.substring(media.url.indexOf(FOLDER_MEDIA) + FOLDER_MEDIA.length, media.url.lastIndexOf('?'));
    simpleMedia.randomNumber = Math.random();
    simpleMedia.mediaNameEncode = media.mediaNameEncode;
    return simpleMedia;
  }

  /**
   * convert ss to hh:mm:ss
   * @param time string time
   */
  public static convertDuration(time: string): number {
    if (!time) {
      return 0;
    }
    const timeArray = time.split(':');
    return (
      +timeArray[Constant.HOURS] * Constant.SECOND_PER_HOUR +
      +timeArray[Constant.MINUTES] * Constant.SECOND_PER_MINUTE +
      +timeArray[Constant.SECONDS]
    );
  }

  /**
   * format time to hh:mm:ss:SS
   * @param duration time
   */
  public static formatDuration(duration: any): string {
    if (!duration && isNaN(duration)) {
      return '';
    }
    return new Date(duration * 1000).toISOString().substr(11, 8);
  }

  /**
   * format time to hh:mm:ss:SS
   * @param duration time
   */
  public static formatDurationSimpleSignage(duration: any): string {
    if (!duration && isNaN(duration)) {
      return '';
    }
    return this.secondsToHHmmss(duration);
  }

  /**
   * convert data to check diff data
   * @param mediaOfSequences
   * @returns
   */
  public static convertDataToCheckDiffData(mediaOfSequences: Array<MediaOfSequence>): any {
    return mediaOfSequences?.map(media => {
      return {
        key: media.randomNumber,
        duration: media.duration,
        startTime: media.startTime,
        validFrom: media.validFrom,
        validTo: media.validTo,
        playTimeFrom: media.playTimeFrom,
        playTimeTo: media.playTimeTo
      };
    });
  }

  /**
   * cover media
   * @param canvas
   * @param media
   * @param objectFit
   */
  public static coverMedia(canvas, media: any, objectFit: ObjectFitEnum): any {
    let position = { sX: 0, sY: 0, sWidth: 0, sHeight: 0, x: 0, y: 0, width: 0, height: 0 };
    if (objectFit == ObjectFitEnum.FILL) {
      position.x = 0;
      position.y = 0;
      position.width = canvas.width;
      position.height = canvas.height;
    } else {
      if (media) {
        let resizeRatio =
          objectFit == ObjectFitEnum.CONTAIN
            ? Math.min(canvas.width / media.width, canvas.height / media.height)
            : Math.max(canvas.width / media.width, canvas.height / media.height);
        let resizeWidth = media.width * resizeRatio;
        let resizeHeight = media.height * resizeRatio;
        position.sWidth = media.width / (resizeWidth / canvas.width);
        position.sHeight = media.height / (resizeHeight / canvas.height);
        position.sX = (media.width - position.sWidth) * 0.5;
        position.sY = (media.height - position.sHeight) * 0.5;
        position.x = 0;
        position.y = 0;
        position.width = canvas.width;
        position.height = canvas.height;
      }
    }
    return position;
  }

  /**
   * check file drop to Station Display => true if file is image
   * @param file
   */
  public static isImageFileStationDisplay(file: any): boolean {
    const TYPE_ATTRIBUTE = 'type';
    return (
      file[TYPE_ATTRIBUTE].toLowerCase() == TypeMediaFileEnum.JPG ||
      file[TYPE_ATTRIBUTE].toLowerCase() == TypeMediaFileEnum.PNG ||
      file[TYPE_ATTRIBUTE].toLowerCase() == TypeMediaFileEnum.BMP ||
      file[TYPE_ATTRIBUTE].toLowerCase() == TypeMediaFileEnum.GIF
    );
  }

  /**
   * check file drop to Timetable Editor, Timetable Operation Manager => true if file is image
   * @param file
   */
  public static isImageFile(file: any): boolean {
    const TYPE_ATTRIBUTE = 'type';
    return (
      file[TYPE_ATTRIBUTE].toLowerCase() == TypeMediaFileEnum.JPG ||
      file[TYPE_ATTRIBUTE].toLowerCase() == TypeMediaFileEnum.PNG ||
      file[TYPE_ATTRIBUTE].toLowerCase() == TypeMediaFileEnum.BMP ||
      file[TYPE_ATTRIBUTE].toLowerCase() == TypeMediaFileEnum.PDF
    );
  }

  /**
   * convert string to format
   * @param template
   * @param args
   * @returns new string with new format
   */
  public static formatString(template: string, ...args: string[]) {
    return template.replace(/{(\d+)}/g, (match, index) => {
      return args[index] || '';
    });
  }

  /**
   * convert message error to format
   * @param template
   * @param args
   * @returns new string with new format
   */
  public static formatStringError(template: string, ...args: string[]) {
    return template.replace(/{(\d+)}/g, (match, index) => {
      return `\t• ${args[index] || ''}`;
    });
  }

  /**
   * convert secondes to format HH:mm:ss
   * @param duration seconds number
   * @returns string HH:mm:ss
   */
  private static secondsToHHmmss(duration): string {
    const seconds = Math.floor(duration % 60);
    const minutes = Math.floor((duration / 60) % 60);
    const hours = Math.floor(duration / 3600);
    return [hours.toString().padStart(2, '0'), minutes.toString().padStart(2, '0'), seconds.toString().padStart(2, '0')].join(':');
  }

  /**
   * Convert media name to display in GUI
   *
   * @param mediaName
   * @param mediaNameEncode
   * @returns
   */
  private static convertDataToDisplayGUI(mediaName: string, mediaNameEncode: string): string {
    if (!mediaNameEncode) {
      return mediaName;
    }
    let index = mediaName.lastIndexOf('_');
    if (index != -1) {
      return mediaName.substring(0, index);
    }
    return mediaName;
  }

  /**
   * Convert media name to display in GUI not contain date
   *
   * @param mediaName
   * @param mediaNameEncode
   * @returns
   */
  private static convertDataToDisplayGUINotContainDate(mediaName: string, mediaNameEncode: string): string {
    if (!mediaNameEncode) {
      return mediaName;
    }

    const regex = Constant.REGEX_MEDIA_NAME_CONTAIN_DATE;
    const matches = mediaName.match(regex);
    if (matches) {
      const lastIndex = mediaName.lastIndexOf(matches[0]);
      if (lastIndex !== -1) {
        return mediaName.substring(0, lastIndex);
      }
    }
    return mediaName;
  }

  /**
   * Convert media name back ward
   *
   * @param mediaName
   * @param mediaNameEncode
   * @returns
   */
  public static convertMediaNameBackWard(mediaName: string, mediaNameEncode: string): string {
    if (!mediaNameEncode) {
      return mediaName;
    }
    let index = mediaNameEncode.lastIndexOf('_');
    if (index != -1) {
      return `${mediaName}_${mediaNameEncode.substring(index + 1)}`;
    }
    return mediaName;
  }

  /**
   * Convert data weather contents
   *
   * @param weatherContentDatas
   * @returns
   */
  public static convertDataWeatherContents(weatherContentDatas: any[]): Array<WeatherContent> {
    return weatherContentDatas.map(weatherContentData => this.convertDataWeatherContentFromServer(weatherContentData));
  }

  /**
   * Convert data weather contents
   *
   * @param newsContentDatas
   * @returns
   */
  public static convertDataNewsContents(newsContentDatas: any[]): Array<NewsContent> {
    return newsContentDatas.map(newsContentData => this.convertDataNewsContentFromServer(newsContentData));
  }

  /**
   * Convert data open weather contents
   *
   * @param weatherContentDatas
   * @returns
   */
  public static convertDataOpenWeatherContents(openWeatherContentDatas: any[]): Array<OpenWeatherContent> {
    return openWeatherContentDatas.map(openWeatherContentData => this.convertDataOpenWeatherContentFromServer(openWeatherContentData));
  }

  /**
   * Convert data external contents
   *
   * @param externalContentDatas
   * @returns
   */
  public static convertDataExternalContents(externalContentDatas: any[]): Array<ExternalContent> {
    return externalContentDatas.map(externalContent => this.convertDataExternalContent(externalContent));
  }

  /**
   * Convert data external content
   *
   * @param externalContentData
   * @returns
   */
  public static convertDataExternalContent(externalContentData: any): ExternalContent {
    let externalContent = new ExternalContent();
    externalContent.contentOutputFileName = this.convertDataToDisplayGUI(
      externalContentData['contentOutputFileName'],
      externalContentData['contentOutputFileNameEncode']
    );
    externalContent.id = externalContentData['id'];
    externalContent.contentOutputFileNameEncode = externalContentData['contentOutputFileNameEncode'];
    externalContent['templateId'] = externalContentData['templateId'];
    externalContent['height'] = externalContentData['height'];
    externalContent['width'] = externalContentData['width'];
    return externalContent;
  }

  /**
   * Convert media to media name original
   *
   * @param mediaName
   * @param mediaNameEncode
   * @returns
   */
  public static convertMediaToMediaNameOriginal(mediaName: string, mediaNameEncode: string) {
    let index = mediaNameEncode.lastIndexOf('_');
    if (index != -1) {
      return `${mediaName}${mediaNameEncode.substring(index)}`;
    }
    return mediaName;
  }

  /**
   * Convert hours to seconds
   *
   * @param time
   * @returns
   */
  public static convertHoursToSeconds(time: string): number {
    let timeSplit = time.split(':');
    return time ? +timeSplit[0] * 3600 + +timeSplit[1] * 60 + +timeSplit[2] : 0;
  }

  /**
   * get ReferencePositionColumns by templates
   * @param template
   * @returns list reference position columns
   */
  public static getReferencePositionColumnsByTemplates(templates: Template[]): Array<number> {
    if (!templates) {
      return [];
    }
    let referencePositionColumnSet = new Set<number>([0]);
    templates.forEach(template => {
      if (!template) {
        return;
      }
      let listAreaTimetableAndIndexWord = Helper.getAllAreaReferenceTimetableAndIndexWord(template);
      if (listAreaTimetableAndIndexWord.some(item => item.length)) {
        let referencePositionColumns = listAreaTimetableAndIndexWord[this.TIMETABLE_INDEX]
          .map(area => area.referencePositionColumn)
          .concat(listAreaTimetableAndIndexWord[this.INDEX_WORD_INDEX].map(areaIndexWord => areaIndexWord.referencePositionColumn + 1));
        referencePositionColumnSet = this.mergeSet(referencePositionColumnSet, _.uniq(referencePositionColumns));
      }
    });
    return [...referencePositionColumnSet].sort((value1, value2) => value1 - value2);
  }

  /**
   * get ReferencePositionColumns by templates
   * @param template
   * @returns list reference position columns
   */
  public static getReferencePositionColumnsByTemplate(template: Template): Array<number> {
    let referencePositionColumnSet = new Set<number>([0]);
    if (!template) {
      return;
    }
    let listAreaTimetableAndIndexWord = Helper.getAllAreaReferenceTimetableAndIndexWord(template);
    if (listAreaTimetableAndIndexWord.some(item => item.length)) {
      let referencePositionColumns = listAreaTimetableAndIndexWord[this.TIMETABLE_INDEX]
        .map(area => area.referencePositionColumn)
        .concat(listAreaTimetableAndIndexWord[this.INDEX_WORD_INDEX].map(areaIndexWord => areaIndexWord.referencePositionColumn + 1));
      referencePositionColumnSet = this.mergeSet(referencePositionColumnSet, _.uniq(referencePositionColumns));
    }
    return [...referencePositionColumnSet].sort((value1, value2) => value1 - value2);
  }

  /**
   * get area timetable and area index word
   * @param template
   * @returns
   */
  public static getAllAreaReferenceTimetableAndIndexWord(template: Template): any[] {
    let areasTimetable: Array<TextArea> = new Array<TextArea>();
    let areasIndexWord: Array<Area> = new Array<Area>();
    if (template?.layers) {
      template.layers.forEach(layer => {
        layer.areas.forEach(area => {
          if (!area.isFix) {
            if (area.checkTypeTextArea() && (area as TextArea).linkReferenceData === LinkDataTextEnum.TIMETABLE) {
              areasTimetable.push(area as TextArea);
            } else if (
              (area as TextArea).linkReferenceData === LinkDataTextEnum.INDEX_WORD ||
              (area as PictureArea).attribute === LinkDataPictureEnum.INDEX_WORD
            ) {
              areasIndexWord.push(area);
            }
          }
        });
      });
    }
    return [areasTimetable, areasIndexWord];
  }

  /**
   * get area reference external content
   * @param template
   * @returns
   */
  public static getAllAreaReferenceExternalContent(template: Template): PictureArea[] {
    let areasExternalContent: Array<PictureArea> = new Array<PictureArea>();
    if (template?.layers) {
      template.layers.forEach(layer => {
        layer.areas.forEach(area => {
          if (!area.isFix && !area.checkTypeTextArea() && (area as PictureArea).attribute === LinkDataPictureEnum.EXTERNAL_CONTENT) {
            areasExternalContent.push(area as PictureArea);
          }
        });
      });
    }
    return areasExternalContent;
  }

  /**
   * Save main state action
   * @param store
   * @param commonObject
   */
  public static saveMainStateAction(store: Store<AppState>, commonObject: Common): void {
    store.dispatch(
      new SaveMainStateAction({
        common: commonObject
      })
    );
  }

  /**
   * Encode HTML
   *
   * @param text
   * @returns
   */
  public static encodeHTML(text: string): string {
    if (text == Constant.EMPTY || !text) {
      return Constant.EMPTY;
    }
    return text.replace(Constant.CHARS_TO_ENCODE, char => Constant.ENCODE_TO[char]);
  }

  /**
   * reEncodeHTML
   *
   * @param text
   * @returns
   */
  public static reEncodeHTML(text: string): string {
    if (text == Constant.EMPTY || !text || typeof text == 'boolean') {
      return Constant.EMPTY;
    }
    return text.replace(new RegExp(Constant.CHARS_TO_RE_ENCODE, 'g'), char => Constant.RE_ENCODE_TO[char]);
  }

  /**
   * Encode HTML Announcement Manager
   *
   * @param text
   * @returns
   */
  public static encodeHTMLAnnouncementManager(text: string): string {
    if (text == Constant.EMPTY || !text) {
      return Constant.EMPTY;
    }
    return text.replace(Constant.CHARS_TO_ENCODE_ANNOUNCEMENT_MANAGER, char => Constant.ENCODE_TO_ANNOUNCEMENT_MANAGER[char]);
  }

  /**
   * reEncode HTML Announcement Manager
   *
   * @param text
   * @returns
   */
  public static reEncodeHTMLAnnouncementManager(text: string): string {
    if (text == Constant.EMPTY || !text) {
      return Constant.EMPTY;
    }
    return text.replace(
      new RegExp(Constant.CHARS_TO_RE_ENCODE_ANNOUNCEMENT_MANAGER, 'g'),
      char => Constant.RE_ENCODE_TO_ANNOUNCEMENT_MANAGER[char]
    );
  }

  /**
   * Decode HTML
   *
   * @param text
   * @returns
   */
  public static decodeHTML(text: string) {
    if (text == Constant.EMPTY || !text) {
      return Constant.EMPTY;
    }
    let divText = `<div style="white-space: pre;">${text}</div>`;
    let document = new DOMParser().parseFromString(divText, 'text/html');
    return document.documentElement.textContent;
  }

  /**
   * convert data schedule display index
   *
   * @param scheduleDisplayIndexData
   * @returns
   */
  public static convertDataScheduleDisplayIndexBackward(scheduleDisplayIndexData: ScheduleDisplayIndex): any {
    return {
      id: scheduleDisplayIndexData.id,
      timetableContentDayId: scheduleDisplayIndexData.timetableContentDayId,
      displayIndex: JSON.stringify(scheduleDisplayIndexData.displayIndex)
    };
  }

  /**
   * Get image information
   *
   * @param media
   * @returns
   */
  public static getImageInformation(media: any): Promise<any> {
    return new Promise((resolve, reject) => {
      const img = document.createElement('img');
      img.onload = () => {
        resolve({ width: img.width, height: img.height });
      };
      img.onerror = error => {
        reject(error);
      };
      img.src = media.url;
    });
  }

  /**
   * Convert date to format date save
   *
   * @param date
   * @param setting
   * @returns
   */
  public static convertDateToFormatDateSave(date: Date, setting: any): string {
    let GMTTimeZone = this.getUserTimeZone(setting);
    return moment(date).format('YYYY-MM-DD') + 'T00:00:00' + GMTTimeZone;
  }

  /**
   * show message error network
   * @param dialogService
   * @param translateService
   */
  public static showMessageErrorNetWork(dialogService: DialogService, translateService: TranslateService): void {
    dialogService.showDialog(DialogMessageComponent, {
      data: {
        title: translateService.instant('dialog-error.title'),
        text: translateService.instant('dialog-error.error-network')
      }
    });
  }

  /**
   * convert list device content day
   * @param deviceContentDays
   */
  public static convertDeviceContentDays(deviceContentDays: Array<DeviceContentDay>): Array<DeviceContentDay> {
    return deviceContentDays.map(deviceContentDay => {
      return this.convertDeviceContentDay(deviceContentDay);
    });
  }

  /**
   * convert device content day
   * @param deviceContentDay
   * @returns
   */
  private static convertDeviceContentDay(deviceContentDay: DeviceContentDay): any {
    const isUnlimited = deviceContentDay[Constant.SIMPLE_CONTENT_DAY_ELEMENT]?.filter(item => item.unlimitedInfo != null)?.length > 0;
    deviceContentDay[Constant.SIMPLE_CONTENT_DAY_ELEMENT] = deviceContentDay[Constant.SIMPLE_CONTENT_DAY_ELEMENT]?.filter(
      data => data['playlistId'] != -1
    );
    return {
      deviceId: deviceContentDay.deviceId,
      contentDays: deviceContentDay[Constant.SIMPLE_CONTENT_DAY_ELEMENT]?.map(contentDay => {
        return {
          fullDate: moment(contentDay['fullDate']).format(Constant.FORMAT_DATE),
          playlistId: contentDay['playlistId']
        };
      }),
      isUnlimited: isUnlimited
    };
  }

  /**
   * get list device's id not yet completed delivery
   * @param deviceCalendars
   * @returns
   */
  public static getDeviceIdsNotYetCompletedDelivery(deviceCalendars: Array<DeviceCalendar>): Array<Number> {
    return deviceCalendars
      ?.filter(
        device => (device.status == DeviceStatusEnum.WAITING || device.status == DeviceStatusEnum.IN_PROGRESS) && !device.isDelivering
      )
      ?.map(deviceData => deviceData?.id);
  }

  /**
   * customize time picker
   * @param languageKey
   * @param isNotDialog
   */
  public static customizeTimePicker(languageKey: string, isNotDialog?: boolean): void {
    if (languageKey == Constant.EN_LANGUAGE && !isNotDialog) {
      return;
    }
    const dpElement = Array.prototype.slice.call(document.getElementsByClassName(Constant.TIME_PICKER_CLASS));
    if (dpElement) {
      const dpSubElement = Array.prototype.slice.call(document.getElementsByClassName(Constant.MERIDIEM_TIME_PICKER_CLASS));
      if (dpSubElement) {
        const meridiemElement = dpSubElement;
        dpSubElement.forEach(element => {
          element.remove();
        });
        dpElement.forEach((e1, index) => {
          const element = meridiemElement[index];
          if (languageKey == Constant.JP_LANGUAGE) {
            e1.insertBefore(element, e1.firstElementChild);
          } else {
            this.insertAfter(element, e1.lastElementChild);
          }
        });
      }
    }
  }

  /**
   * insert element after
   * @param newNode
   * @param existingNode
   */
  private static insertAfter(newNode, existingNode) {
    existingNode.parentNode.insertBefore(newNode, existingNode.nextSibling);
  }

  /**
   * get locale
   * @param languageKey
   * @returns
   */
  public static getLocale(languageKey: string) {
    return languageKey == Constant.JP_LANGUAGE ? Constant.JA : Constant.EN_GB;
  }

  /**
   * Handle media types to display
   *
   * @param mediaTypes
   * @param translateService
   * @returns
   */
  public static handleMediaTypesToDisplay(mediaTypes: string[], translateService: TranslateService): string[] {
    if (!mediaTypes || !mediaTypes?.length) {
      return mediaTypes;
    }
    if (mediaTypes.length == Constant.MEDIA_TYPE_NUMBER) {
      return translateService.instant('dashboard.tab-content-report.select-all');
    }
    let mediaTypeConverted = [];
    mediaTypes.forEach(type => {
      switch (type) {
        case TypeMediaInDashBoard.IMAGE:
          mediaTypeConverted.push(translateService.instant('dialog-extraction-condition-setting.image-type'));
          break;
        case TypeMediaInDashBoard.VIDEO:
          mediaTypeConverted.push(translateService.instant('dialog-extraction-condition-setting.video-type'));
          break;
        default:
          break;
      }
    });
    return mediaTypeConverted;
  }

  /**
   * set data timetable after overwrite all
   *
   * @param timetablesSet
   * @param timetableGet
   */
  public static setDataTimetableAfterOverwriteAll(timetableSet: Timetable, timetableGet: Timetable): void {
    timetableSet.label = timetableGet?.label;
    timetableSet.name = timetableGet.name;
    timetableSet.timetableSchedule = timetableGet?.timetableSchedule;

    timetableSet.templateDisplay1s = timetableGet.templateDisplay1s ? timetableGet.templateDisplay1s : undefined;
    timetableSet['nameTemplateMain1'] = timetableGet.templateDisplay1s ? timetableGet?.templateDisplay1s[0]?.name : undefined;
    timetableSet.displayTemplate1 = timetableGet.templateDisplay1s ? timetableGet.displayTemplate1 : undefined;
  }

  /**
   * update language headers of timetable schedule
   *
   * @param headers
   * @param translateService
   */
  public static updateLanguageHeaders(headers: any, translateService: TranslateService): void {
    headers.forEach((element, index) => {
      if (new RegExp(Constant.REGEX_FORMAT_HEADERS_EN).exec(element) || new RegExp(Constant.REGEX_FORMAT_HEADERS_JP).exec(element)) {
        if (index == 0) {
          headers[index] = translateService.instant('timetable-editor.time');
        } else {
          headers[index] = Helper.formatString(translateService.instant('timetable-editor.item'), `${index}`);
        }
      }
    });
  }
  /**
   * update language last time when change language
   * @param lastTime
   * @param languageKey
   * @returns
   */
  public static updateLanguageLastTime(lastTime: string, languageKey: any): any {
    if (!lastTime) {
      return undefined;
    }
    if (languageKey == Constant.EN_LANGUAGE) {
      return `${moment(lastTime, 'YYYY-MM-DD HH:mm:ss').format('YYYY/MM/DD HH:mm:ss')}`;
    } else {
      return `${moment(lastTime, 'YYYY-MM-DD HH:mm:ss').format('YYYY年M月D日 HH:mm:ss')}`;
    }
  }

  /**
   * Convert EDS list Route list from server
   *
   * @param edsRouteListDatas
   * @returns
   */
  public static convertEdsListRouteListFromServer(edsRouteListDatas: any[]): Array<RouteDestination> {
    if (!edsRouteListDatas) {
      return [];
    }
    return edsRouteListDatas.map(edsRouteListData => this.convertEdsRouteListDataFromServer(edsRouteListData));
  }

  /**
   * Convert EDS Route list data from server
   *
   * @param edsRouteListData
   * @returns
   */
  public static convertEdsRouteListDataFromServer(edsRouteListData: any) {
    let edsRouteList = new RouteDestination();
    edsRouteList.id = edsRouteListData['id'];
    edsRouteList.label = edsRouteListData['label'];
    edsRouteList.routeNo = edsRouteListData['routeNo'];
    edsRouteList.routeName = edsRouteListData['routeName'];
    edsRouteList.adjustedRouteNo = edsRouteListData['adjustedRouteNo'];
    edsRouteList.adjustedRouteName = edsRouteListData['adjustedRouteName'];
    edsRouteList.suffix = edsRouteListData['suffix'];
    edsRouteList.idTemplate1 = edsRouteListData['idTemplate1'];
    edsRouteList.idTemplate2 = edsRouteListData['idTemplate2'];
    edsRouteList.idTemplate3 = edsRouteListData['idTemplate3'];
    edsRouteList.idTemplate4 = edsRouteListData['idTemplate4'];
    edsRouteList.idTemplate5 = edsRouteListData['idTemplate5'];
    edsRouteList.idTemplate6 = edsRouteListData['idTemplate6'];
    edsRouteList.display1 = edsRouteListData['template1'];
    edsRouteList.display2 = edsRouteListData['template2'];
    edsRouteList.display3 = edsRouteListData['template3'];
    edsRouteList.display4 = edsRouteListData['template4'];
    edsRouteList.display5 = edsRouteListData['template5'];
    edsRouteList.display6 = edsRouteListData['template6'];
    edsRouteList.isEditAdjustedName = edsRouteListData['isEditAdjustedName'];
    edsRouteList.isEditAdjustedNo = edsRouteListData['isEditAdjustedNo'];
    let templateNames = [];
    templateNames.push(edsRouteListData['template1Name']);
    templateNames.push(edsRouteListData['template2Name']);
    templateNames.push(edsRouteListData['template3Name']);
    templateNames.push(edsRouteListData['template4Name']);
    templateNames.push(edsRouteListData['template5Name']);
    templateNames.push(edsRouteListData['template6Name']);
    edsRouteList.templateNamesToDisplay = templateNames;
    edsRouteList.isShowIconMultiDisplay = templateNames.filter(data => data).length > 1;
    edsRouteList.templateNameShowInGUI = templateNames.filter(data => data)[0];
    edsRouteList.busStops = this.convertEdsBusStopFromServer(edsRouteListData['listBusStop']);
    return edsRouteList;
  }

  /**
   * Convert EDS Bus Stop From Server
   * @param listBusStop
   * @returns
   */
  public static convertEdsBusStopFromServer(listBusStop: any): Array<BusStopRoute> {
    if (!listBusStop) {
      return [];
    }
    let edsBusStops = new Array<BusStopRoute>();
    [...listBusStop].forEach(element => {
      let edsBusStop = new BusStopRoute();
      edsBusStop.index = element['index'];
      edsBusStop.adjustedName = element['adjustedBusStopName'];
      edsBusStop.name = element['busStopName'];
      edsBusStop.no = element['busStopNo'];
      edsBusStop.isEdited = element['isEdited'];
      edsBusStop.isChecked = element['isChecked'];
      edsBusStops.push(edsBusStop);
    });
    return edsBusStops;
  }

  /**
   * Convert eds route to backward
   *
   * @param routeDestination
   * @returns
   */
  public static convertEdsRouteToBackward(routeDestination: RouteDestination): any {
    return {
      id: routeDestination.id,
      label: routeDestination.label,
      routeNo: routeDestination.routeNo,
      suffix: routeDestination.suffix,
      routeName: routeDestination.routeName,
      adjustedRouteNo: routeDestination.adjustedRouteNo,
      adjustedRouteName: routeDestination.adjustedRouteName,
      listBusStopJson: JSON.stringify(this.convertEdsBusStopToBackward(routeDestination.busStops)),
      idTemplate1: routeDestination.idTemplate1,
      idTemplate2: routeDestination.idTemplate2,
      idTemplate3: routeDestination.idTemplate3,
      idTemplate4: routeDestination.idTemplate4,
      idTemplate5: routeDestination.idTemplate5,
      idTemplate6: routeDestination.idTemplate6,
      isEditAdjustedNo: routeDestination.isEditAdjustedNo,
      isEditAdjustedName: routeDestination.isEditAdjustedName
    };
  }

  /**
   * Convert eds routes to backward
   *
   * @param routeDestinations
   * @returns
   */
  public static convertEdsRoutesToBackward(routeDestinations: RouteDestination[]): any[] {
    return routeDestinations.map(route => this.convertEdsRouteToBackward(route));
  }

  /**
   * Convert eds bus stop to backward
   *
   * @param busStops
   * @returns
   */
  public static convertEdsBusStopToBackward(busStops: Array<BusStopRoute>) {
    if (busStops?.length <= 0) {
      return [];
    }
    return busStops.map(busStop => {
      return {
        index: busStop.index,
        busStopNo: busStop.no,
        busStopName: busStop.name,
        adjustedBusStopName: busStop.adjustedName,
        isEdited: busStop.isEdited,
        isChecked: busStop.isChecked
      };
    });
  }

  /**
   * convert data text area led
   * @param textAreaData
   * @param displayModel
   */
  public static convertDataTextAreaLED(textAreaData: any, displayModel?: DisplayModelEnum): TextAreaLED {
    let textArea = new TextAreaLED();
    textArea.id = textAreaData['id'];
    textArea.name = textAreaData['name'];
    textArea.index = textAreaData['index'];
    textArea.isFix = textAreaData['isFix'];
    textArea.width = textAreaData['width'];
    textArea.height = textAreaData['height'];
    textArea.posX = textAreaData['posX'];
    textArea.posY = textAreaData['posY'];
    textArea.referencePoint = textAreaData['referencePoint'];
    textArea.text = textAreaData['text'];
    textArea.fontName = textAreaData['fontName'];
    textArea.fontSize = textAreaData['fontSize'];
    textArea.outlineSize = textAreaData['outlineSize'];
    textArea.outlineColor = textAreaData['outlineColor'];
    textArea.horizontalTextAlignment = textAreaData['horizontalTextAlignment'];
    textArea.verticalTextAlignment = textAreaData['verticalTextAlignment'];
    textArea.orientation = textAreaData['orientation'];
    textArea.scrollStatus = textAreaData['scrollStatus'];
    textArea.scrollSpeed = textAreaData['scrollSpeed'];
    textArea.scrollDirection = textAreaData['scrollDirection'];
    textArea.linkReferenceData = textAreaData['linkReferenceData'];
    textArea.indexWordGroupId = textAreaData['indexWordGroupId'];
    textArea.referencePositionColumn = textAreaData['referencePositionColumn'];
    textArea.backgroundColor = textAreaData['backgroundColor'];
    textArea.fontColor = textAreaData['fontColor'];
    if (displayModel && displayModel == DisplayModelEnum.AMBER) {
      textArea.fontColor = '#F0A30AFF';
    }
    textArea.pauseTime = textAreaData['pauseTime'];
    textArea.areaLink = textAreaData['areaLink'];
    textArea.linkWith = textAreaData['linkWith'];
    textArea.leadSpacing = textAreaData['leadSpacing'];
    textArea.letterSpacing = textAreaData['letterSpacing'];
    textArea.baselineHeight = textAreaData['baselineHeight'];
    textArea.changeover = textAreaData['changeover'];
    textArea.duration = textAreaData['duration'];
    textArea.delimiters = textAreaData['delimiters'];
    textArea.otherDelimiters = textAreaData['otherDelimiters'];
    textArea.fontType = textAreaData['fontType'];
    return textArea;
  }

  /**
   * convert data picture area led
   * @param pictureAreaData
   */
  public static convertDataPictureAreaLED(pictureAreaData: any): PictureAreaLED {
    let pictureArea = new PictureAreaLED();
    pictureArea.id = pictureAreaData['id'];
    pictureArea.name = pictureAreaData['name'];
    pictureArea.index = pictureAreaData['index'];
    pictureArea.isFix = pictureAreaData['isFix'];
    pictureArea.width = pictureAreaData['width'];
    pictureArea.height = pictureAreaData['height'];
    pictureArea.posX = pictureAreaData['posX'];
    pictureArea.posY = pictureAreaData['posY'];
    pictureArea.pauseTime = pictureAreaData['pauseTime'];
    pictureArea.scrollStatus = pictureAreaData['scrollStatus'];
    pictureArea.scrollSpeed = pictureAreaData['scrollSpeed'];
    pictureArea.scrollDirection = pictureAreaData['scrollDirection'];
    pictureArea.referencePoint = pictureAreaData['referencePoint'];
    pictureArea.templateId = pictureAreaData['templateId'];
    pictureArea.pauseTime = pictureAreaData['pauseTime'];
    if (pictureAreaData['media']) {
      pictureArea.media = this.convertMediaData(pictureAreaData['media']);
    }
    pictureArea.objectFit = pictureAreaData['objectFit'];
    pictureArea.linkReferenceData = pictureAreaData['linkReferenceData'];
    pictureArea.indexWordGroupId = pictureAreaData['indexWordGroupId'];
    pictureArea.referencePositionColumn = pictureAreaData['referencePositionColumn'];
    pictureArea.isFlipbook = pictureAreaData['isFlipbook'];
    return pictureArea;
  }

  /**
   * convert data areas led
   * @param data
   * @param displayModel
   * @returns
   */
  public static convertDataAreasLED(data: any, displayModel?: DisplayModelEnum): Array<AreaLED> {
    let areasOutput = new Array<AreaLED>();
    if (!data) {
      return areasOutput;
    }
    let textAreas = data['textAreas'];
    if (textAreas) {
      textAreas.forEach(textAreaData => {
        let textAreaOutput: TextAreaLED = this.convertDataTextAreaLED(textAreaData, displayModel);
        areasOutput.push(textAreaOutput);
      });
    }
    let pictureAreasDTO = data['pictureAreas'];
    if (pictureAreasDTO) {
      pictureAreasDTO.forEach(pictureAreaDTOData => {
        let pictureAreaOutput: PictureAreaLED = this.convertDataPictureAreaLED(pictureAreaDTOData);
        areasOutput.push(pictureAreaOutput);
      });
    }
    areasOutput = areasOutput.sort(function(area1, area2) {
      return area1.index - area2.index;
    });
    return areasOutput;
  }

  public static convertDataAreasLEDBackward(data: Array<AreaLED>): DataResponseArea {
    let areaIds = data.map(area => area.id);
    let textAreas = data.filter(area => area.checkTypeTextArea()) as Array<TextAreaLED>;
    textAreas = textAreas.map(area => {
      return this.getTextAreaLED(area);
    });
    let pictureAreas = data.filter(area => !area.checkTypeTextArea()) as Array<PictureAreaLED>;
    pictureAreas = pictureAreas.map(area => {
      return this.getPictureAreaLED(area);
    });
    return new DataResponseArea(textAreas, pictureAreas, areaIds);
  }

  /**
   * get picture area led
   * @param area
   */
  private static getPictureAreaLED(area: AreaLED): any {
    return {
      type: this.PICTURE_AREA_TYPE,
      id: area.id,
      name: area.name.trim(),
      width: area.width,
      height: area.height,
      posX: area.posX,
      posY: area.posY,
      referencePoint: area.referencePoint,
      index: area.index,
      isFix: area.isFix,
      media: area.getArea().media ?? null,
      mediaId: area.getArea().media?.id ?? null,
      objectFit: area.getArea().objectFit,
      linkReferenceData: area.getArea().linkReferenceData,
      indexWordGroupId: area.getArea().indexWordGroupId,
      referencePositionColumn: area.getArea().referencePositionColumn,
      pauseTime: area.getArea().pauseTime,
      scrollStatus: area.scrollStatus,
      scrollSpeed: area.scrollSpeed,
      scrollDirection: area.scrollDirection,
      templateId: area.templateId,
      isFlipbook: area.getArea().isFlipbook
    };
  }

  /**
   * get text area led
   * @param area
   */
  private static getTextAreaLED(area: AreaLED): any {
    return {
      type: this.TEXT_AREA_TYPE,
      id: area.id,
      name: area.name.trim(),
      width: area.width,
      height: area.height,
      posX: area.posX,
      posY: area.posY,
      referencePoint: area.referencePoint,
      index: area.index,
      isFix: area.isFix,
      text: area.getArea().text,
      fontName: area.getArea().fontName,
      fontSize: area.getArea().fontSize,
      outlineSize: area.getArea().outlineSize,
      horizontalTextAlignment: area.getArea().horizontalTextAlignment,
      verticalTextAlignment: area.getArea().verticalTextAlignment,
      orientation: area.getArea().orientation,
      scrollStatus: area.scrollStatus,
      scrollSpeed: area.scrollSpeed,
      scrollDirection: area.scrollDirection,
      linkReferenceData: area.getArea().linkReferenceData,
      indexWordGroupId: area.getArea().indexWordGroupId,
      referencePositionColumn: area.getArea().referencePositionColumn,
      backgroundColor: area.getArea().backgroundColor,
      fontColor: area.getArea().fontColor,
      outlineColor: area.getArea().outlineColor,
      pauseTime: area.getArea().pauseTime,
      areaLink: area.getArea().areaLink,
      linkWith: area.getArea().linkWith,
      leadSpacing: area.getArea().leadSpacing,
      letterSpacing: area.getArea().letterSpacing,
      baselineHeight: area.getArea().baselineHeight,
      changeover: area.getArea().changeover,
      duration: area.getArea().duration,
      delimiters: area.getArea().delimiters,
      otherDelimiters: area.getArea().otherDelimiters,
      fontType: area.getArea().fontType
    };
  }
  /**
   * Convert export eds route to backward
   *
   * @param routeDestination
   * @returns
   */
  public static convertExportEdsRouteToBackward(routeDestination: RouteDestination): any {
    return {
      id: routeDestination.id,
      label: routeDestination.label,
      routeNo: routeDestination.routeNo,
      suffix: routeDestination.suffix,
      routeName: routeDestination.routeName,
      adjustedRouteNo: routeDestination.adjustedRouteNo,
      adjustedRouteName: routeDestination.adjustedRouteName,
      listBusStopJson: JSON.stringify(this.convertEdsBusStopToBackward(routeDestination.busStops)),
      listBusStop: this.convertEdsBusStopToBackward(routeDestination.busStops),
      idTemplate1: routeDestination.idTemplate1,
      idTemplate2: routeDestination.idTemplate2,
      idTemplate3: routeDestination.idTemplate3,
      idTemplate4: routeDestination.idTemplate4,
      idTemplate5: routeDestination.idTemplate5,
      idTemplate6: routeDestination.idTemplate6,
      template1Name: routeDestination.templateNamesToDisplay[0],
      template2Name: routeDestination.templateNamesToDisplay[1],
      template3Name: routeDestination.templateNamesToDisplay[2],
      template4Name: routeDestination.templateNamesToDisplay[3],
      template5Name: routeDestination.templateNamesToDisplay[4],
      template6Name: routeDestination.templateNamesToDisplay[5],
      template1: this.convertTemplateLedToBackward(routeDestination.display1),
      template2: this.convertTemplateLedToBackward(routeDestination.display2),
      template3: this.convertTemplateLedToBackward(routeDestination.display3),
      template4: this.convertTemplateLedToBackward(routeDestination.display4),
      template5: this.convertTemplateLedToBackward(routeDestination.display5),
      template6: this.convertTemplateLedToBackward(routeDestination.display6),
      isEditAdjustedNo: routeDestination.isEditAdjustedNo,
      isEditAdjustedName: routeDestination.isEditAdjustedName
    };
  }

  /**
   * Convert template led to backward
   * @param template
   * @returns
   */
  private static convertTemplateLedToBackward(template: TemplateLED): any {
    if (!template) {
      return null;
    }
    return {
      id: template.id,
      name: template.name,
      templateGroupId: template.templateGroupId,
      width: template.width,
      height: template.height,
      templateGroupName: template.templateGroupName,
      displayModel: template.displayModel
    };
  }

  /**
   * Convert export eds routes to backward
   *
   * @param routeDestinations
   * @returns
   */
  public static convertExportEdsRoutesToBackward(routeDestinations: RouteDestination[]): any[] {
    return routeDestinations.map(route => this.convertExportEdsRouteToBackward(route));
  }

  /**
   * Convert publish eds route to backward
   *
   * @param routeDestination
   * @returns
   */
  public static convertPublishEdsRouteToBackward(routeDestination: RouteDestination): any {
    return {
      id: routeDestination.id,
      routeNo: routeDestination.routeNo,
      suffix: routeDestination.suffix,
      routeName: routeDestination.routeName,
      adjustedRouteNo: routeDestination.adjustedRouteNo,
      adjustedRouteName: routeDestination.adjustedRouteName,
      listBusStopJson: JSON.stringify(this.convertEdsBusStopToBackward(routeDestination.busStops)),
      busStops: this.convertEdsBusStopToBackward(routeDestination.busStops),
      templateIdsToDisplay: routeDestination.templateIdsToDisplay
    };
  }

  /**
   * convert data publish
   * @param edsRoutesDownload
   * @returns
   */
  public static convertDataPublish(edsRoutesDownload: any[]): any {
    let templateIds: Array<Number> = new Array<any>();
    let edsRoutes = edsRoutesDownload.map(route => {
      route.templateIdsToDisplay = new Array<Number>();
      for (let index = 1; index <= 6; index++) {
        let template: TemplateLED = route[`display${index}`];
        if (template) {
          if (!templateIds?.includes(template?.id)) {
            templateIds.push(template.id);
          }
          route.templateIdsToDisplay.push(template.id);
        } else {
          route.templateIdsToDisplay.push(null);
        }
      }
      return Helper.convertPublishEdsRouteToBackward(route);
    });
    return {
      templateIds: templateIds,
      edsRoutes: edsRoutes
    };
  }

  /**
   * update system time when click apply
   * @param systemTime
   * @param languageKey
   * @returns
   */
  public static updateSystemTime(systemTime: string, languageKey: any): any {
    if (!systemTime) {
      return undefined;
    }
    moment.locale('en');
    if (languageKey == Constant.EN_LANGUAGE) {
      return `${moment(systemTime, 'dd MMM D hh:mm:ss YYYY').format('YYYY/MM/DD HH:mm:ss')}` + `${' JST'}`;
    } else {
      return `${moment(systemTime, 'dd MMM D hh:mm:ss YYYY').format('YYYY年M月D日 HH:mm:ss')}` + `${' (JST)'}`;
    }
  }

  /**
   * update language system time when change language
   * @param systemTime
   * @param languageKey
   * @returns
   */
  public static updateLanguageSystemTime(systemTime: string, languageKey: any): any {
    if (!systemTime) {
      return undefined;
    }
    if (languageKey == Constant.EN_LANGUAGE) {
      return `${moment(systemTime, 'YYYY/MM/DD HH:mm:ss').format('YYYY/MM/DD HH:mm:ss')}` + `${' JST'}`;
    } else {
      return `${moment(systemTime, 'YYYY/MM/DD HH:mm:ss').format('YYYY年M月D日 HH:mm:ss')}` + `${' (JST)'}`;
    }
  }

  /**
   * create data payload group
   * @param groupName
   * @param account
   * @returns
   */
  public static createPayloadGroup(groupName: any, account: any): any {
    return {
      groupName: groupName,
      account: account
    };
  }

  /**
   * create data payload group device
   * @param groupId
   * @param registrationIds
   * @returns
   */
  public static createPayloadGroupDevice(groupId: any, registrationIds: any[]): any {
    return {
      groupId: groupId,
      device: registrationIds
    };
  }

  /**
   * Get payload status enum
   *
   * @param activeColumnHeader
   * @returns
   */
  public static getPayloadStatusEnum(activeColumnHeader: ActiveColumnHeader): PayloadDeviceStatusEnum {
    switch (activeColumnHeader) {
      case ActiveColumnHeader.WAITING:
        return PayloadDeviceStatusEnum.WAITING;
      case ActiveColumnHeader.IN_PROGRESS:
        return PayloadDeviceStatusEnum.IN_PROGRESS;
      case ActiveColumnHeader.COMPLETED:
        return PayloadDeviceStatusEnum.COMPLETED;
      case ActiveColumnHeader.FAILED:
        return PayloadDeviceStatusEnum.FAILED;
      case ActiveColumnHeader.CANCEL:
        return PayloadDeviceStatusEnum.CANCELLED;
      default:
        return null;
    }
  }

  /**
   * Get device status enum
   *
   * @param activeColumnHeader
   * @returns
   */
  public static getDeviceStatusEnum(activeColumnHeader: ActiveColumnHeader): DeviceStatusEnum {
    switch (activeColumnHeader) {
      case ActiveColumnHeader.WAITING:
        return DeviceStatusEnum.WAITING;
      case ActiveColumnHeader.IN_PROGRESS:
        return DeviceStatusEnum.IN_PROGRESS;
      case ActiveColumnHeader.COMPLETED:
        return DeviceStatusEnum.COMPLETED;
      case ActiveColumnHeader.FAILED:
        return DeviceStatusEnum.FAILED;
      case ActiveColumnHeader.CANCEL:
        return DeviceStatusEnum.CANCELLED;
      default:
        return DeviceStatusEnum.NO_RESPONSE;
    }
  }

  /**
   * get areas index word and group ids by template
   * @param template
   * @returns
   */
  public static getAreasIndexWordAngGroupIdsByTemplate(template: Template): any[] {
    let areasIndexWord: Array<Area> = new Array<Area>();
    let groupIds: Array<Number> = new Array<Number>();
    if (template?.layers) {
      template.layers.forEach(layer => {
        layer.areas?.forEach(area => {
          if (
            !area.isFix &&
            ((area as TextArea).linkReferenceData === LinkDataTextEnum.INDEX_WORD ||
              (area as PictureArea).attribute === LinkDataPictureEnum.INDEX_WORD)
          ) {
            areasIndexWord.push(area);
          }
        });
      });
    }
    if (!areasIndexWord.length) {
      return [areasIndexWord, groupIds];
    }

    // get list group id
    areasIndexWord.forEach(area => {
      groupIds.push(area.indexWordGroupId);
    });
    return [areasIndexWord, groupIds];
  }

  /**
   * get areas index word and group ids
   * @param templatesDisplay
   * @param displayTemplate
   * @returns
   */
  public static getAreasIndexWordAndGroupIds(templatesDisplay: Template[], displayTemplate: DisplayTemplate): any {
    let areasIndexWord = [];
    let groupIds = new Set();
    if (!displayTemplate || !templatesDisplay?.length) {
      return [areasIndexWord, groupIds];
    }
    let areasIndexWordAndGroupIds;
    if (displayTemplate.idMainPage) {
      areasIndexWordAndGroupIds = this.getAreasIndexWordAngGroupIdsByTemplate(templatesDisplay[DestinationEnum.MAIN]);
      areasIndexWord = _.concat(areasIndexWord, areasIndexWordAndGroupIds[Constant.AREAS_INDEX_WORD_INDEX]);
      groupIds = this.mergeSet(groupIds, areasIndexWordAndGroupIds[Constant.GROUP_IDS_INDEX]);
    }
    if (displayTemplate.idSubPage1) {
      areasIndexWordAndGroupIds = this.getAreasIndexWordAngGroupIdsByTemplate(templatesDisplay[DestinationEnum.SUB_PAGE_1]);
      areasIndexWord = _.concat(areasIndexWord, areasIndexWordAndGroupIds[Constant.AREAS_INDEX_WORD_INDEX]);
      groupIds = this.mergeSet(groupIds, areasIndexWordAndGroupIds[Constant.GROUP_IDS_INDEX]);
    }
    if (displayTemplate.idSubPage2) {
      areasIndexWordAndGroupIds = this.getAreasIndexWordAngGroupIdsByTemplate(templatesDisplay[DestinationEnum.SUB_PAGE_2]);
      areasIndexWord = _.concat(areasIndexWord, areasIndexWordAndGroupIds[Constant.AREAS_INDEX_WORD_INDEX]);
      groupIds = this.mergeSet(groupIds, areasIndexWordAndGroupIds[Constant.GROUP_IDS_INDEX]);
    }
    if (displayTemplate.idSubPage3) {
      areasIndexWordAndGroupIds = this.getAreasIndexWordAngGroupIdsByTemplate(templatesDisplay[DestinationEnum.SUB_PAGE_3]);
      areasIndexWord = _.concat(areasIndexWord, areasIndexWordAndGroupIds[Constant.AREAS_INDEX_WORD_INDEX]);
      groupIds = this.mergeSet(groupIds, areasIndexWordAndGroupIds[Constant.GROUP_IDS_INDEX]);
    }
    if (displayTemplate.idSubPage4) {
      areasIndexWordAndGroupIds = this.getAreasIndexWordAngGroupIdsByTemplate(templatesDisplay[DestinationEnum.SUB_PAGE_4]);
      areasIndexWord = _.concat(areasIndexWord, areasIndexWordAndGroupIds[Constant.AREAS_INDEX_WORD_INDEX]);
      groupIds = this.mergeSet(groupIds, areasIndexWordAndGroupIds[Constant.GROUP_IDS_INDEX]);
    }
    if (displayTemplate.idSubPage5) {
      areasIndexWordAndGroupIds = this.getAreasIndexWordAngGroupIdsByTemplate(templatesDisplay[DestinationEnum.SUB_PAGE_5]);
      areasIndexWord = _.concat(areasIndexWord, areasIndexWordAndGroupIds[Constant.AREAS_INDEX_WORD_INDEX]);
      groupIds = this.mergeSet(groupIds, areasIndexWordAndGroupIds[Constant.GROUP_IDS_INDEX]);
    }
    if (displayTemplate.idSubPage6) {
      areasIndexWordAndGroupIds = this.getAreasIndexWordAngGroupIdsByTemplate(templatesDisplay[DestinationEnum.SUB_PAGE_6]);
      areasIndexWord = _.concat(areasIndexWord, areasIndexWordAndGroupIds[Constant.AREAS_INDEX_WORD_INDEX]);
      groupIds = this.mergeSet(groupIds, areasIndexWordAndGroupIds[Constant.GROUP_IDS_INDEX]);
    }
    if (displayTemplate.idSubPage7) {
      areasIndexWordAndGroupIds = this.getAreasIndexWordAngGroupIdsByTemplate(templatesDisplay[DestinationEnum.SUB_PAGE_7]);
      areasIndexWord = _.concat(areasIndexWord, areasIndexWordAndGroupIds[Constant.AREAS_INDEX_WORD_INDEX]);
      groupIds = this.mergeSet(groupIds, areasIndexWordAndGroupIds[Constant.GROUP_IDS_INDEX]);
    }
    if (displayTemplate.idSubPage8) {
      areasIndexWordAndGroupIds = this.getAreasIndexWordAngGroupIdsByTemplate(templatesDisplay[DestinationEnum.SUB_PAGE_8]);
      areasIndexWord = _.concat(areasIndexWord, areasIndexWordAndGroupIds[Constant.AREAS_INDEX_WORD_INDEX]);
      groupIds = this.mergeSet(groupIds, areasIndexWordAndGroupIds[Constant.GROUP_IDS_INDEX]);
    }
    if (displayTemplate.idSubPage9) {
      areasIndexWordAndGroupIds = this.getAreasIndexWordAngGroupIdsByTemplate(templatesDisplay[DestinationEnum.SUB_PAGE_9]);
      areasIndexWord = _.concat(areasIndexWord, areasIndexWordAndGroupIds[Constant.AREAS_INDEX_WORD_INDEX]);
      groupIds = this.mergeSet(groupIds, areasIndexWordAndGroupIds[Constant.GROUP_IDS_INDEX]);
    }
    if (displayTemplate.idEmergencyPage) {
      areasIndexWordAndGroupIds = this.getAreasIndexWordAngGroupIdsByTemplate(templatesDisplay[DestinationEnum.EMERGENCY]);
      areasIndexWord = _.concat(areasIndexWord, areasIndexWordAndGroupIds[Constant.AREAS_INDEX_WORD_INDEX]);
      groupIds = this.mergeSet(groupIds, areasIndexWordAndGroupIds[Constant.GROUP_IDS_INDEX]);
    }
    return [areasIndexWord, [...groupIds]];
  }

  /**
   * get name index word from schedule
   * @param timetable
   * @param area
   * @param currentIndex
   * @param position
   * @returns
   */
  public static getNameIndexWordFromSchedule(timetable: Timetable, area: Area, currentIndex: number, position: number): string {
    if (position === -1) {
      return Constant.EMPTY;
    }
    return timetable.timetableSchedule?.itemDetails[area.referencePositionRow + currentIndex]?.items[position];
  }

  /**
   * get name index word from schedule
   * @param timetable
   * @param area
   * @param currentIndex
   * @param position
   * @returns
   */
  public static getNameIndexWordFromScheduleRegistration(schedule: any, area: Area, currentIndex: number, position: number): string {
    if (position === -1 || !schedule[area.referencePositionRow + currentIndex]) {
      return Constant.EMPTY;
    }
    return schedule[area.referencePositionRow + currentIndex][position];
  }

  /**
   * get name index word from schedule
   * @param timetable
   * @param area
   * @param currentIndex
   * @param position
   * @returns
   */
  public static getNameIndexWordFromScheduleMerge(schedule: any, area: Area, currentIndex: number, position: number): string {
    if (position === -1 || !schedule.length || !schedule[currentIndex + area.referencePositionRow]) {
      return Constant.EMPTY;
    }
    return schedule[area.referencePositionRow + currentIndex][position];
  }

  /**
   * get name index word from schedule to validate
   * @param timetable
   * @param currentIndex
   * @param position
   * @returns
   */
  public static getNameIndexWordFromScheduleToValidate(timetable: Timetable, currentIndex: number, position: number): string {
    if (position === -1) {
      return Constant.EMPTY;
    }
    return timetable.timetableSchedule?.itemDetails[currentIndex]?.items[position];
  }

  /**
   * Merge set
   *
   * @param iterables
   * @returns
   */
  public static mergeSet(...iterables): Set<any> {
    const set = new Set();
    for (const iterable of iterables) {
      for (const item of iterable) {
        set.add(item);
      }
    }
    return set;
  }

  /**
   * validate index word
   * @param timetables
   * @param indexWordService
   */
  public static async validateIndexWordInvalid(timetables: Array<Timetable>, indexWordService: IndexWordService): Promise<boolean> {
    for (const timetable of timetables) {
      if (!timetable.timetableSchedule) {
        continue;
      }
      let areasIndexWordAndGroupIds = Helper.getAreasIndexWordAndGroupIds(timetable.templateDisplay1s, timetable?.displayTemplate1);
      let areasIndexWord = areasIndexWordAndGroupIds[Constant.AREAS_INDEX_WORD_INDEX];
      let groupIds = areasIndexWordAndGroupIds[Constant.GROUP_IDS_INDEX];
      let isDataInvalid = await new Promise<boolean>(resolve => {
        indexWordService.getIndexWordsByGroupIds(groupIds).subscribe(indexWords => {
          if (areasIndexWord.length && timetable.timetableSchedule.itemDetails && indexWords.length) {
            let isIndexWordInvalid = areasIndexWord.some(area => {
              return timetable.timetableSchedule.itemDetails.some((item, index) => {
                if (area.referencePositionRow <= index) {
                  let nameIndexWord = Helper.getNameIndexWordFromScheduleToValidate(timetable, index, area.referencePositionColumn + 1);
                  if (!nameIndexWord || !_.cloneDeep(nameIndexWord).trim()) {
                    return;
                  }
                  let indexWord = indexWords.find(item => item.groupId == area.indexWordGroupId && item.name == nameIndexWord);
                  if (
                    !indexWord ||
                    (area.checkTypeTextArea() && (!indexWord.text || indexWord.text == Constant.EMPTY)) ||
                    (!area.checkTypeTextArea() && !indexWord.media)
                  ) {
                    return true;
                  }
                }
              });
            });
            resolve(isIndexWordInvalid);
          } else {
            resolve(false);
          }
        });
      });
      if (isDataInvalid) {
        return isDataInvalid;
      }
    }
  }

  /**
   * Set data for device group clone
   *
   * @param element
   * @param data
   * @param deviceId
   * @param groupExpandedClone
   * @returns
   */
  public static setDataForDeviceGroupClone(element: any, data: any, deviceId: Number, groupExpandedClone: GroupDevice): void {
    if (!groupExpandedClone) {
      return;
    }
    let index = groupExpandedClone.deviceCalendars.findIndex(data => data.id === deviceId);
    if (index != -1) {
      groupExpandedClone.deviceCalendars[index][element] = data;
    }
  }

  /**
   * get horizontal text alignment
   * @param align AlignmentEnum
   */
  public static getHorizontalTextAlignment(align: AlignmentEnum): string {
    switch (align) {
      case AlignmentEnum.RIGHT:
        return 'right';
      case AlignmentEnum.CENTER:
        return 'center';
      case AlignmentEnum.LEFT:
        return 'left';
      default:
        return 'left';
    }
  }

  /**
   * get vertical text alignment
   * @param align AlignmentEnum
   */
  public static getVerticalTextAlignment(align: AlignmentEnum): string {
    switch (align) {
      case AlignmentEnum.MIDDLE:
        return 'middle';
      case AlignmentEnum.BOTTOM:
        return 'bottom';
      case AlignmentEnum.TOP:
        return 'top';
      default:
        return 'middle';
    }
  }

  /**
   * get reference position orientation sideways
   * @param ctx
   * @param areaText
   * @param widthMeasureText
   * @returns
   */
  public static getReferencePositionOrientationSideways(ctx: any, areaText: TextArea, widthMeasureText: any): ReferencePosition {
    var referenceX = 0;
    var referenceY = 0;
    ctx.textAlign = 'left';
    let hAlign = Helper.getHorizontalTextAlignmentOrientationSideways(areaText.verticalTextAlignment);
    let vAlign = Helper.getVerticalTextAlignmentOrientationSideways(areaText.horizontalTextAlignment);
    let dummyMetrics = ctx.measureText(Constant.CANVAS_DISPLAY_DUMMY_STRING);
    switch (hAlign) {
      case 'left': //bottom
        referenceY = -dummyMetrics.actualBoundingBoxDescent / Constant.CANVAS_DISPLAY_BOTTOM_RATIO;
        break;
      case 'center': //middle
        referenceY = -areaText.canvas.width / 2 + dummyMetrics.actualBoundingBoxAscent / Constant.CANVAS_DISPLAY_MIDDLE_RATIO;
        break;
      case 'right': //top
        referenceY = -areaText.canvas.width + dummyMetrics.actualBoundingBoxAscent / Constant.CANVAS_DISPLAY_TOP_RATIO;
        break;
    }
    switch (vAlign) {
      case 'top':
        referenceX = 0;
        break;
      case 'middle':
        referenceX = areaText.canvas.height / 2 - widthMeasureText / 2;
        break;
      case 'bottom':
        referenceX = areaText.canvas.height - widthMeasureText;
        break;
    }
    return new ReferencePosition(referenceX, referenceY);
  }

  /**
   * get reference position orientation horizontal
   * @param ctx
   * @param areaText
   * @returns
   */
  public static getReferencePositionOrientationHorizontal(ctx: any, areaText: TextArea): ReferencePosition {
    var referenceX = 0;
    var referenceY = 0;
    ctx.textAlign = this.getHorizontalTextAlignment(areaText.horizontalTextAlignment);
    let textBaseline = this.getVerticalTextAlignment(areaText.verticalTextAlignment);
    let dummyMetrics = ctx.measureText(Constant.CANVAS_DISPLAY_DUMMY_STRING);
    switch (ctx.textAlign) {
      case 'left':
        referenceX = 0;
        break;
      case 'center':
        referenceX = areaText.canvas.width / 2;
        break;
      case 'right':
        referenceX = areaText.canvas.width;
        break;
    }
    switch (textBaseline) {
      case 'top':
        referenceY = dummyMetrics.actualBoundingBoxAscent / Constant.CANVAS_DISPLAY_TOP_RATIO;
        break;
      case 'middle':
        referenceY = areaText.canvas.height / 2 + dummyMetrics.actualBoundingBoxAscent / Constant.CANVAS_DISPLAY_MIDDLE_RATIO;
        break;
      case 'bottom':
        referenceY = areaText.canvas.height - dummyMetrics.actualBoundingBoxDescent / Constant.CANVAS_DISPLAY_BOTTOM_RATIO;
        break;
    }
    return new ReferencePosition(referenceX, referenceY);
  }
  /**
   * get reference position orientation vertical
   * @param ctx
   * @param areaText
   * @returns
   */
  public static getReferencePositionOrientationVertical(ctx: any, areaText: TextArea, textHeight): ReferencePosition {
    var referenceX = 0;
    var referenceY = 0;
    ctx.textAlign = 'left';
    ctx.textBaseline = 'middle';
    let horizontalAlign = Helper.getHorizontalTextAlignmentOrientationSideways(areaText.verticalTextAlignment);
    let verticalAlign = Helper.getVerticalTextAlignmentOrientationSideways(areaText.horizontalTextAlignment);
    switch (horizontalAlign) {
      case 'left':
        referenceX = areaText.fontSize / 2;
        break;
      case 'center':
        referenceX = areaText.canvas.width / 2;
        break;
      case 'right':
        referenceX = areaText.canvas.width - areaText.fontSize / 2;
        break;
    }
    switch (verticalAlign) {
      case 'top':
        referenceY = 0;
        break;
      case 'middle':
        referenceY = areaText.canvas.height / 2 - textHeight / 2;
        break;
      case 'bottom':
        referenceY = areaText.canvas.height - textHeight;
        break;
    }
    return new ReferencePosition(referenceX, referenceY);
  }

  /**
   * load fonts to preview
   * @param store
   * @param commonObject
   */
  public static async loadFontsToPreview(
    store: Store<AppState>,
    commonObject: Common,
    translateService: TranslateService,
    dialogService: DialogService
  ): Promise<any> {
    if (!commonObject.isLoadedFonts) {
      let fontsLoaded = await HelperLayout.handleFonts(commonObject.fonts);
      commonObject.isLoadedFonts = fontsLoaded?.every(item => item.load == Constant.FONT_LOADED);
      Helper.saveMainStateAction(store, commonObject);
      if (!fontsLoaded) {
        return;
      }
      let stringFontFalse = '';
      for (const item of fontsLoaded) {
        if (item && item.fail != undefined) {
          stringFontFalse += item.fail + '.ttf, ';
        }
      }
      if (stringFontFalse && stringFontFalse != '') {
        stringFontFalse = stringFontFalse.substring(0, stringFontFalse.length - 2);
        dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: translateService.instant('dialog-error.title'),
            texts: [
              translateService.instant('dialog-error.msg-false-load-font'),
              stringFontFalse,
              translateService.instant('dialog-error.view-font-default')
            ]
          }
        });
      }
    }
  }

  /**
   * Convert simple media to media
   *
   * @param simpleMedia
   * @returns
   */
  public static convertSimpleMediaToMedia(simpleMedia: SimpleMedia): Media {
    return simpleMedia.type == TypeMediaFileEnum.MP4
      ? this.convertSimpleMediaToVideo(simpleMedia)
      : this.convertSimpleMediaToImage(simpleMedia);
  }

  /**
   * Convert simple media to video
   *
   * @param simpleMedia
   * @returns
   */
  public static convertSimpleMediaToVideo(simpleMedia: SimpleMedia): Video {
    let video = new Video();
    video.id = simpleMedia['id'];
    video.name = simpleMedia['name'];
    video.url = simpleMedia['url'];
    video.type = simpleMedia['type'];
    video.width = simpleMedia['width'];
    video.height = simpleMedia['height'];
    video.folderId = simpleMedia['folderId'];
    video.randomNumber = Math.random();
    video.description = simpleMedia['description'];
    video.durationDisplay = simpleMedia['duration'];
    video.mediaNameEncode = simpleMedia['mediaNameEncode'];
    return video;
  }

  /**
   * Convert simple media to image
   *
   * @param simpleMedia
   * @returns
   */
  public static convertSimpleMediaToImage(simpleMedia: SimpleMedia): Image {
    let image = new Image();
    image.id = simpleMedia['id'];
    image.name = simpleMedia['name'];
    image.url = simpleMedia['url'];
    image.type = simpleMedia['type'];
    image.width = simpleMedia['width'];
    image.height = simpleMedia['height'];
    image.folderId = simpleMedia['folderId'];
    image.randomNumber = Math.random();
    image.mediaNameEncode = simpleMedia['mediaNameEncode'];
    return image;
  }

  /**
   * Get single device id's not yet completed
   *
   * @param groupDevices
   * @param groupClone
   * @returns device id's
   */
  public static getSingleDeviceIdsNotYetCompleted(groupDevices: GroupDevice[], groupClone: GroupDevice): Number[] {
    if (!groupDevices) {
      return [];
    }
    let deviceIds = [];
    groupDevices.forEach(group => {
      let groupDevice = groupClone && groupClone.groupId == group.groupId ? groupClone : group;
      deviceIds = deviceIds.concat(
        groupDevice.deviceCalendars
          .filter(
            device =>
              device.jobId &&
              device.jobId.indexOf(Constant.DELIVERY_SINGLE_KEY) != -1 &&
              (device.status == DeviceStatusEnum.WAITING || device.status == DeviceStatusEnum.IN_PROGRESS) &&
              !device.isDelivering
          )
          .map(deviceData => deviceData.id)
      );
    });
    return deviceIds;
  }

  /**
   * check Mapping Status Devices Group
   *
   * @param groupDevice
   * @param groupClone
   * @returns
   */
  public static checkMappingStatusDevicesGroup(groupDevice: GroupDevice, groupClone: GroupDevice): boolean {
    if (
      !groupDevice.deviceCalendars ||
      groupDevice.deviceCalendars.some(
        data =>
          data.jobId?.indexOf(Constant.DELIVERY_SINGLE_KEY) > -1 ||
          data.jobId == Constant.CMP_DELIVERY_GROUP_KEY ||
          data.jobId == Constant.CMP_DELIVERY_SINGLE_KEY ||
          data.jobId == groupDevice.name
      )
    ) {
      return true;
    }
    let group = groupClone && groupClone.groupId == groupDevice.groupId ? groupClone : groupDevice;
    let deviceDeliveryGroups = group.deviceCalendars.filter(data => data.jobId && data.jobId.indexOf(Constant.DELIVERY_GROUP_KEY) != -1);
    return (
      deviceDeliveryGroups.filter(device => device.status == DeviceStatusEnum.WAITING).length ==
        groupDevice.statusNumberObject.waitingNumber &&
      deviceDeliveryGroups.filter(device => device.status == DeviceStatusEnum.IN_PROGRESS).length ==
        groupDevice.statusNumberObject.inprogressNumber &&
      deviceDeliveryGroups.filter(device => device.status == DeviceStatusEnum.COMPLETED).length ==
        groupDevice.statusNumberObject.completedNumber &&
      deviceDeliveryGroups.filter(device => device.status == DeviceStatusEnum.CANCELLED).length ==
        groupDevice.statusNumberObject.cancelNumber &&
      deviceDeliveryGroups.filter(device => device.status == DeviceStatusEnum.FAILED).length == groupDevice.statusNumberObject.failedNumber
    );
  }

  /**
   * compare data of checked devices
   * @param checkedDevices
   * @param deviceResult
   * @returns
   */
  public static isEqualDeviceCalendar(checkedDevices: DeviceCalendar[], deviceResult: DeviceCalendar): boolean {
    return _.isEqual(
      checkedDevices.map(data => {
        return {
          deviceId: data.id,
          jobId: data.jobId
        };
      }),
      deviceResult
    );
  }

  /**
   * get group set
   * @param checkedDevices
   * @returns
   */
  public static getGroupSet(checkedDevices: DeviceCalendar[]): any {
    return [
      ...new Set(
        checkedDevices.map(device => {
          return {
            groupId: device.groupId,
            jobId: device.jobId
          };
        })
      )
    ];
  }

  /**
   * Get device in group
   *
   * @param deviceId
   * @param groupDevices
   * @returns
   */
  public static getDeviceInGroup(deviceId: Number, groupDevices: GroupDevice[]): DeviceCalendar {
    return groupDevices
      .find(group => group.deviceCalendars.map(device => device.id).includes(deviceId))
      ?.deviceCalendars.find(deviceCalendar => deviceCalendar.id == deviceId);
  }

  /**
   * Get checked devices in group
   *
   * @param deviceIds
   * @param groupDevices
   * @param groupClone
   * @returns
   */
  public static getCheckedDevicesInGroup(deviceIds: Number[], groupDevices: GroupDevice[], groupClone: GroupDevice): DeviceCalendar[] {
    let devices = new Set(
      deviceIds.map(id => {
        return this.getDeviceInGroup(id, groupDevices);
      })
    );
    if (groupClone) {
      groupClone.deviceCalendars.forEach(device => {
        if (device.isDelivering) {
          devices.add(device);
        }
      });
    }
    return [...devices].filter(data => data);
  }

  /**
   * Update active status number realtime
   *
   * @param groupDevice
   * @param status
   * @param num
   */
  public static updateActiveStatusNumberRealtime(groupDevice: GroupDevice, status: DeviceStatusEnum, num: number): void {
    let deviceCalendar = groupDevice.deviceCalendars.filter(data => data.jobId?.indexOf(Constant.DELIVERY_GROUP_KEY) > -1);
    switch (status) {
      case DeviceStatusEnum.WAITING:
        groupDevice.statusNumberObject.waitingNumber = num;
        groupDevice.statusNumberObject.inprogressNumber = deviceCalendar.filter(
          device => device.status == DeviceStatusEnum.IN_PROGRESS
        ).length;
        groupDevice.statusNumberObject.completedNumber = deviceCalendar.filter(
          device => device.status == DeviceStatusEnum.COMPLETED
        ).length;
        groupDevice.statusNumberObject.cancelNumber = deviceCalendar.filter(device => device.status == DeviceStatusEnum.CANCELLED).length;
        groupDevice.statusNumberObject.failedNumber = deviceCalendar.filter(device => device.status == DeviceStatusEnum.FAILED).length;
        groupDevice.statusNumberObjectOld = _.cloneDeep(groupDevice.statusNumberObject);
        break;
      case DeviceStatusEnum.IN_PROGRESS:
        groupDevice.statusNumberObject.inprogressNumber = num;
        groupDevice.statusNumberObject.waitingNumber = deviceCalendar.filter(device => device.status == DeviceStatusEnum.WAITING).length;
        groupDevice.statusNumberObject.completedNumber = deviceCalendar.filter(
          device => device.status == DeviceStatusEnum.COMPLETED
        ).length;
        groupDevice.statusNumberObject.cancelNumber = deviceCalendar.filter(device => device.status == DeviceStatusEnum.CANCELLED).length;
        groupDevice.statusNumberObject.failedNumber = deviceCalendar.filter(device => device.status == DeviceStatusEnum.FAILED).length;
        groupDevice.statusNumberObjectOld = _.cloneDeep(groupDevice.statusNumberObject);
        break;
      case DeviceStatusEnum.COMPLETED:
        groupDevice.statusNumberObject.completedNumber = num;
        groupDevice.statusNumberObject.waitingNumber = deviceCalendar.filter(device => device.status == DeviceStatusEnum.WAITING).length;
        groupDevice.statusNumberObject.inprogressNumber = deviceCalendar.filter(
          device => device.status == DeviceStatusEnum.IN_PROGRESS
        ).length;
        groupDevice.statusNumberObject.cancelNumber = deviceCalendar.filter(device => device.status == DeviceStatusEnum.CANCELLED).length;
        groupDevice.statusNumberObject.failedNumber = deviceCalendar.filter(device => device.status == DeviceStatusEnum.FAILED).length;
        groupDevice.statusNumberObjectOld = _.cloneDeep(groupDevice.statusNumberObject);
        break;
      case DeviceStatusEnum.CANCELLED:
        groupDevice.statusNumberObject.cancelNumber = num;
        groupDevice.statusNumberObject.waitingNumber = deviceCalendar.filter(device => device.status == DeviceStatusEnum.WAITING).length;
        groupDevice.statusNumberObject.inprogressNumber = deviceCalendar.filter(
          device => device.status == DeviceStatusEnum.IN_PROGRESS
        ).length;
        groupDevice.statusNumberObject.completedNumber = deviceCalendar.filter(
          device => device.status == DeviceStatusEnum.COMPLETED
        ).length;
        groupDevice.statusNumberObject.failedNumber = deviceCalendar.filter(device => device.status == DeviceStatusEnum.FAILED).length;
        groupDevice.statusNumberObjectOld = _.cloneDeep(groupDevice.statusNumberObject);
        break;
      case DeviceStatusEnum.FAILED:
        groupDevice.statusNumberObject.failedNumber = num;
        groupDevice.statusNumberObject.waitingNumber = deviceCalendar.filter(device => device.status == DeviceStatusEnum.WAITING).length;
        groupDevice.statusNumberObject.inprogressNumber = deviceCalendar.filter(
          device => device.status == DeviceStatusEnum.IN_PROGRESS
        ).length;
        groupDevice.statusNumberObject.completedNumber = deviceCalendar.filter(
          device => device.status == DeviceStatusEnum.COMPLETED
        ).length;
        groupDevice.statusNumberObject.cancelNumber = deviceCalendar.filter(device => device.status == DeviceStatusEnum.CANCELLED).length;
        groupDevice.statusNumberObjectOld = _.cloneDeep(groupDevice.statusNumberObject);
        break;
      default:
        break;
    }
  }

  /**
   * filter status group blank
   * @param group
   * @param activeColumn
   * @returns
   */
  public static filterStatusGroupBlank(group: GroupDevice, activeColumn: ActiveColumnHeader, groupExpandedClone: GroupDevice): void {
    if (!group.isExpand) {
      return;
    }
    switch (activeColumn) {
      case ActiveColumnHeader.WAITING:
        group.deviceCalendars = groupExpandedClone.deviceCalendars.filter(device => device.status == DeviceStatusEnum.WAITING);
        break;
      case ActiveColumnHeader.IN_PROGRESS:
        group.deviceCalendars = groupExpandedClone.deviceCalendars.filter(device => device.status == DeviceStatusEnum.IN_PROGRESS);
        break;
      case ActiveColumnHeader.COMPLETED:
        group.deviceCalendars = groupExpandedClone.deviceCalendars.filter(device => device.status == DeviceStatusEnum.COMPLETED);
        break;
      case ActiveColumnHeader.FAILED:
        group.deviceCalendars = groupExpandedClone.deviceCalendars.filter(device => device.status == DeviceStatusEnum.FAILED);
        break;
      case ActiveColumnHeader.CANCEL:
        group.deviceCalendars = groupExpandedClone.deviceCalendars.filter(device => device.status == DeviceStatusEnum.CANCELLED);
        break;
      default:
        group.deviceCalendars = groupExpandedClone.deviceCalendars;
        break;
    }
    group.isChecked = group.deviceCalendars.length > 0 && group.deviceCalendars.every(device => device.isChecked);
  }

  /**
   * Get devices delivery object id
   *
   * @param devices
   * @param groupDevices
   * @param groupClone
   * @returns
   */
  public static getDevicesDeliveryObjectId(devices: DeviceCalendar[], groupDevices: GroupDevice[], groupClone: GroupDevice): any {
    let singleIds = new Array<Number>();
    let groupIds = new Array<Number>();
    groupDevices.forEach(group => {
      if (groupClone && groupClone.groupId == group.groupId) {
        this.getStatusDevice(groupClone, groupIds, devices, singleIds);
        return;
      }
      this.getStatusDevice(group, groupIds, devices, singleIds);
    });
    return { singleIds: singleIds, groupIds: groupIds };
  }

  /**
   * get Status Device
   * @param group
   * @param groupIds
   * @param devices
   * @param singleIds
   */
  public static getStatusDevice(group: GroupDevice, groupIds: Array<Number>, devices: DeviceCalendar[], singleIds: Array<Number>) {
    if (group.deviceCalendars.every(element => element.isChecked)) {
      group.deviceCalendars.forEach(data => {
        groupIds.push(data.id);
      });
    } else {
      group.deviceCalendars.forEach(device => {
        if (devices.map(data => data.id).includes(device.id)) {
          singleIds.push(device.id);
        }
      });
    }
  }

  /**
   * handle show detail status display
   * @param translateService
   * @param screenTitle
   * @param status
   * @returns
   */
  public static handleShowDetailStatusDisplay(translateService: TranslateService, screenTitle: string, status: string) {
    return status == DeviceStatusEnum.WAITING ? translateService.instant(`${screenTitle}.${DeviceDetailStatusEnum.PREPARING_DATA}`) : null;
  }

  /**
   * get areas display on preview
   * @param areas
   * @param display
   * @param areaSwitchingTiming
   */
  public static getAreasDisplayOnPreview(areas: Area[], display: Template, areaSwitchingTiming: number): Area[] {
    let areasOn = [];
    if (!display) {
      return areasOn;
    }
    areas.forEach(area => {
      const layer = this.findLayerOfArea(area, display);
      if (layer && layer.isSwitchingArea && areaSwitchingTiming) {
        layer.areas = _.orderBy(layer.areas, ['index'], ['desc']);
        if (layer.areas?.length && layer.areas[0].name == area.name) {
          areasOn.push(area);
        }
      } else {
        areasOn.push(area);
      }
    });
    return areasOn;
  }

  /**
   * find layer of area
   * @param area
   * @param template
   */
  public static findLayerOfArea(area: Area, template: Template): Layer {
    if (!area) {
      return;
    }
    return template?.layers.find(layer => {
      return layer.areas.findIndex(item => item?.id == area.id) != -1;
    });
  }

  /**
   * get areas of layer on off
   * @param areas
   * @param display
   * @param areaSwitchingTiming
   * @param isLayerOn
   * @returns
   */
  public static getAreasOfLayerOnOff(areas: Area[], display: Template, areaSwitchingTiming: number, isLayerOn?: boolean): Area[] {
    if (!display) {
      return;
    }
    if (isLayerOn) {
      return areas
        .map(areaOn => {
          const layer = this.findLayerOfArea(areaOn, display);
          if (layer && layer.isSwitchingArea && areaSwitchingTiming) {
            return areaOn;
          }
        })
        ?.filter(item1 => item1);
    }
    return areas
      .map(areaOff => {
        const layer = this.findLayerOfArea(areaOff, display);
        if ((layer && !layer.isSwitchingArea) || !areaSwitchingTiming) {
          return areaOff;
        }
      })
      ?.filter(item2 => item2);
  }

  /**
   * set data index word for areas
   * @param areasIndexWord
   * @param indexWords
   */
  public static setDataIndexWordForAreas(areasIndexWord: Area[], indexWords: IndexWord[]): void {
    areasIndexWord.forEach((areaIndexWord, index) => {
      areaIndexWord.getArea().text = indexWords[index] ? indexWords[index].text : Constant.EMPTY;
      areaIndexWord.getArea().media = indexWords[index] ? indexWords[index].media : null;
    });
  }

  /**
   * set data index word for areas
   * @param areasIndexWord
   * @param indexWords
   */
  public static setDataIndexWordForAreasRegistration(
    areasIndexWord: Area[],
    indexWords: IndexWord[],
    timetableSchedules: ScheduleRegistration[],
    currentIndex: number
  ): void {
    areasIndexWord.forEach((areaIndexWord, index) => {
      areaIndexWord.getArea().text = indexWords[index] ? indexWords[index].text : Constant.EMPTY;
      areaIndexWord.getArea().media = indexWords[index] ? indexWords[index].media : null;
    });
  }

  /**
   * set data index word for areas
   * @param areasIndexWord
   * @param indexWords
   */
  public static setDataIndexWordForAreasMerge(
    areasIndexWord: Area[],
    indexWords: IndexWord[],
    timetableSchedules: TimetableScheduleMerge[],
    currentIndex: number
  ): void {
    areasIndexWord.forEach((areaIndexWord, index) => {
      areaIndexWord.getArea().text = indexWords[index] ? indexWords[index].text : Constant.EMPTY;
      areaIndexWord.getArea().media = indexWords[index] ? indexWords[index].media : null;
    });
  }

  /**
   * Handle when network error
   * @param error
   */
  public static handleError(error: any, translateService: TranslateService, dialogService: DialogService): void {
    dialogService.showDialog(DialogMessageComponent, {
      data: {
        title: translateService.instant('dialog-error.title'),
        text:
          error.status == Constant.NETWORK_ERROR_CODE
            ? translateService.instant('dialog-error.error-network-api')
            : translateService.instant('dialog-error.msg')
      }
    });
  }

  /**
   * convert data device contentDay
   * @param contentDayData
   */
  public static convertDataDeviceContentDay(contentDayData: any): DeviceContentDay {
    let contentDay = new DeviceContentDay();
    contentDay.deviceId = contentDayData['deviceId'];
    if (contentDayData['contentDays']?.length) {
      contentDay.contentDays = contentDayData['contentDays'].map(data => this.convertDataContentDayToDownload(data));
    }
    return contentDay;
  }

  /**
   * convert data contentDay to download
   * @param contentDayData
   */
  public static convertDataContentDayToDownload(contentDayData: any): ContentDay {
    let contentDay: ContentDay = new ContentDay();
    if (contentDayData['simplePlaylist']) {
      contentDay.playlistSimple = contentDayData['simplePlaylist'];
    }
    if (contentDayData['playlistId']) {
      contentDay.playlistSimpleId = contentDayData['playlistId'];
      contentDay.unlimitedInfo = this.getUnlimitedDataInformation(contentDayData['unlimitedInfo']);
    }
    contentDay.fullDate = this.getContentDayFullDate(contentDayData['fullDate']);
    if (contentDayData['color']) {
      contentDay.color = contentDayData['color'];
    }
    contentDay.isDelivered = contentDayData['isDelivered'];
    return contentDay;
  }

  /**
   * update color for area
   * @param color
   * @param dataService
   * @param isFontColor
   */
  public static updateColorForArea(color: Color, dataService: DataService, isFontColor: boolean): void {
    dataService.sendData([isFontColor ? Constant.IS_CHANGE_FONT_COLOR : Constant.IS_CHANGE_BACKGROUND_COLOR, color.toRgbaHex(color)]);
  }

  /**
   * get calendars schedule merge
   * @param month month
   * @param year year
   */
  public static getCalendarsScheduleMerge(year: number, month: number): Array<ContentDaySchedule> {
    let calendarDays = new Array<ContentDaySchedule>();
    let currentDate = new Date(year, month, 1);
    currentDate.setMonth(currentDate.getMonth() - 1);
    // insert days in previous month
    let datePreviousMonth = currentDate;
    let daysPreviousMonth = this.daysInMonth(currentDate);
    let tempDay = new Date(datePreviousMonth);
    for (let day = 1; day <= daysPreviousMonth; day++) {
      tempDay = this.addDay(tempDay, 1);
      calendarDays.push(new ContentDaySchedule(tempDay.getDay(), new Date(tempDay)));
    }

    // insert days in current month
    for (let i = month + 1; i <= 12; i++) {
      let dateCurrentMonth = this.getDateByMonth(year, i);
      let daysCurrentMonth = this.daysInMonth(dateCurrentMonth);
      for (let day = 1; day <= daysCurrentMonth; day++) {
        tempDay = this.addDay(tempDay, 1);
        calendarDays.push(new ContentDaySchedule(tempDay.getDay(), new Date(tempDay)));
      }
    }

    if (month != 11) {
      return calendarDays;
    }
    // if current month is 12 => insert days in month 1 next year
    for (let j = 1; j <= 2; j++) {
      let dateCurrentMonthNextYear = this.getDateByMonth(year + 1, j);
      let daysCurrentMonthNextYear = this.daysInMonth(dateCurrentMonthNextYear);
      for (let day = 1; day <= daysCurrentMonthNextYear; day++) {
        tempDay = this.addDay(tempDay, 1);
        calendarDays.push(new ContentDaySchedule(tempDay.getDay(), new Date(tempDay)));
      }
    }
    return calendarDays;
  }

  /**
   * get calendars by month year
   * @param calendars
   * @param month month
   * @param year year
   * @param commonObject Common
   */
  public static getCalendarsByMonthYearSchedule(calendars: any, month: number, year: number, commonObject: Common): Array<any> {
    let calendarDays: Array<any> = new Array<any>();
    let currentDate = new Date(new Date(year, month).getFullYear(), new Date(year, month).getMonth());
    currentDate.setDate(0);
    let lastDatePreviousMonth = currentDate.getDate();
    let firstDateCurrentMonth = new Date(year, month, 1);
    let dayOfMonth = lastDatePreviousMonth - firstDateCurrentMonth.getDay();
    // get date previous month
    for (let day = lastDatePreviousMonth; day > dayOfMonth; day--) {
      let contentDays: Array<any>;
      if (month == 0) {
        contentDays = calendars.filter(
          contentDay =>
            contentDay.fullDate.getDate() == day && contentDay.fullDate.getMonth() == 11 && contentDay.fullDate.getFullYear() == year - 1
        );
      } else {
        contentDays = calendars.filter(
          contentDay =>
            contentDay.fullDate.getDate() == day && contentDay.fullDate.getMonth() == month - 1 && contentDay.fullDate.getFullYear() == year
        );
      }
      contentDays.forEach(contentDay => {
        contentDay.isOtherMonth = true;
        contentDay.inactive = true;
        contentDay.isActive = true;
        calendarDays.unshift(contentDay);
      });
    }
    // get date current month
    const now = this.getCurrentByTimezoneSetting(commonObject, false);
    let contentDays = calendars.filter(contentDay => contentDay.fullDate.getMonth() == month && contentDay.fullDate.getFullYear() == year);
    contentDays.forEach(contentDay => {
      let dateEnd = this.getCurrentByTimezoneSetting(commonObject, false);
      dateEnd.setFullYear(dateEnd.getFullYear() + Constant.MAX_YEAR);
      dateEnd.setDate(dateEnd.getDate() - 1);
      if (
        contentDay.fullDate < this.getDateByDay(now.getFullYear(), now.getMonth() + 1, now.getDate()) ||
        contentDay.fullDate > this.getDateByDay(dateEnd.getFullYear(), dateEnd.getMonth() + 1, dateEnd.getDate())
      ) {
        contentDay.isOtherMonth = false;
        contentDay.inactive = true;
        contentDay.isActive = false;
      } else {
        contentDay.isOtherMonth = false;
        contentDay.inactive = false;
        contentDay.isActive = false;
      }
      calendarDays.push(contentDay);
    });

    // get date next month
    let contentDayEnds: Array<any>;
    if (month == 11) {
      contentDayEnds = calendars.filter(contentDay => contentDay.fullDate.getMonth() == 0 && contentDay.fullDate.getFullYear() == year + 1);
    } else {
      contentDayEnds = calendars.filter(
        contentDay => contentDay.fullDate.getMonth() == month + 1 && contentDay.fullDate.getFullYear() == year
      );
    }

    contentDayEnds.forEach(contentDay => {
      if (calendarDays.length >= 42) {
        return;
      }
      contentDay.isOtherMonth = true;
      contentDay.inactive = true;
      contentDay.isActive = true;
      calendarDays.push(contentDay);
    });
    return calendarDays;
  }

  /**
   * get calendars from current date
   */
  public static getCalendarsFromCurrentDate(): Array<ContentDay> {
    let calendarDays = new Array<ContentDay>();
    let currentDate = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    currentDate.setMonth(currentDate.getMonth() - 1);
    // insert days in previous month
    let datePreviousMonth = currentDate;
    let daysPreviousMonth = this.daysInMonth(currentDate);
    let tempDay = new Date(datePreviousMonth);
    for (let day = 1; day <= daysPreviousMonth; day++) {
      tempDay = this.addDay(tempDay, 1);
      calendarDays.push(new ContentDay(tempDay.getDay(), new Date(tempDay)));
    }

    // insert days in current month to 12 current year
    for (let month = currentDate.getMonth() + 1; month <= 12; month++) {
      let dateCurrentMonth = this.getDateByMonth(currentDate.getFullYear(), month);
      let daysCurrentMonth = this.daysInMonth(dateCurrentMonth);
      for (let day = 1; day <= daysCurrentMonth; day++) {
        tempDay = this.addDay(tempDay, 1);
        calendarDays.push(new ContentDay(tempDay.getDay(), new Date(tempDay)));
      }
    }

    // insert days in month 1 to 12 next year
    for (let i = 1; i < Constant.MAX_YEAR; i++) {
      for (let month = 1; month <= 12; month++) {
        let dateCurrentMonthNextYear = this.getDateByMonth(currentDate.getFullYear() + i, month);
        let daysCurrentMonthNextYear = this.daysInMonth(dateCurrentMonthNextYear);
        for (let day = 1; day <= daysCurrentMonthNextYear; day++) {
          tempDay = this.addDay(tempDay, 1);
          calendarDays.push(new ContentDay(tempDay.getDay(), new Date(tempDay)));
        }
      }
    }

    // insert days in next month to previous finish month next year
    let dateEnd = _.cloneDeep(currentDate);
    dateEnd.setFullYear(dateEnd.getFullYear() + Constant.MAX_YEAR);
    dateEnd = this.getDateByDay(dateEnd.getFullYear(), dateEnd.getMonth() + 1, dateEnd.getDate());
    for (let month = 1; month <= dateEnd.getMonth() + 1; month++) {
      let dateNextMonth = this.getDateByMonth(dateEnd.getFullYear(), month);
      let daysNextMonth = this.daysInMonth(dateNextMonth);
      for (let day = 1; day <= daysNextMonth; day++) {
        tempDay = this.addDay(tempDay, 1);
        calendarDays.push(new ContentDay(tempDay.getDay(), new Date(tempDay)));
      }
    }

    // insert days in finish month next year
    let dateFinish = this.getDateByMonth(dateEnd.getFullYear(), dateEnd.getMonth() + 1);
    let daysInMonthEnd = this.daysInMonth(dateFinish);
    for (let day = 1; day <= daysInMonthEnd; day++) {
      tempDay = this.addDay(tempDay, 1);
      calendarDays.push(new ContentDay(tempDay.getDay(), new Date(tempDay)));
    }

    return calendarDays;
  }

  /**
   * getOneMonthCalendar
   * @param month
   * @param year
   * @param commonObject
   * @returns
   */
  public static getOneMonthCalendar(month: number, year: number, commonObject: Common): Array<any> {
    let calendarDays: Array<any> = new Array<any>();

    // Xác định ngày bắt đầu của tháng hiện tại
    let firstDateCurrentMonth = new Date(year, month, 1);
    let firstDayOfWeek = firstDateCurrentMonth.getDay();

    // Xác định ngày cuối của tháng trước
    let previousMonth = month - 1 < 0 ? 11 : month - 1;
    let previousMonthYear = month - 1 < 0 ? year - 1 : year;
    let lastDatePreviousMonth = new Date(previousMonthYear, previousMonth + 1, 0).getDate();

    // Lấy ngày của tháng trước để điền vào lịch
    for (let i = firstDayOfWeek; i > 0; i--) {
      let day = lastDatePreviousMonth - i + 1;
      let contentDay = new ContentDayReservation(
        new Date(previousMonthYear, previousMonth, day).getDay(),
        new Date(previousMonthYear, previousMonth, day)
      );
      contentDay.isOtherMonth = true;
      contentDay.inactive = true;
      contentDay.isActive = true;
      calendarDays.push(contentDay);
    }

    // Lấy ngày của tháng hiện tại
    let daysInCurrentMonth = new Date(year, month + 1, 0).getDate();
    for (let day = 1; day <= daysInCurrentMonth; day++) {
      let contentDay = new ContentDayReservation(new Date(year, month, day).getDay(), new Date(year, month, day));
      let now = this.getCurrentByTimezoneSetting(commonObject, false);

      if (contentDay.fullDate < this.getDateByDay(now.getFullYear(), now.getMonth() + 1, now.getDate())) {
        contentDay.isOtherMonth = false;
        contentDay.inactive = true;
        contentDay.isActive = false;
      } else {
        contentDay.isOtherMonth = false;
        contentDay.inactive = false;
        contentDay.isActive = false;
      }
      calendarDays.push(contentDay);
    }

    // Lấy ngày của tháng sau để điền vào lịch
    let nextMonth = month + 1 > 11 ? 0 : month + 1;
    let nextMonthYear = month + 1 > 11 ? year + 1 : year;
    let daysToFill = 42 - calendarDays.length;
    for (let day = 1; day <= daysToFill; day++) {
      let contentDay = new ContentDayReservation(new Date(nextMonthYear, nextMonth, day).getDay(), new Date(nextMonthYear, nextMonth, day));
      contentDay.isOtherMonth = true;
      contentDay.inactive = true;
      contentDay.isActive = true;
      calendarDays.push(contentDay);
    }

    return calendarDays;
  }

  public static getOneWeekCalendar(year: number, week: number, commonObject: Common, dateSlect: any): Array<any> {
    let calendarDays: Array<any> = new Array<any>();
    const date = new Date(dateSlect);
    const dateOfWeek = date.getDay();
    // Tính ngày đầu tiên của tuần cần lấy
    let firstDayOfWeek = new Date(date.getTime() - dateOfWeek * 24 * 60 * 60 * 1000);

    // Chuyển ngày về đầu tuần (Chủ Nhật)
    while (firstDayOfWeek.getDay() !== 0) {
      firstDayOfWeek.setDate(firstDayOfWeek.getDate() - 1);
    }

    // Lấy ngày của tuần đó
    for (let i = 0; i < 7; i++) {
      let currentDay = new Date(firstDayOfWeek.getTime() + i * 24 * 60 * 60 * 1000);
      let contentDay = new ContentDayReservation(currentDay.getDay(), currentDay);
      let now = this.getCurrentByTimezoneSetting(commonObject, false);

      if (currentDay < this.getDateByDay(now.getFullYear(), now.getMonth() + 1, now.getDate())) {
        contentDay.isOtherMonth = false;
        contentDay.inactive = true;
        contentDay.isActive = false;
      } else {
        contentDay.isOtherMonth = false;
        contentDay.inactive = false;
        contentDay.isActive = false;
      }
      calendarDays.push(contentDay);
    }

    return calendarDays;
  }

  /**
   * get calendars from current date
   */
  public static getCalendarsTicketFromCurrentDate(): Array<ContentDayReservationManager> {
    let calendarDays = new Array<ContentDayReservationManager>();
    let currentDate = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    currentDate.setMonth(currentDate.getMonth() - 1);
    // insert days in previous month
    let datePreviousMonth = currentDate;
    let daysPreviousMonth = this.daysInMonth(currentDate);
    let tempDay = new Date(datePreviousMonth);
    for (let day = 1; day <= daysPreviousMonth; day++) {
      tempDay = this.addDay(tempDay, 1);
      calendarDays.push(new ContentDayReservationManager(tempDay.getDay(), new Date(tempDay)));
    }

    // insert days in current month to 12 current year
    for (let month = currentDate.getMonth() + 1; month <= 12; month++) {
      let dateCurrentMonth = this.getDateByMonth(currentDate.getFullYear(), month);
      let daysCurrentMonth = this.daysInMonth(dateCurrentMonth);
      for (let day = 1; day <= daysCurrentMonth; day++) {
        tempDay = this.addDay(tempDay, 1);
        calendarDays.push(new ContentDayReservationManager(tempDay.getDay(), new Date(tempDay)));
      }
    }

    // insert days in month 1 to 12 next year
    for (let i = 1; i < Constant.MAX_YEAR; i++) {
      for (let month = 1; month <= 12; month++) {
        let dateCurrentMonthNextYear = this.getDateByMonth(currentDate.getFullYear() + i, month);
        let daysCurrentMonthNextYear = this.daysInMonth(dateCurrentMonthNextYear);
        for (let day = 1; day <= daysCurrentMonthNextYear; day++) {
          tempDay = this.addDay(tempDay, 1);
          calendarDays.push(new ContentDayReservationManager(tempDay.getDay(), new Date(tempDay)));
        }
      }
    }

    // insert days in next month to previous finish month next year
    let dateEnd = _.cloneDeep(currentDate);
    dateEnd.setFullYear(dateEnd.getFullYear() + Constant.MAX_YEAR);
    dateEnd = this.getDateByDay(dateEnd.getFullYear(), dateEnd.getMonth() + 1, dateEnd.getDate());
    for (let month = 1; month <= dateEnd.getMonth() + 1; month++) {
      let dateNextMonth = this.getDateByMonth(dateEnd.getFullYear(), month);
      let daysNextMonth = this.daysInMonth(dateNextMonth);
      for (let day = 1; day <= daysNextMonth; day++) {
        tempDay = this.addDay(tempDay, 1);
        calendarDays.push(new ContentDayReservationManager(tempDay.getDay(), new Date(tempDay)));
      }
    }

    // insert days in finish month next year
    let dateFinish = this.getDateByMonth(dateEnd.getFullYear(), dateEnd.getMonth() + 1);
    let daysInMonthEnd = this.daysInMonth(dateFinish);
    for (let day = 1; day <= daysInMonthEnd; day++) {
      tempDay = this.addDay(tempDay, 1);
      calendarDays.push(new ContentDayReservationManager(tempDay.getDay(), new Date(tempDay)));
    }

    return calendarDays;
  }

  /**
   * convert Timetable After Import
   * @param timetables
   * @returns
   */
  public static convertTimetableAfterImport(timetables: any, timeDateLine: string): TimetableMerge[] {
    let timetableMerges: TimetableMerge[] = new Array<TimetableMerge>();
    timetables?.forEach(timetable => {
      let timetableMerge = new TimetableMerge(
        timetable['id'],
        timetable['no'],
        timetable['suffix'],
        timetable['name'],
        timetable['headers']
      );
      timetableMerge.schedules = timetable['schedules'];
      const formatRegex = this.formatTimeRegex(Constant.FORMAT_TIME_COMMON_REGEX, timeDateLine);
      // check time valid

      timetableMerge.schedules?.forEach(detail => {
        const time = detail.columnsData[0];
        let match = new RegExp(formatRegex).exec(time);
        if (match == null) {
          detail.inValidRow = true;
        } else if (time?.split(':')[0]?.length == 1) {
          detail.columnsData[0] = '0' + time;
        }
      });
      timetableMerges.push(timetableMerge);
    });
    return timetableMerges;
  }

  /**
   * convert Timetable After Import
   * @param timetables
   * @returns
   */
  public static convertTimetableDaily(timetablesDailyBack: any, timeDateLine): TimetableDaily[] {
    let timetablesDaily: TimetableDaily[] = [];
    timetablesDailyBack.forEach(scheduleDailyBack => {
      let timetableDaily = new TimetableDaily(
        scheduleDailyBack['id'],
        scheduleDailyBack['no'],
        scheduleDailyBack['suffix'],
        scheduleDailyBack['name'],
        scheduleDailyBack['headers']
      );
      let schedulesTemp = new Array();
      if (scheduleDailyBack['schedules'] && scheduleDailyBack['schedules'].length > 0) {
        for (let columdata of scheduleDailyBack['schedules']) {
          let schedule = new TimetableScheduleMerge(columdata);
          schedulesTemp.push(schedule);
        }
      }
      timetableDaily.schedules = schedulesTemp;
      const formatRegex = this.formatTimeRegex(Constant.FORMAT_TIME_COMMON_REGEX, timeDateLine);
      // check time valid

      timetableDaily.schedules?.forEach(detail => {
        const time = detail.columnsData[0];
        let match = new RegExp(formatRegex).exec(time);
        if (match == null) {
          detail.inValidRow = true;
        } else if (time?.split(':')[0]?.length == 1) {
          detail.columnsData[0] = '0' + time;
        }
      });
      timetablesDaily.push(timetableDaily);
    });
    return timetablesDaily;
  }

  /**
   * convert schedule registration after importt
   * @param timetables
   * @returns
   */
  public static convertScheduleRegistrationAfterImport(schedules: any, timeDateLine: string): ScheduleRegistrationDetail[] {
    let scheduleRegistrations: ScheduleRegistrationDetail[] = [];
    schedules.forEach(schedule => {
      let scheduleRegistration = new ScheduleRegistrationDetail(
        schedule['id'],
        schedule['no'],
        schedule['suffix'],
        schedule['name'],
        schedule['headers'],
        schedule['userId'],
        null,
        schedule['routeId']
      );
      if (schedule['label']) {
        scheduleRegistration.label = schedule['label'];
        scheduleRegistration['nameLabel'] = scheduleRegistration.label?.name;
      }
      scheduleRegistration.schedules = schedule['scheduleRegistrations'];
      // check time valid
      const formatRegex = this.formatTimeRegex(Constant.FORMAT_TIME_COMMON_REGEX, timeDateLine);
      scheduleRegistration.schedules?.forEach(detail => {
        const time = detail.columnData[0];
        let match = new RegExp(formatRegex).exec(time);
        if (match == null) {
          detail.inValidRow = true;
        } else if (time?.split(':')[0]?.length == 1) {
          detail.columnData[0] = '0' + time;
        }
      });

      scheduleRegistrations.push(scheduleRegistration);
    });
    return scheduleRegistrations;
  }
  /**
   * Convert timetable data backward when export timetable
   * @param timetable
   * @returns
   */
  public static convertTimetableRegistrationDataBackWardWhenExport(scheduleRegistration: ScheduleRegistrationDetail): any {
    return {
      id: scheduleRegistration.id,
      name: scheduleRegistration.name,
      no: scheduleRegistration.no,
      suffix: scheduleRegistration.suffix,
      headers: scheduleRegistration.headers,
      scheduleRegistrations: scheduleRegistration.schedules
    };
  }
  /**
   * convert list data timetable content day backward
   *
   * @param timetableContentDayDatas
   * @param setting
   * @returns
   */
  public static convertDataScheduleContentDayBackward(scheduleContentDayDatas: any, setting: UserSetting): any {
    return scheduleContentDayDatas
      ?.filter(data => data?.scheduleRegistration || data?.scheduleRegistrationId)
      ?.map(contentDay => {
        return this.convertScheduleContentDayBackward(contentDay, setting);
      });
  }

  /**
   * convert a timetable content day
   *
   * @param contentDay
   * @returns
   */
  private static convertScheduleContentDayBackward(contentDay: ContentDay, setting: UserSetting): any {
    let GMTTimeZone = this.getUserTimeZone(setting);
    return {
      id: contentDay?.id,
      scheduleRegistrationId: contentDay.scheduleRegistrationId ?? -1,
      fullDate: moment(contentDay.fullDate).format('YYYY-MM-DD') + 'T00:00:00' + GMTTimeZone,
      color: contentDay.color ?? '',
      isRegistered: contentDay.isRegistered ?? false,
      unlimitedInfo: contentDay.unlimitedInfo ? JSON.stringify(contentDay.unlimitedInfo) : null,
      routeId: contentDay?.routeId
    };
  }

  /**
   * Convert data content day for timetable
   *
   * @param contentDayData
   * @param schedules
   * @returns
   */
  public static convertDataContentDayForSchedule(contentDayData: any, schedules?: ScheduleRegistrationDetail[]): ContentDay {
    let contentDay: ContentDay = new ContentDay();
    contentDay.id = contentDayData['id'];
    if (contentDayData['scheduleRegistrationId']) {
      contentDay.scheduleRegistrationId = contentDayData['scheduleRegistrationId'];
      contentDay.unlimitedInfo = this.getUnlimitedDataInformation(contentDayData['unlimitedInfo']);
    }
    if (contentDayData['scheduleRegistration']) {
      contentDay.scheduleRegistration = this.convertDataScheduleRegistration(contentDayData['scheduleRegistration']);
      if (schedules) {
        let index = schedules.findIndex(schedule => schedule.id == contentDay.scheduleRegistrationId);
        if (index != -1) {
          contentDay.scheduleRegistration = schedules[index];
        }
      }
    }
    contentDay.fullDate = this.getContentDayFullDate(contentDayData['fullDate']);
    if (contentDayData['color']) {
      contentDay.color = contentDayData['color'];
    }
    contentDay.isDelivered = contentDayData['isDelivered'];
    contentDay.isRegistered = contentDayData['isRegistered'];
    contentDay.routeId = contentDayData['routeId'];
    return contentDay;
  }

  /**
   * convert schedule registration
   * @param schedule
   * @returns
   */
  public static convertDataScheduleRegistration(schedule: any): ScheduleRegistrationDetail {
    let scheduleRegistration = new ScheduleRegistrationDetail(
      schedule['id'],
      schedule['no'],
      schedule['suffix'],
      schedule['name'],
      schedule['headers'],
      schedule['userId'],
      schedule['label'],
      schedule['routeId']
    );
    scheduleRegistration.schedules = schedule['scheduleRegistrations'];
    return scheduleRegistration;
  }

  /**
   * convert data setting schedule
   * @param data
   * @returns
   */
  public static convertDataSettingSchedule(data: any): DisplaySettingSchedule {
    let displaySettingSchedule = new DisplaySettingSchedule();
    displaySettingSchedule.id = data['id'];
    displaySettingSchedule.templateIds = JSON.parse(data['templateIds']);
    displaySettingSchedule.userId = data['userId'];
    displaySettingSchedule.screenName = data['screenName'];
    displaySettingSchedule.routeId = data['routeId'];
    return displaySettingSchedule;
  }

  /**
   * convert data setting schedule backward
   * @param displaySettingSchedule
   */
  public static convertDataSettingScheduleBackward(displaySettingSchedule: DisplaySettingSchedule): any {
    return {
      id: displaySettingSchedule.id,
      templateIds: JSON.stringify(displaySettingSchedule.templateIds),
      routeId: displaySettingSchedule.routeId
    };
  }

  /**
   * convert data timetable backward
   * @param scheduleRegistration
   */
  public static convertDataTimetableScheduleRegistrationBackward(scheduleRegistration: ScheduleRegistrationDetail): any {
    return {
      id: scheduleRegistration.id,
      name: scheduleRegistration.name,
      no: scheduleRegistration.no,
      suffix: scheduleRegistration.suffix,
      labelId: scheduleRegistration.label?.id,
      label: scheduleRegistration.label,
      headers: scheduleRegistration.headers,
      scheduleRegistrations: scheduleRegistration.schedules,
      routeId: scheduleRegistration.routeId
    };
  }

  /**
   * convert data to list UserCalendar object
   * @param users
   * @param translateService
   * @returns
   */
  public static convertDataToUserCalendars(users: any, translateService: TranslateService): Array<UserCalendar> {
    return users.map(user => {
      let userCalendar = new UserCalendar();
      userCalendar.id = user['id'];
      userCalendar.userId = user['userId'];
      userCalendar.name = `${user['fullName'] ? user['fullName'] : user['name']}`;
      userCalendar.status = user['status'];
      userCalendar.calendars = user['contentDayRegistrations'];
      userCalendar.routeId = user['routeId'];
      return userCalendar;
    });
  }

  /**
   * convertTimetableScheduleMergeToBackWardWhenExport
   * @param timetable
   * @returns
   */
  public static convertTimetableScheduleMergeToBackWardWhenExport(timetable: TimetableMerge): any {
    return {
      id: timetable.id,
      no: timetable.no,
      suffix: timetable.suffix,
      name: timetable.name,
      headers: timetable.headers,
      schedules: timetable.schedules
    };
  }

  /**
   * Convert data content day tab registration
   *
   * @param contentDayData
   * @returns
   */
  public static convertDataContentDayTabRegistration(contentDayData: any): ContentDay {
    let contentDay: ContentDay = new ContentDay();
    contentDay.fullDate = new Date(contentDayData['date']);
    if (contentDayData['name']) {
      contentDay.timetableName = contentDayData['name'];
    }
    if (contentDayData['styleId']) {
      contentDay.styleId = contentDayData['styleId'];
    }
    if (contentDayData['color']) {
      contentDay.color = contentDayData['color'];
    }
    return contentDay;
  }

  /**
   * Get full date of content day
   *
   * @param fullDate
   * @returns
   */
  private static getFullDateOfContentDay(fullDate: any): Date {
    let stringDate = fullDate.split('-');
    return this.getDateByDay(+stringDate[0], +stringDate[1], +stringDate[2]);
  }

  /**
   * convert schedule merge
   * @param schedule
   * @returns
   */
  public static convertDataScheduleMerge(timetable: any): TimetableMerge {
    let timetableMerge = new TimetableMerge(timetable['id'], timetable['no'], timetable['suffix'], timetable['name'], timetable['headers']);
    timetableMerge.schedules = timetable['schedules'];
    return timetableMerge;
  }

  /**
   * convert data to device operation
   * @param devices
   */
  public static convertDataToDeviceOperation(devices: any): DeviceOperation[] {
    return devices.map(device => {
      return new DeviceOperation(device['id'], device['registrationId'], device['deviceId'], false, device['customTag2']);
    });
  }
  /**
   * convert schedule merge
   * @param schedule
   * @returns
   */
  public static convertTextHighlightToDB(textHighlight: TextHighlightingSetting): any {
    return {
      id: textHighlight.id,
      titleName: textHighlight.titleName,
      name: textHighlight.name,
      blinking: textHighlight.blinking,
      object: textHighlight.object,
      color: textHighlight.color,
      opacity: textHighlight.opacity,
      isActive: textHighlight.isActive,
      readOnlyName: textHighlight.readOnlyName
    };
  }

  /**
   * get day of months
   * @param no
   * @returns
   */
  public static getDaysInMonth(no: string): number {
    let month = +no.substring(2);
    let year = +`20${no.substring(0, 2)}`;
    let dateData = Helper.getDateByMonth(year, month);
    return Helper.daysInMonth(dateData);
  }

  /**
   * convert text highlight from db
   * @param textHighlight
   */
  public static convertTextHighlightFromDB(textHighlight: any): TextHighlightingSetting {
    let textHighlightSetting = new TextHighlightingSetting();
    textHighlightSetting.id = textHighlight['id'];
    textHighlightSetting.titleName = textHighlight['titleName'];
    textHighlightSetting.name = textHighlight['name'];
    textHighlightSetting.blinking = textHighlight['blinking'];
    textHighlightSetting.object = textHighlight['object'];
    textHighlightSetting.color = textHighlight['color'];
    textHighlightSetting.isActive = textHighlight['isActive'];
    textHighlightSetting.readOnlyName = textHighlight['readOnlyName'];
    textHighlightSetting.opacity = textHighlight['opacity'];
    return textHighlightSetting;
  }

  /**
   * getCurrentByTimezoneSetting
   * @param commonObject
   * @returns
   */
  public static getCurrentByTimezoneSetting(commonObject: Common, resetTime = true): Date {
    let currentDate;
    var offsetHour = 0;
    var offsetMinute = 0;
    var setting = commonObject.setting;
    if (setting) {
      offsetHour = setting.timezone.offsetHour;
      offsetMinute = setting.timezone.offsetMinute;
    }
    currentDate = new Date(
      moment
        .utc()
        .add(offsetHour, 'hour')
        .add(offsetMinute, 'minute')
        .format(Constant.FORMAT_DATE_TIME1)
    );
    if (resetTime) {
      return new Date(currentDate.setHours(0, 0, 0, 0));
    } else {
      return currentDate;
    }
  }

  /**
   * convert ss to hh:mm:ss
   * @param time string time
   */
  public static convertTime(time: any): number {
    if (!time) {
      return 0;
    }
    const timeArray = time.split(':');
    return +timeArray[Constant.HOURS] * Constant.SECOND_PER_HOUR + +timeArray[Constant.MINUTES] * Constant.SECOND_PER_MINUTE;
  }

  /**
   * convert from moment to time
   * @param momentValue
   */
  public static convertFromMomentToTime(momentValue: any): string {
    let timeArray;
    let timeValue: string;
    timeArray =
      typeof momentValue == 'object'
        ? moment(momentValue._d)
            .format(Constant.FORMAT_TIME_TO_MINUTES)
            .split(':')
        : momentValue.split(':');
    timeValue = timeArray[0] + ':' + timeArray[1];
    return timeValue;
  }

  /**
   * convert data to check diff data
   * @param mediaOfSequences
   * @returns
   */
  public static convertDataToCheckDiffDataAnnouncement(audioOfSequences: Array<AudioOfSequence>): any {
    return audioOfSequences?.map(media => {
      return {
        key: media.randomNumber,
        duration: media.duration,
        startTime: media.startTime
      };
    });
  }

  /**
   * convert list data simple playlist
   *
   * @param playlistDatas
   * @returns
   */
  public static convertDataAnnouncementPlaylists(playlistDatas: any[]): Array<AnnouncementPlaylist> {
    return playlistDatas?.map(data => this.convertDataAnnouncementPlaylist(data));
  }

  /**
   * convert data simple playlist
   * @param playlistData
   * @returns
   */
  public static convertDataAnnouncementPlaylist(playlistData: any): AnnouncementPlaylist {
    let announcementPlaylist = new AnnouncementPlaylist();
    announcementPlaylist.id = playlistData['id'];
    announcementPlaylist.name = playlistData['name'];
    announcementPlaylist.sequence = playlistData['sequence'];
    announcementPlaylist.playlistType = playlistData['playlistType'];
    return announcementPlaylist;
  }

  /**
   * convert data list simple folder media
   * @param folderDatas
   * @returns
   */
  public static convertDataAnnouncementFolderMedias(folderDatas: any): Array<AnnouncementFolder> {
    return folderDatas?.map(data => this.convertDataAnnouncementFolderMedia(data));
  }

  /**
   * convert data simple folder media
   * @param folderData
   * @returns
   */
  private static convertDataAnnouncementFolderMedia(folderData: any): AnnouncementFolder {
    let announcementFolder = new AnnouncementFolder();
    announcementFolder.id = folderData['id'];
    announcementFolder.name = folderData['name'];
    announcementFolder.folderS3Name = folderData['folderS3Name'];
    return announcementFolder;
  }

  /**
   * convert data list announcement media
   * @param mediaDatas
   * @returns
   */
  public static convertDataAnnouncementMedias(mediaDatas: any, isDisplayInGUI: boolean): Array<AnnouncementMedia> {
    if (!mediaDatas) {
      return [];
    }
    return mediaDatas.map(data => this.convertDataAnnouncementMedia(data, isDisplayInGUI));
  }

  /**
   * convert data list announcement media not contain date
   * @param mediaDatas
   * @param isDisplayInGUI
   * @returns
   */
  public static convertDataAnnouncementMediasNotContainDate(mediaDatas: any, isDisplayInGUI: boolean): Array<AnnouncementMedia> {
    if (!mediaDatas) {
      return [];
    }
    return mediaDatas.map(data => this.convertDataAnnouncementMediaNotContainDate(data, isDisplayInGUI));
  }

  /**
   * convert data announcement media
   * @param simpleMediaData
   * @returns
   */
  public static convertDataAnnouncementMedia(simpleMediaData: any, isDisplayInGUI: boolean): AnnouncementMedia {
    let announcementMedia = new AnnouncementMedia();
    announcementMedia.id = simpleMediaData['id'];
    announcementMedia.name = isDisplayInGUI
      ? this.convertDataToDisplayGUI(simpleMediaData['name'], simpleMediaData['mediaNameEncode'])
      : simpleMediaData['name'];
    announcementMedia.url = simpleMediaData['url'];
    announcementMedia.type = simpleMediaData['type'];
    announcementMedia.duration = simpleMediaData['duration'];
    announcementMedia.folderId = simpleMediaData['folderId'];
    announcementMedia.size = simpleMediaData['size'];
    announcementMedia.randomNumber = Math.random();
    announcementMedia.mediaNameEncode = simpleMediaData['mediaNameEncode'];
    announcementMedia.bitRate = simpleMediaData['bitRate'];
    return announcementMedia;
  }

  /**
   * convert data announcement media not contain date
   * @param simpleMediaData
   * @param isDisplayInGUI: boolean
   * @returns
   */
  public static convertDataAnnouncementMediaNotContainDate(simpleMediaData: any, isDisplayInGUI: boolean): AnnouncementMedia {
    let announcementMedia = new AnnouncementMedia();
    announcementMedia.id = simpleMediaData['id'];
    announcementMedia.name = isDisplayInGUI
      ? this.convertDataToDisplayGUINotContainDate(simpleMediaData['name'], simpleMediaData['mediaNameEncode'])
      : simpleMediaData['name'];
    announcementMedia.url = simpleMediaData['url'];
    announcementMedia.type = simpleMediaData['type'];
    announcementMedia.duration = simpleMediaData['duration'];
    announcementMedia.folderId = simpleMediaData['folderId'];
    announcementMedia.size = simpleMediaData['size'];
    announcementMedia.randomNumber = Math.random();
    announcementMedia.mediaNameEncode = simpleMediaData['mediaNameEncode'];
    announcementMedia.bitRate = simpleMediaData['bitRate'];
    return announcementMedia;
  }

  /**
   * convert announcement media to media of sequence
   *
   * @param simpleMedia
   */
  public static convertAnnouncementAudioToAudioOfSequence(announcementMedia: AnnouncementMedia): AudioOfSequence {
    let audioOfSequence = new AudioOfSequence();
    audioOfSequence.idMedia = announcementMedia.id;
    audioOfSequence.name = announcementMedia.name;
    audioOfSequence.duration = this.convertDuration(announcementMedia.duration);
    audioOfSequence.type = announcementMedia.type;
    audioOfSequence.url = announcementMedia.url;
    audioOfSequence.size = announcementMedia.size;
    audioOfSequence.randomNumber = Math.random();
    audioOfSequence.mediaNameEncode = announcementMedia.mediaNameEncode;
    return audioOfSequence;
  }

  /**
   * convert data list media of sequence
   * @param mediaOfSequenceData
   * @returns
   */
  public static convertDataListAudioOfSequence(audioOfSequenceDatas: any): Array<AudioOfSequence> {
    return audioOfSequenceDatas?.map(data => this.convertDataAudioOfSequence(data));
  }

  /**
   * convert data media of sequence
   * @param mediaOfSequenceData
   * @returns
   */
  private static convertDataAudioOfSequence(mediaOfSequenceData: any): AudioOfSequence {
    let audioOfSequence = new AudioOfSequence();
    audioOfSequence.idMedia = mediaOfSequenceData['idMedia'];
    audioOfSequence.startTime = mediaOfSequenceData['startTime'];
    audioOfSequence.url = mediaOfSequenceData['url'];
    audioOfSequence.name = this.convertDataToDisplayGUINotContainDate(mediaOfSequenceData['name'], mediaOfSequenceData['mediaNameEncode']);
    audioOfSequence.duration = mediaOfSequenceData['duration'];
    audioOfSequence.type = mediaOfSequenceData['type'];
    audioOfSequence.size = mediaOfSequenceData['size'];
    audioOfSequence.randomNumber = Math.random();
    audioOfSequence.folderS3Name = mediaOfSequenceData['folderS3Name'];
    audioOfSequence.mediaNameEncode = mediaOfSequenceData['mediaNameEncode'];
    this.convertDurationAudioOfSequence(audioOfSequence);
    return audioOfSequence;
  }

  /**
   * convert duration of media
   * @param mediaOfSequence
   */
  private static convertDurationAudioOfSequence(audioOfSequence: AudioOfSequence) {
    if (audioOfSequence.type == TypeMediaFileEnum.MP3) {
      return;
    }
    audioOfSequence.hour = `${Math.floor(audioOfSequence.duration / 3600)}`.padStart(2, '0');
    audioOfSequence.minute = `${Math.floor((audioOfSequence.duration % 3600) / 60)}`.padStart(2, '0');
    audioOfSequence.second = `${(audioOfSequence.duration % 3600) % 60}`.padStart(2, '0');
  }

  /**
   * convert common playlist registrations from backend
   * @param commonPlaylistRegistrations
   * @returns
   */
  public static convertCommonPlaylistRegistrationsFromBackend(
    commonPlaylistRegistrations: Array<CommonPlaylistRegistration>
  ): Array<CommonPlaylistRegistration> {
    return commonPlaylistRegistrations?.map(commonPlaylistRegistration =>
      this.convertCommonPlaylistRegistrationFromBackend(commonPlaylistRegistration)
    );
  }

  /**
   * convert common playlist registrations from backend
   * @param commonPlaylistRegistration
   */
  public static convertCommonPlaylistRegistrationFromBackend(
    commonPlaylistRegistration: CommonPlaylistRegistration
  ): CommonPlaylistRegistration {
    let commonPlaylist = new CommonPlaylistRegistration();
    commonPlaylist.id = commonPlaylistRegistration['id'];
    commonPlaylist.deviceId = commonPlaylistRegistration['deviceId'];
    commonPlaylist.commonAnnouncement1 = commonPlaylistRegistration['commonAnnouncement1'];
    commonPlaylist.commonAnnouncement2 = commonPlaylistRegistration['commonAnnouncement2'];
    commonPlaylist.periodicAnnouncement = commonPlaylistRegistration['periodicAnnouncement'];
    commonPlaylist.deviceName = commonPlaylistRegistration['deviceDTO'].deviceId;
    commonPlaylist.registrationId = commonPlaylistRegistration['deviceDTO'].registrationId;
    commonPlaylist.playlistSpecific = commonPlaylistRegistration['playlistSpecific'];
    commonPlaylist.customTagElement = this.convertCustomTagElementFromBackEnd(commonPlaylistRegistration['customTagElementDTO']);
    return commonPlaylist;
  }

  /**
   * convert common registrations to device common specific
   * @param commonPlaylistRegistrations
   * @returns
   */
  public static convertCommonRegistrationsToDeviceCommonSpecific(
    commonPlaylistRegistrations: Array<CommonPlaylistRegistration>
  ): Array<DeviceCommonSpecific> {
    return commonPlaylistRegistrations?.map(commonPlaylistRegistration =>
      this.convertCommonRegistrationToDeviceCommonSpecific(commonPlaylistRegistration)
    );
  }

  /**
   * convert common registration to device common specific
   * @param commonPlaylistRegistration
   */
  public static convertCommonRegistrationToDeviceCommonSpecific(
    commonPlaylistRegistration: CommonPlaylistRegistration
  ): DeviceCommonSpecific {
    let deviceCommonPlaylist = new DeviceCommonSpecific();
    deviceCommonPlaylist.id = commonPlaylistRegistration.deviceId;
    deviceCommonPlaylist.name = commonPlaylistRegistration.deviceName;
    deviceCommonPlaylist.commonAnnouncement1 = commonPlaylistRegistration.commonAnnouncement1;
    deviceCommonPlaylist.commonAnnouncement2 = commonPlaylistRegistration.commonAnnouncement2;
    deviceCommonPlaylist.periodicAnnouncement = commonPlaylistRegistration.periodicAnnouncement;
    deviceCommonPlaylist.registrationId = commonPlaylistRegistration.registrationId;
    deviceCommonPlaylist.nameGroup = commonPlaylistRegistration.customTagElement.name;
    deviceCommonPlaylist.groupId = commonPlaylistRegistration.customTagElement.id + '';
    deviceCommonPlaylist.playlistSpecific = commonPlaylistRegistration.playlistSpecific;
    return deviceCommonPlaylist;
  }

  /**
   * convert custom tag element from backend
   * @param customTagElement
   * @returns
   */
  public static convertCustomTagElementFromBackEnd(customTagElement: any): CustomTagElementAnnouncement {
    let customTagCommon = new CustomTagElementAnnouncement();
    customTagCommon.id = customTagElement['id'];
    customTagCommon.name = customTagElement['name'];
    return customTagCommon;
  }

  /**
   * convert common playlist registrations to backend
   * @param commonPlaylistRegistrations
   */
  public static convertCommonPlaylistRegistrationsToBackend(commonPlaylistRegistrations: Array<CommonPlaylistRegistration>): any {
    return commonPlaylistRegistrations.map(commonPlaylistRegistration =>
      this.convertCommonPlaylistRegistrationToBackend(commonPlaylistRegistration)
    );
  }

  /**
   * convert common playlist registration to backend
   * @param commonPlaylistRegistration
   * @returns
   */
  public static convertCommonPlaylistRegistrationToBackend(commonPlaylistRegistration: CommonPlaylistRegistration): any {
    return {
      id: commonPlaylistRegistration.id,
      commonAnnouncement1:
        commonPlaylistRegistration.commonAnnouncement1 == -1 ? undefined : commonPlaylistRegistration.commonAnnouncement1,
      commonAnnouncement2:
        commonPlaylistRegistration.commonAnnouncement2 == -1 ? undefined : commonPlaylistRegistration.commonAnnouncement2,
      periodicAnnouncement:
        commonPlaylistRegistration.periodicAnnouncement == -1 ? undefined : commonPlaylistRegistration.periodicAnnouncement,
      deviceId: commonPlaylistRegistration.deviceId,
      playlistSpecific: commonPlaylistRegistration.playlistSpecific,
      customTagElementDTO: this.convertCustomTagElementToBackend(commonPlaylistRegistration.customTagElement)
    };
  }

  /**
   * convert custom tag element to backend
   * @param customTagElement
   */
  public static convertCustomTagElementToBackend(customTagElement: CustomTagElementAnnouncement): any {
    return {
      id: customTagElement.id,
      name: customTagElement.name
    };
  }

  /**
   * convert data to check diff data
   * @param commonPlaylistRegistrations
   * @returns
   */
  public static convertDataToCheckDiffCommonRegistration(commonPlaylistRegistrations: Array<CommonPlaylistRegistration>): any {
    return commonPlaylistRegistrations?.map(commonPlaylistRegistration => {
      return {
        commonAnnouncement1: commonPlaylistRegistration.commonAnnouncement1,
        commonAnnouncement2: commonPlaylistRegistration.commonAnnouncement2,
        periodicAnnouncement: commonPlaylistRegistration.periodicAnnouncement
      };
    });
  }

  public static convertMediaNameOriginal(data: any, originalContentJson: any): any {
    let index = data.indexOf('.');
    return index == -1 ? originalContentJson[`${data}`].name : data;
  }

  /**
   * Get single device id's not yet completed
   *
   * @param groupDevices
   * @param groupClone
   * @returns device id's
   */
  public static getSingleDeviceAnnouncementIdsNotYetCompleted(
    groupDevices: GroupDeviceAnnouncement[],
    groupClone: GroupDeviceAnnouncement
  ): Number[] {
    if (!groupDevices) {
      return [];
    }
    let deviceIds = [];
    groupDevices.forEach(group => {
      let groupDevice = groupClone && groupClone.groupId == group.groupId ? groupClone : group;
      deviceIds = deviceIds.concat(
        groupDevice.deviceCommonSpecifics
          .filter(
            device =>
              device.jobId &&
              device.jobId.indexOf(Constant.DELIVERY_SINGLE_KEY) != -1 &&
              (device.status == DeviceStatusEnum.WAITING || device.status == DeviceStatusEnum.IN_PROGRESS) &&
              !device.isDelivering
          )
          .map(deviceData => deviceData.id)
      );
    });
    return deviceIds;
  }

  /**
   * check Mapping Status Devices Group
   *
   * @param groupDevice
   * @param groupClone
   * @returns
   */
  public static checkMappingStatusDevicesGroupAnnouncement(
    groupDevice: GroupDeviceAnnouncement,
    groupClone: GroupDeviceAnnouncement
  ): boolean {
    if (
      !groupDevice.deviceCommonSpecifics ||
      groupDevice.deviceCommonSpecifics.some(
        data =>
          data.jobId?.indexOf(Constant.DELIVERY_SINGLE_KEY) > -1 ||
          data.jobId == Constant.CMP_DELIVERY_GROUP_KEY ||
          data.jobId == Constant.CMP_DELIVERY_SINGLE_KEY ||
          data.jobId == groupDevice.name
      )
    ) {
      return true;
    }
    let group = groupClone && groupClone.groupId == groupDevice.groupId ? groupClone : groupDevice;
    let deviceDeliveryGroups = group.deviceCommonSpecifics.filter(
      data => data.jobId && data.jobId.indexOf(Constant.DELIVERY_GROUP_KEY) != -1
    );
    return (
      deviceDeliveryGroups.filter(device => device.status == DeviceStatusEnum.WAITING).length ==
        groupDevice.statusNumberObject.waitingNumber &&
      deviceDeliveryGroups.filter(device => device.status == DeviceStatusEnum.IN_PROGRESS).length ==
        groupDevice.statusNumberObject.inprogressNumber &&
      deviceDeliveryGroups.filter(device => device.status == DeviceStatusEnum.COMPLETED).length ==
        groupDevice.statusNumberObject.completedNumber &&
      deviceDeliveryGroups.filter(device => device.status == DeviceStatusEnum.CANCELLED).length ==
        groupDevice.statusNumberObject.cancelNumber &&
      deviceDeliveryGroups.filter(device => device.status == DeviceStatusEnum.FAILED).length == groupDevice.statusNumberObject.failedNumber
    );
  }

  /**
   * filter status group blank
   * @param group
   * @param activeColumn
   * @returns
   */
  public static filterStatusGroupAnnouncementBlank(
    group: GroupDeviceAnnouncement,
    activeColumn: ActiveColumnHeader,
    groupExpandedClone: GroupDeviceAnnouncement
  ): void {
    if (!group.isExpand) {
      return;
    }
    switch (activeColumn) {
      case ActiveColumnHeader.WAITING:
        group.deviceCommonSpecifics = groupExpandedClone.deviceCommonSpecifics.filter(device => device.status == DeviceStatusEnum.WAITING);
        break;
      case ActiveColumnHeader.IN_PROGRESS:
        group.deviceCommonSpecifics = groupExpandedClone.deviceCommonSpecifics.filter(
          device => device.status == DeviceStatusEnum.IN_PROGRESS
        );
        break;
      case ActiveColumnHeader.COMPLETED:
        group.deviceCommonSpecifics = groupExpandedClone.deviceCommonSpecifics.filter(
          device => device.status == DeviceStatusEnum.COMPLETED
        );
        break;
      case ActiveColumnHeader.FAILED:
        group.deviceCommonSpecifics = groupExpandedClone.deviceCommonSpecifics.filter(device => device.status == DeviceStatusEnum.FAILED);
        break;
      case ActiveColumnHeader.CANCEL:
        group.deviceCommonSpecifics = groupExpandedClone.deviceCommonSpecifics.filter(
          device => device.status == DeviceStatusEnum.CANCELLED
        );
        break;
      default:
        group.deviceCommonSpecifics = groupExpandedClone.deviceCommonSpecifics;
        break;
    }
    group.isChecking = group.deviceCommonSpecifics.length > 0 && group.deviceCommonSpecifics.every(device => device.isChecking);
  }

  /**
   * Update active status number realtime
   *
   * @param groupDevice
   * @param status
   * @param num
   */
  public static updateActiveStatusNumberRealtimeAnnouncement(
    groupDevice: GroupDeviceAnnouncement,
    status: DeviceStatusEnum,
    num: number
  ): void {
    let deviceCommonSpecifics = groupDevice.deviceCommonSpecifics.filter(data => data.jobId?.indexOf(Constant.DELIVERY_GROUP_KEY) > -1);
    switch (status) {
      case DeviceStatusEnum.WAITING:
        groupDevice.statusNumberObject.waitingNumber = num;
        groupDevice.statusNumberObject.inprogressNumber = deviceCommonSpecifics.filter(
          device => device.status == DeviceStatusEnum.IN_PROGRESS
        ).length;
        groupDevice.statusNumberObject.completedNumber = deviceCommonSpecifics.filter(
          device => device.status == DeviceStatusEnum.COMPLETED
        ).length;
        groupDevice.statusNumberObject.cancelNumber = deviceCommonSpecifics.filter(
          device => device.status == DeviceStatusEnum.CANCELLED
        ).length;
        groupDevice.statusNumberObject.failedNumber = deviceCommonSpecifics.filter(
          device => device.status == DeviceStatusEnum.FAILED
        ).length;
        groupDevice.statusNumberObjectOld = _.cloneDeep(groupDevice.statusNumberObject);
        break;
      case DeviceStatusEnum.IN_PROGRESS:
        groupDevice.statusNumberObject.inprogressNumber = num;
        groupDevice.statusNumberObject.waitingNumber = deviceCommonSpecifics.filter(
          device => device.status == DeviceStatusEnum.WAITING
        ).length;
        groupDevice.statusNumberObject.completedNumber = deviceCommonSpecifics.filter(
          device => device.status == DeviceStatusEnum.COMPLETED
        ).length;
        groupDevice.statusNumberObject.cancelNumber = deviceCommonSpecifics.filter(
          device => device.status == DeviceStatusEnum.CANCELLED
        ).length;
        groupDevice.statusNumberObject.failedNumber = deviceCommonSpecifics.filter(
          device => device.status == DeviceStatusEnum.FAILED
        ).length;
        groupDevice.statusNumberObjectOld = _.cloneDeep(groupDevice.statusNumberObject);
        break;
      case DeviceStatusEnum.COMPLETED:
        groupDevice.statusNumberObject.completedNumber = num;
        groupDevice.statusNumberObject.waitingNumber = deviceCommonSpecifics.filter(
          device => device.status == DeviceStatusEnum.WAITING
        ).length;
        groupDevice.statusNumberObject.inprogressNumber = deviceCommonSpecifics.filter(
          device => device.status == DeviceStatusEnum.IN_PROGRESS
        ).length;
        groupDevice.statusNumberObject.cancelNumber = deviceCommonSpecifics.filter(
          device => device.status == DeviceStatusEnum.CANCELLED
        ).length;
        groupDevice.statusNumberObject.failedNumber = deviceCommonSpecifics.filter(
          device => device.status == DeviceStatusEnum.FAILED
        ).length;
        groupDevice.statusNumberObjectOld = _.cloneDeep(groupDevice.statusNumberObject);
        break;
      case DeviceStatusEnum.CANCELLED:
        groupDevice.statusNumberObject.cancelNumber = num;
        groupDevice.statusNumberObject.waitingNumber = deviceCommonSpecifics.filter(
          device => device.status == DeviceStatusEnum.WAITING
        ).length;
        groupDevice.statusNumberObject.inprogressNumber = deviceCommonSpecifics.filter(
          device => device.status == DeviceStatusEnum.IN_PROGRESS
        ).length;
        groupDevice.statusNumberObject.completedNumber = deviceCommonSpecifics.filter(
          device => device.status == DeviceStatusEnum.COMPLETED
        ).length;
        groupDevice.statusNumberObject.failedNumber = deviceCommonSpecifics.filter(
          device => device.status == DeviceStatusEnum.FAILED
        ).length;
        groupDevice.statusNumberObjectOld = _.cloneDeep(groupDevice.statusNumberObject);
        break;
      case DeviceStatusEnum.FAILED:
        groupDevice.statusNumberObject.failedNumber = num;
        groupDevice.statusNumberObject.waitingNumber = deviceCommonSpecifics.filter(
          device => device.status == DeviceStatusEnum.WAITING
        ).length;
        groupDevice.statusNumberObject.inprogressNumber = deviceCommonSpecifics.filter(
          device => device.status == DeviceStatusEnum.IN_PROGRESS
        ).length;
        groupDevice.statusNumberObject.completedNumber = deviceCommonSpecifics.filter(
          device => device.status == DeviceStatusEnum.COMPLETED
        ).length;
        groupDevice.statusNumberObject.cancelNumber = deviceCommonSpecifics.filter(
          device => device.status == DeviceStatusEnum.CANCELLED
        ).length;
        groupDevice.statusNumberObjectOld = _.cloneDeep(groupDevice.statusNumberObject);
        break;
      default:
        break;
    }
  }

  /**
   * Get devices delivery object id
   *
   * @param devices
   * @param groupDevices
   * @param groupClone
   * @returns
   */
  public static getDevicesDeliveryObjectIdAnnouncement(
    devices: DeviceCommonSpecific[],
    groupDevices: GroupDeviceAnnouncement[],
    groupClone: GroupDeviceAnnouncement
  ): any {
    let singleIds = new Array<Number>();
    let groupIds = new Array<Number>();
    groupDevices.forEach(group => {
      if (groupClone && groupClone.groupId == group.groupId) {
        this.getStatusDeviceAnnouncement(groupClone, groupIds, devices, singleIds);
        return;
      }
      this.getStatusDeviceAnnouncement(group, groupIds, devices, singleIds);
    });
    return { singleIds: singleIds, groupIds: groupIds };
  }

  /**
   * get Status Device
   * @param group
   * @param groupIds
   * @param devices
   * @param singleIds
   */
  public static getStatusDeviceAnnouncement(
    group: GroupDeviceAnnouncement,
    groupIds: Array<Number>,
    devices: DeviceCommonSpecific[],
    singleIds: Array<Number>
  ) {
    if (group.deviceCommonSpecifics.every(element => element.isChecking)) {
      group.deviceCommonSpecifics.forEach(data => {
        groupIds.push(data.id);
      });
    } else {
      group.deviceCommonSpecifics.forEach(device => {
        if (devices.map(data => data.id).includes(device.id)) {
          singleIds.push(device.id);
        }
      });
    }
  }

  /**
   * Set data for device group clone
   *
   * @param element
   * @param data
   * @param deviceId
   * @param groupExpandedClone
   * @returns
   */
  public static setDataForDeviceGroupCloneAnnouncement(
    element: any,
    data: any,
    deviceId: Number,
    groupExpandedClone: GroupDeviceAnnouncement
  ): void {
    if (!groupExpandedClone) {
      return;
    }
    let index = groupExpandedClone.deviceCommonSpecifics.findIndex(data => data.id === deviceId);
    if (index != -1) {
      groupExpandedClone.deviceCommonSpecifics[index][element] = data;
    }
  }

  /**
   * Get checked devices in group
   *
   * @param deviceIds
   * @param groupDevices
   * @param groupClone
   * @returns
   */
  public static getCheckedDevicesInGroupCommonSpecific(
    deviceIds: number[],
    groupDevices: GroupDeviceAnnouncement[],
    groupClone: GroupDeviceAnnouncement
  ): DeviceCommonSpecific[] {
    let devices = new Set(
      deviceIds.map(id => {
        return this.getDeviceInGroupAnnouncemnet(id, groupDevices);
      })
    );
    if (groupClone) {
      groupClone.deviceCommonSpecifics.forEach(device => {
        if (device.isDelivering) {
          devices.add(device);
        }
      });
    }
    return [...devices].filter(data => data);
  }

  /**
   * Get device in group
   *
   * @param deviceId
   * @param groupDevices
   * @returns
   */
  public static getDeviceInGroupAnnouncemnet(deviceId: number, groupDevices: GroupDeviceAnnouncement[]): DeviceCommonSpecific {
    return groupDevices
      .find(group => group.deviceCommonSpecifics.map(device => device.id).includes(deviceId))
      ?.deviceCommonSpecifics.find(deviceCalendar => deviceCalendar.id == deviceId);
  }

  /**
   * Set data for device group clone
   *
   * @param element
   * @param data
   * @param deviceId
   * @param groupExpandedClone
   * @returns
   */
  public static setDataForDeviceGroupCloneAnnoucement(
    element: any,
    data: any,
    deviceId: number,
    groupExpandedClone: GroupDeviceAnnouncement
  ): void {
    if (!groupExpandedClone) {
      return;
    }
    let index = groupExpandedClone.deviceCommonSpecifics.findIndex(data => data.id === deviceId);
    if (index != -1) {
      groupExpandedClone.deviceCommonSpecifics[index][element] = data;
    }
  }

  /**
   * compare data of checked devices
   * @param checkedDevices
   * @param deviceResult
   * @returns
   */
  public static isEqualDeviceCommonSpecific(checkedDevices: DeviceCommonSpecific[], deviceResult: DeviceCommonSpecific): boolean {
    return _.isEqual(
      checkedDevices.map(data => {
        return {
          deviceId: data.id,
          jobId: data.jobId
        };
      }),
      deviceResult
    );
  }

  /**
   * get group set
   * @param checkedDevices
   * @returns
   */
  public static getGroupAnnouncementSet(checkedDevices: DeviceCommonSpecific[]): any {
    return [
      ...new Set(
        checkedDevices.map(device => {
          return {
            groupId: device.groupId,
            jobId: device.jobId
          };
        })
      )
    ];
  }
  /**
   * convert schedule reservation after import
   * @param schedules
   * @param timeDateLine
   * @param idMax
   * @returns
   */
  public static convertScheduleReservationAfterImport(schedules: any, timeDateLine: string, idMax: number): ScheduleReservation[] {
    let scheduleReservations: ScheduleReservation[] = new Array<ScheduleReservation>();
    schedules?.forEach(schedule => {
      let scheduleReservation = new ScheduleReservation(++idMax, schedule['no'], schedule['suffix'], schedule['name'], schedule['headers']);
      scheduleReservation.scheduleDetails = schedule['schedules'];
      const formatRegex = this.formatTimeRegex(Constant.FORMAT_TIME_COMMON_REGEX, timeDateLine);
      // check time valid

      scheduleReservation.scheduleDetails?.forEach(detail => {
        const time = detail.columnsData[0];
        let match = new RegExp(formatRegex).exec(time);
        if (match == null) {
          detail.inValidRow = true;
        } else if (time?.split(':')[0]?.length == 1) {
          detail.columnsData[0] = '0' + time;
        }
      });
      scheduleReservations.push(scheduleReservation);
    });
    return scheduleReservations;
  }

  /**
   * convert schedule checking to backend when export
   * @param schedule
   * @returns
   */
  public static convertScheduleCheckingToBackendWhenExport(schedule: ScheduleReservation): any {
    return {
      id: schedule.id,
      no: schedule.no,
      suffix: schedule.suffix,
      name: schedule.name,
      headers: schedule.headers,
      schedules: schedule.scheduleDetails
    };
  }

  /**
   * Get current date by time zone
   * @param isGetHours
   * @returns
   */
  public static getCurrentDateByTimeZone(commonObject): string {
    let offsetHour = 0;
    let offsetMinute = 0;
    let setting = commonObject.setting;
    if (setting) {
      offsetHour = setting.timezone.offsetHour;
      offsetMinute = setting.timezone.offsetMinute;
    }
    return moment
      .utc()
      .add(offsetHour, 'hour')
      .add(offsetMinute, 'minute')
      .format(Constant.FORMAT_DATE_TIME1);
  }

  /**
   * convert custom tag element from backend
   * @param
   * @returns
   */
  public static convertResApplication(response: any): Array<ApplicationDTO> {
    let applications: ApplicationDTO[] = new Array<ApplicationDTO>();
    response?.forEach(res => {
      let application = new ApplicationDTO();
      application.appName = res['appName'];
      application.appNameShort = res['appNameShort'];
      application.appDesigns = res['appDesign'];
      application.supportedLanguage = res['supportedLanguage'];
      application.appId = res['appId'];
      application.appUrl = res['appUrl'];
      application.appHomepageUrl = res['appHomepageUrl'];
      application.userPoolId = res['userPoolId'];
      application.contactAnnouncement = res['contactAnnouncement'];
      application.isNoCostAvailable = res['isNoCostAvailable'];
      application.isDistributable = res['isDistributable'];
      application.distributionAppBaseUrl = res['distributionAppBaseUrl'];
      application.availableQrSystems = res['availableQrSystems'];
      application.anonymousForm = res['anonymousForm'];
      application.optionalForms = res['optionalForms'];
      application.optionalForm = res['optionalForm'];
      applications.push(application);
    });
    return applications;
  }

  public static convertSpots(data: any): Array<Spot> {
    return data.map(spot => this.convertSport(spot));
  }

  public static convertSport(data: any): Spot {
    let spot = new Spot();
    spot.spotId = data['spotId'];
    spot.spotName = data['spotName'];
    spot.appId = data['appId'];
    spot.appName = data['appName'];
    spot.ticketInformation = data['ticketList'];
    let spotDetails = new SpotDetails();
    spotDetails.id = data['spotId'];
    spotDetails.idSpot = data['spotId'];
    spotDetails.spotName = data['spotName'];
    spotDetails.appId = data['appId'];
    spotDetails.code = data['spotCode'];
    spotDetails.spotQrCode = data['spotQrCode'];
    if (data['image']) {
      spotDetails.spotImage = this.getFileNameFromUrl(data['image']);
      spotDetails.spotImageURL = data['image'];
    }
    if (data['imageCircle']) {
      spotDetails.spotImageCircle = this.getFileNameFromUrl(data['imageCircle']);
      spotDetails.spotImageCircleURL = data['imageCircle'];
    }
    spotDetails.imagePath = data['imagePath'];
    spotDetails.imageCirclePath = data['imageCirclePath'];
    spotDetails.cropPosition = data['cropPosition'];
    spot.spotDetails = spotDetails;
    return spot;
  }

  /**
   * getFileNameFromUrl
   * @param url
   * @returns
   */
  public static getFileNameFromUrl(url: string): string {
    const filenameWithExtension = url.substring(url.lastIndexOf('/') + 1, url.indexOf('?'));
    const filename = filenameWithExtension.split('/').pop();
    return filename;
  }

  /**
   * convertFileToBase64
   * @param file
   * @returns
   */
  public static convertFileToBase64(file: File): Promise<string | ArrayBuffer> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onloadend = () => {
        resolve(reader.result);
      };

      reader.onerror = reject;

      reader.readAsDataURL(file);
    });
  }

  public static getErrorTicket(error: any, translateService: any, dialogService) {
    const errorMessage =
      error.status == Constant.NETWORK_ERROR_CODE
        ? translateService.instant('dialog-error.error-network-api')
        : error.error
        ? error.error.message
        : translateService.instant('ticket-editor.common-error');
    dialogService.showDialog(DialogMessageComponent, {
      data: {
        title: translateService.instant('dialog-error.title'),
        text: errorMessage
      }
    });
  }

  /**
   * isObjectEmpty
   * @param obj
   * @returns
   */
  public static isObjectEmpty(obj: any): boolean {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }

  /**
   * reorderLanguages
   * @param languages
   * @param languageSelected
   * @returns
   */
  public static reorderLanguages(languages: any[], languageSelected: string): any[] {
    const reorderedLanguages = [...languages];

    const selectedLanguageIndex = reorderedLanguages.findIndex(lang => lang.translation_language_code == languageSelected);

    if (selectedLanguageIndex !== -1) {
      const selectedLanguage = reorderedLanguages[selectedLanguageIndex];
      reorderedLanguages.splice(selectedLanguageIndex, 1);
      reorderedLanguages.unshift(selectedLanguage);
    } else {
      const defaultLanguageCode = languageSelected == 'en' ? 'ja' : 'en';
      const defaultLanguageIndex = reorderedLanguages.findIndex(lang => lang.translation_language_code == defaultLanguageCode);

      if (defaultLanguageIndex !== -1) {
        const defaultLanguage = reorderedLanguages[defaultLanguageIndex];
        reorderedLanguages.splice(defaultLanguageIndex, 1);
        reorderedLanguages.unshift(defaultLanguage);
      }
    }

    return reorderedLanguages;
  }

  /**
   * getLanguagesCode
   * @param languagesCodes
   * @param commonService
   * @returns
   */
  public static getLanguagesCode(languagesCodes: any[], commonService: any) {
    if (!languagesCodes || !languagesCodes.length) {
      return [];
    }
    //languages code CMP
    const languagesSetting = commonService.getCommonObject().setting.languagesSetting.includes('"')
      ? commonService
          .getCommonObject()
          .setting.languagesSetting.slice(1, -1)
          .split(',')
      : commonService.getCommonObject().setting?.languagesSetting.split(',');
    //languages code that belong to languages Setting
    const languageCodes1 = _.cloneDeep(languagesCodes).filter(e => languagesSetting.includes(e));
    //languages code that not belong to languages Setting
    const languageCodes2 = _.cloneDeep(languagesCodes).filter(e => !languagesSetting.includes(e));
    languageCodes1.sort(function(a, b) {
      return languagesSetting.indexOf(a) - languagesSetting.indexOf(b);
    });
    languageCodes2.sort(function(a, b) {
      return Constant.LANGUAGES_SETTING_CODE.indexOf(a) - Constant.LANGUAGES_SETTING_CODE.indexOf(b);
    });
    return languageCodes1.concat(languageCodes2);
  }

  /**
   * getTotalPrice
   * @param priceAdult
   * @param reservationNumberAdult
   * @param priceCustom1
   * @param reservationNumberCustom1
   * @param priceCustom2
   * @param reservationNumberCustom2
   * @param priceCustom3
   * @param reservationNumberCustom3
   * @returns
   */
  public static getTotalPrice(
    priceAdult: number,
    reservationNumberAdult: number,
    priceCustom1: number,
    reservationNumberCustom1: number,
    priceCustom2: number,
    reservationNumberCustom2: number,
    priceCustom3: number,
    reservationNumberCustom3: number
  ): number {
    let total = 0;
    if (priceAdult && reservationNumberAdult) {
      total += priceAdult * reservationNumberAdult;
    }
    if (priceCustom1 && reservationNumberCustom1) {
      total += priceCustom1 * reservationNumberCustom1;
    }
    if (priceCustom2 && reservationNumberCustom2) {
      total += priceCustom2 * reservationNumberCustom2;
    }
    if (priceCustom3 && reservationNumberCustom3) {
      total += priceCustom3 * reservationNumberCustom3;
    }
    return total;
  }

  /**
   * isEmpty
   * @param value
   * @returns
   */
  public static isEmpty(value: any): boolean {
    if (!value) {
      if (typeof value == 'number' && value == 0) {
        return false;
      }
      return true;
    }
    return false;
  }

  /**
   * convertPhoneNumber
   * @param input
   * @returns
   */
  public static convertPhoneNumber(input: string): string {
    if (!input) {
      return;
    }
    if (!input.includes('-')) {
      return input;
    }
    const index = input.indexOf('-');
    return input.substring(index + 1);
  }

  /**
   * convert data announcement media not contain date
   * @param simpleMediaData
   * @param isDisplayInGUI: boolean
   * @returns
   */
  public static convertDataNotificationMediaNotContainDate(data): any {
    return [];
  }

  public static convertDataNotificationMedia(notificationMedia: any, isDisplayInGUI: boolean): NotificationMedia {
    let notification = new NotificationMedia();
    notification.id = notificationMedia['id'];
    notification.name = isDisplayInGUI
      ? this.convertDataToDisplayGUI(notificationMedia['name'], notificationMedia['mediaNameEncode'])
      : notificationMedia['name'];
    notification.url = notificationMedia['url'];
    notification.urlMediaDelivery = notificationMedia['urlMediaDelivery'];
    notification.type = notificationMedia['type'];
    notification.width = notificationMedia['width'];
    notification.height = notificationMedia['height'];
    notification.size = notificationMedia['size'];
    notification.randomNumber = Math.random();
    notification.mediaNameEncode = notificationMedia['mediaNameEncode'];
    notification.folderId = notificationMedia['folderId'];
    notification.folderS3Name = notificationMedia['folderS3Name'];
    return notification;
  }

  /**
   * convert data list simple media
   * @param mediaDatas
   * @returns
   */
  public static convertDataNotificationMedias(mediaDatas: any, isDisplayInGUI: boolean): Array<any> {
    if (!mediaDatas) {
      return [];
    }
    return mediaDatas.map(data => this.convertDataNotificationMedia(data, isDisplayInGUI));
  }

  /**
   * translation
   * @param announcementManagerService
   * @param translateService
   * @param dialogService
   * @param languageKey
   * @param source_language_code
   * @param supportedLanguages
   * @param text
   * @returns
   */
  public static async translation(
    announcementManagerService: AnnouncementManagerService,
    translateService: TranslateService,
    dialogService: DialogService,
    languageKey: string,
    source_language_code: string,
    supportedLanguages: string[],
    text: string
  ): Promise<any> {
    try {
      let filteredLanguages = supportedLanguages?.filter(language => language != source_language_code);
      let payload = {
        glossary: '',
        base_language_code: languageKey == 'en' ? 'en' : 'ja',
        text: Helper.encodeHTMLAnnouncementManager(text),
        source_language_code: source_language_code,
        target_language_code: filteredLanguages
      };

      // Chuyển đổi observable thành Promise
      const res = await announcementManagerService.translate(payload).toPromise();

      let result = {};
      filteredLanguages.forEach(lang => {
        let item = res?.message?.find(r => r.target_language_code == lang);
        result[lang] = item ? Helper.reEncodeHTMLAnnouncementManager(item.translated_text) : '';
      });

      return result;
    } catch (error) {
      const errorMessage =
        error.status == Constant.NETWORK_ERROR_CODE
          ? translateService.instant('dialog-error.error-network-api')
          : error.error
          ? error.error.message
          : translateService.instant('ticket-editor.common-error');

      dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: translateService.instant('dialog-error.title'),
          text: errorMessage
        }
      });
      return null;
    }
  }

  /**
   * generateQRCode
   * @param url
   * @returns
   */
  public static generateQRCode(url: string): Promise<string> {
    const qrCode = new QRCodeStyling({
      width: 100,
      height: 100,
      type: 'svg',
      data: url,
      margin: 10,
      qrOptions: {
        typeNumber: 0,
        mode: 'Byte',
        errorCorrectionLevel: 'L'
      },
      dotsOptions: {
        color: '#000',
        type: 'square'
      },
      backgroundOptions: {
        color: '#FFF'
      },
      cornersSquareOptions: {
        color: '#000',
        type: 'square'
      },
      cornersDotOptions: {
        color: '#000',
        type: 'square'
      }
    });
    return qrCode.getRawData('svg').then((blob: Blob) => {
      return new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64QRCode = reader.result as string;
          resolve(base64QRCode);
        };
        reader.onerror = reject;
        reader.readAsText(blob);
      });
    });
  }

  /**
   * changeDisplayTicket
   * @param str
   * @returns
   */
  public static changeDisplay(str: string, max: number): string {
    if (!str) {
      return '';
    }
    let result = 0;
    for (let i = 0; i < str.length; i++) {
      const char = str.charAt(i);
      if (/[^\x00-\xff]/.test(char)) {
        result += 2;
      } else if (/[mW]/.test(char)) {
        result += 1.88;
      } else if (/[ABCDEFGHJKLNOPQRSTUVXYZ]/.test(char)) {
        result += 1.75;
      } else if (/[w]/.test(char)) {
        result += 1.58;
      } else if (/[M]/.test(char)) {
        result += 1.8;
      } else if (/[bdghknopqsuvxyz]/.test(char)) {
        result += 1.22;
      } else if (/[ae]/.test(char)) {
        result += 1.1;
      } else if (/[c]/.test(char)) {
        result += 0.8;
      } else if (/[iIlrjft]/.test(char)) {
        result += 0.55;
      } else {
        result += 1; // For numbers and special characters
      }
      if (result > max) {
        return str.substring(0, i) + '...';
      }
    }
    return str;
  }

  /**
   * convertDataScheduleOperationFolders
   * @param folderDatas
   * @returns
   */
  public static convertDataScheduleOperationFolders(folderDatas: any): Array<ScheduleOperationFolder> {
    return folderDatas?.map(data => this.convertDataScheduleOperationFolder(data));
  }

  /**
   *convertDataScheduleOperationFolder
   * @param folderData
   * @returns
   */
  private static convertDataScheduleOperationFolder(folderData: any): Folder {
    let folder = new ScheduleOperationFolder();
    folder.id = folderData['id'];
    folder.name = folderData['name'];
    folder.folderS3Name = folderData['folderS3Name'];
    folder.type = folderData['type'];
    return folder;
  }
}
